/*eslint-disable*/

import React from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import UploadIcon from "@material-ui/icons/CloudUpload";
import PCAPdatabaseIcon from "@material-ui/icons/TableChart";
import RemoteControlIcon from "@material-ui/icons/SettingsRemote";
import ReportIcon from "@material-ui/icons/Assessment";
import TestCaseIcon from "@material-ui/icons/FormatListBulleted";
import TestSuiteIcon from "@material-ui/icons/ListAlt";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";


import {
  cardTitle,
  roseColor
} from "assets/jss/material-dashboard-pro-react.js";



const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconRose: {
    color: roseColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  }
};

const useStyles = makeStyles(styles);



export default function Home() {

  const classes = useStyles();
  const history = useHistory();

  const onClickFileUpload = () => {

    history.push('/admin/upload');

  }

  const onClickPcapDatabase = () => {

    history.push('/admin/pcap-database');

  }

  const onClickRemoteControl= () => {

    history.push('/admin/remote-control');

  }

  const onClickReport = () => {

    history.push('/admin/report');

  }

  const onClickTestCase= () => {

    history.push('/admin/test-case');

  }

  const onClickTestSuite= () => {

    history.push('/admin/test-suite');

  }

 
  return (
    <div>

      <GridContainer>
            <GridItem xs={12} sm={6} lg={4}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}> <strong>File Upload</strong> </h5>
                  <div className={classes.icon}>
                    <UploadIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Allows user to view detail information for each specifc PCAP file with the option for download.
                  </p>

                  <Button round color="rose" onClick={onClickFileUpload}>
                    View Page
                  </Button>

                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} lg={4}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}> <strong>PCAP Database</strong> </h5>
                  <div className={classes.icon}>
                    <PCAPdatabaseIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Allows user to upload various types of PCAP files and view existing PCAP files that has been analyzed.
                  </p>

                  <Button round color="rose" onClick={onClickPcapDatabase}>
                    View Page
                  </Button>

                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} lg={4}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}> <strong>Remote Control</strong> </h5>
                  <div className={classes.icon}>
                    <RemoteControlIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Allows user to upload various types of PCAP files and view existing PCAP files that has been analyzed.
                  </p>

                  <Button round color="rose" onClick={onClickRemoteControl}>
                    View Page
                  </Button>

                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} lg={4}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}> <strong>Report</strong> </h5>
                  <div className={classes.icon}>
                    <ReportIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Allows user to upload various types of PCAP files and view existing PCAP files that has been analyzed.
                  </p>

                  <Button round color="rose" onClick={onClickReport}>
                    View Page
                  </Button>

                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} lg={4}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}> <strong>Test Case</strong> </h5>
                  <div className={classes.icon}>
                    <TestCaseIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Allows user to upload various types of PCAP files and view existing PCAP files that has been analyzed.
                  </p>

                  <Button round color="rose" onClick={onClickTestCase}>
                    View Page
                  </Button>

                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} lg={4}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}> <strong>Test Suite</strong> </h5>
                  <div className={classes.icon}>
                    <TestSuiteIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Allows user to upload various types of PCAP files and view existing PCAP files that has been analyzed.
                  </p>

                  <Button round color="rose" onClick={onClickTestSuite}>
                    View Page
                  </Button>

                </CardBody>
              </Card>
            </GridItem>

          </GridContainer>

    </div>
  );
}
