/*eslint-disable*/
import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import NavPills from "components/NavPills/NavPills.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// import CardText from "components/Card/CardText.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import SweetAlert from "react-bootstrap-sweetalert";
import FormHelperText from '@mui/material/FormHelperText';
import Heading from "components/Heading/Heading.js";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from '@material-ui/core/Chip';
// import FormLabel from "@material-ui/core/FormLabel";
// import CircularProgress from '@material-ui/core/CircularProgress';
// import InputAdornment from "@material-ui/core/InputAdornment";
// import ModelIcon from "@material-ui/icons/Devices";
// import EmailIcon from "@material-ui/icons/Email";
// import WebsiteIcon from "@material-ui/icons/Language";
// import BrandIcon from "@material-ui/icons/LocalOffer";
// import SendIcon from "@material-ui/icons/Send";
// import FileIcon from "@material-ui/icons/List";
// import DescriptionIcon from "@material-ui/icons/Description";

// @material-ui/icons
import AssignmentIcon from "@material-ui/icons/Assignment";
// import AddressIcon from "@material-ui/icons/Language";
// import VersionIcon from "@material-ui/icons/Build";
// import PersonIcon from "@material-ui/icons/Person"; // For User Agent
// import PlayIcon from "@material-ui/icons/PlayArrow";
// import CheckIcon from "@material-ui/icons/CheckCircle";
// import ErrorIcon from "@material-ui/icons/Error";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import TvIcon from "@material-ui/icons/Tv";
// import UploadIcon from "@material-ui/icons/CloudUpload";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import TestCaseIcon from "@material-ui/icons/FormatListBulleted";
import TestSuiteIcon from "@material-ui/icons/ListAlt";
import RefreshIcon from "@material-ui/icons/RefreshSharp";
import CreateNewIcon from "@material-ui/icons/NoteAdd";
import RunIcon from "@material-ui/icons/PlayCircleFilledSharp";
import EditIcon from "@material-ui/icons/Edit";
import TitleIcon from "@material-ui/icons/Title";
import CategoryIcon from "@material-ui/icons/Category";
import DescriptionIcon from "@material-ui/icons/Description";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import NewStepIcon from "@material-ui/icons/PlaylistAdd";
import ListIcon from "@material-ui/icons/ViewList";
import TestListIcon from "@material-ui/icons/PlaylistAddCheck";
import PcapIcon from "@material-ui/icons/VideoLibrarySharp";
import FrequencyIcon from "@material-ui/icons/GraphicEq";
import MoveUpIcon from "@material-ui/icons/KeyboardArrowUpTwoTone";
import MoveDownIcon from "@material-ui/icons/KeyboardArrowDownTwoTone";
import ChannelIcon from "@material-ui/icons/SettingsRemote";
import DetailIcon from "@material-ui/icons/Info";
import ComputerIcon from "@material-ui/icons/ComputerSharp";
import StartIcon from "@material-ui/icons/PlayCircleFilledSharp";
import StatusIcon from "@material-ui/icons/History";
import ReportIcon from "@material-ui/icons/Assessment";
import IDIcon from "@material-ui/icons/Label";
import PassIcon from "@material-ui/icons/Check";
import FailIcon from "@material-ui/icons/Close";
import NextIcon from "@material-ui/icons/NavigateNext";
import TestIcon from "@material-ui/icons/FormatListNumbered";
import TableIcon from "@material-ui/icons/Assignment";
import PieChartIcon from "@material-ui/icons/DonutSmall";
import BarChartIcon from "@material-ui/icons/BarChart";
import ViewReportIcon from "@material-ui/icons/BugReport";


// 3rd Party Components
import MaterialTable from "@material-table/core";
import { ThreeDots } from  'react-loader-spinner'
import { CirclesWithBar } from  'react-loader-spinner'
import { RotatingLines } from  'react-loader-spinner'
import { Line, Doughnut, Radar, Bar, Chart, HorizontalBar } from 'react-chartjs-2'; 
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DataTable from 'react-data-table-component';
import { CSVLink } from "react-csv"; // 3rd Party CSV Download

// REST API
import RestAPI from "../../Service/RestAPI.json"

// Global State
import { useAtom } from 'jotai'
import { user } from "../../store/store.js";


import jsonTableData from "../../JSON/pcapDataSetMapping.json";
import jsonPcapFileMapping from "../../JSON/pcapFileMapping.json";


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import stylesSweetAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { ConsoleLogger } from "@aws-amplify/core";
import { BluetoothAudioSharp } from "@material-ui/icons";
import { Container } from "@material-ui/core";

const useStyles = makeStyles(styles);
const useStylesSweetAlert = makeStyles(stylesSweetAlert);
const useStylesDashboard = makeStyles(dashboardStyle);



export default function Report() {


  const [reportSummary, setReportSummary] = React.useState([]);
  const [reportPieSummary, setReportPieSummary] = React.useState([]);
  const [reportBarSummary, setReportBarSummary] = React.useState([]);


  const [testCaseID, setTestCaseID] = React.useState(0);
  const [categoryInput, setCategoryInput] = React.useState('');
  const [titleInput, setTitleInput] = React.useState('');
  const [descriptionInput, setDescriptionInput] = React.useState('');
  const [freqencyInputSelection, setFreqencyInputSelection] = React.useState('');
  const [pcapItemsSelection, setPcapItemsSelection] = React.useState('');
  const [commentInput, setCommentInput] = React.useState('');

  const [currentTestSteps, setCurrentTestSteps] = React.useState([]);
  const [specificTestCaseTable, setSpecificTestCaseTable] = React.useState([]);
  const [currentTestStepsType, setCurrentTestStepsType] = React.useState([]);

  const [testRunStepFocus, setTestRunStepFocus] = React.useState(0);


  const [newTestCaseRunPopUpState, setNewTestCaseRunPopUpState] = React.useState(false); 

  // Load Page
  const [isLoading, setIsLoading] = React.useState(false);

  // Display Test Case Pop up
  const [newTestCasePopUpState, setNewTestCasePopUpState] = React.useState(false); 


  const getTestResult = async () => {

      const axios = require('axios');
    
    try {
      const resp = await axios.get(`${RestAPI.testResult[0].testSummary}`);
      
    
      setReportSummary(resp.data.reportSummary);
      // setReportPieSummary(resp.data.pieChart);
      // setReportBarSummary(resp.data.barChart);
    
    
    } catch (err) {
        // Handle Error Here
        console.error(err);
    
    }
    
    
    };



    React.useEffect(() => {

        getTestResult();

        }, [])



    const viewTestReportSummaryTableColumn = [
        {
            title: "Date",
            field: "date"
        },
        {
            title: "Test Suite ID",
            field: "testSuiteId"
        },
        {
            title: "Test Case ID",
            field: "testCaseId"
        },
        {
            title: "Category",
            field: "category"
        },
        {
            title: "Title",
            field: "title"
        },
        {
            title: "Description",
            field: "description"
        },
        {
            title: "Result",
            field: "result"
        },
        // {
        //     title: "Comment",
        //     field: "comment"
        // },
        {
            title: "Tester",
            field: "tester"
        }

        ];
    

        
    
        const viewTestReportSummaryDataSet = reportSummary.map((data) => {
    
        return {
            id: data["Key"],
            date: data["Date"],
            testSuiteId: data["TestSuiteID"],
            testCaseId: data["ID"],
            category: data["Category"],
            title: data["Title"],
            description: data["Description"],
            result: data["Result"],
            comment: data["Comment"],
            tester: data["Tester"]
        };
        });



        // const handleRefresh = event => {
            
        //     getTestResult();

        //   };



    const handleRefresh = () => {
      console.log("refresh button pressed");
      getTestResult();
    };




// Test Summary Bar Chart

        //   let reportBarSummaryDataSet = {
        //     type: 'bar',
        //     labels: reportBarSummary.map(data => data['Category']),
        //     datasets: [
        //       {
        //         label: 'Pass',
        //         data: reportBarSummary.map(data => data['Pass']),
        //         fill: false,
        //         backgroundColor: 'rgb(26, 115, 55)',
        //         borderColor: 'rgba(26, 115, 55, 0.6)',
        //         stack: 'stack1'
        //       },
          
        //       {
        //         label: 'Fail',
        //         data: reportBarSummary.map(data => data['Fail']),
        //         fill: false,
        //         backgroundColor: 'rgb(163, 30, 30)',
        //         borderColor: 'rgba(163, 30, 30, 0.6)',
        //         stack: 'stack1'
        //       }
          
          
        //     ]
          
        //   }



        //   let reportBarSummaryOption = {

        //     // Drill Down
        //     onClick: (event, elements) => {
          
        //       if(elements.length > 0){ // undefine cause app to crash
        //         const chart = elements[0]._chart;
        //         const element = chart.getElementAtEvent(event)[0];
        //         const dataset = chart.data.datasets[element._datasetIndex];
        //         const xLabel = chart.data.labels[element._index];
        //         const value = dataset.data[element._index];
        //         console.log(dataset.label + " at " + xLabel + ": " + value);
          
        //       }
          
        //     },
          
          
        //     scales: {
        //       xAxes: [{
        //           stacked: true
        //       }],
        //       yAxes: [{
        //           stacked: true
        //       }]
        //   }
          
          
        //   }



        //   const reportBarSummaryTableDataSet = reportBarSummary.map((data) => {
        //     return ({
        //       id: data.id,
        //       category: data['Category'],
        //       pass: data['Pass'],
        //       fail: data['Fail']
        //     })
        //   });
          
        // const reportBarSummaryTableColumn = [
        //   {
        //     name: 'Category',
        //     selector: 'category',
        //     sortable: true
        //   },
        //   {
        //     name: 'Pass',
        //     selector: 'pass',
        //     sortable: true
        //   },
        //   {
        //     name: 'Fail',
        //     selector: 'fail',
        //     sortable: true
        //   }
        // ];


        // // Implement text inside Doughnut
        // var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
        // Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
        // draw: function() {
        //     originalDoughnutDraw.apply(this, arguments);
            
        //     var chart = this.chart.chart;
        //     var ctx = chart.ctx;
        //     var width = chart.width;
        //     var height = chart.height;

        //     var fontSize = (height / 114).toFixed(2); 
        //     ctx.font = fontSize + "em Verdana";
        //     ctx.textBaseline = "middle";

        //     var text = chart.config.data.text,
        //         textX = Math.round((width - ctx.measureText(text).width) / 2),
        //         textY = height / 1.8;

        //     ctx.fillText(text, textX, textY);
        // }
        // });


        // // Topic Search - Pie Chart
        // let reportPieSummaryDataSet = {
        // type: 'doughnut',
        // labels: ["Pass", "Fail"],
        // datasets: [
        // {
        //     data: [reportPieSummary.map(data => data['Pass']), reportPieSummary.map(data => data['Fail'])],
        //     backgroundColor: ['rgb(26, 115, 55)', 'rgb(163, 30, 30)'],
        //     //options: options
        // }],
        // text: ''
        // } 



        // let reportBarSummaryOptions = {

        //     plugins: {
        
        //       datalabels: {
        //         display: true,
        //         color: "white",
        //         font:{
        //           size: 18,
        //           weight: "bold"
        //         },
        //         //formatter: (val) =>
        //         //`${val}`,
        //         formatter: (value, ctx) => {
        //           let datasets = ctx.chart.data.datasets;
        //           let percentage;
        //           if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
        //             let sum = datasets[0].data.reduce((a, b) => a + b, 0);
        //             percentage = Math.round((value / sum) * 100) + '%';
        //             return percentage;
        //           } else {
        //             return percentage;
        //           }
        //         }
        //       }
        //     },
          
        
        //     // Drill Down
        //   onClick: (event, elements) => {
        
        //     if(elements.length > 0){ // undefine cause app to crash
        //       const chart = elements[0]._chart;
        //       const element = chart.getElementAtEvent(event)[0];
        //       const dataset = chart.data.datasets[element._datasetIndex];
        //       const xLabel = chart.data.labels[element._index];
        //       const value = dataset.data[element._index];
        //       console.log(dataset.label + " at " + xLabel + ": " + value);
        
        //     }
        
        //   },
        
        //   }



        // const reportPieSummaryTableDataSet = [{
        //     pass: reportBarSummary.map(data => data['Pass']),
        //     fail: reportBarSummary.map(data => data['Fail'])
        // }]
        // ;
          
        
        // const reportPieSummaryTableColumn = [
        //   {
        //     name: 'Pass',
        //     selector: 'pass',
        //     sortable: true
        //   },
        //   {
        //     name: 'Fail',
        //     selector: 'fail',
        //     sortable: true
        //   }
        // ];




    
        const handleViewReport = async (testCaseID, testCaseComment, testCaseDate, testCaseTester) => {
          console.log(testCaseID);
          console.log(testCaseDate);
          console.log(testCaseTester);

          console.table(reportSummary); // Filter this by test case id, date, and tester to get the last step number

          //let result = reportSummary.filter(x => x.ID === testCaseID & x.Date === testCaseDate & x.Tester === testCaseTester);
          let result = reportSummary.filter(x => x.ID == testCaseID & x.Date == testCaseDate);
          console.table(result);
          //console.table(result[0].LastStep);

          setTestRunStepFocus(result[0].LastStep);  // Error Here
      
          //console.log(testCaseID, testCaseTitle, testCaseDescription)
          //console.log(testCaseID)
          //console.log(testCaseComment)

          setCommentInput(testCaseComment);
      
          handleTestCasePopupRun(testCaseID);
      
          const axios = require('axios');
        
          axios.post(`${RestAPI.testResult[0].specificTestCase}`, {
        
            testID: testCaseID
          
          })
          .then((response) => {
            console.log(response.data);
      
            setTestCaseID(response.data.specificTestCase[0].ID);
            setCategoryInput(response.data.specificTestCase[0].Category);
            setTitleInput(response.data.specificTestCase[0].Title);
            setDescriptionInput(response.data.specificTestCase[0].Description);
            setFreqencyInputSelection(response.data.specificTestCase[0].Frequency);
            setPcapItemsSelection(response.data.specificTestCase[0].PCAP);

      
            
            //console.log(testCaseListDetail.filter(data => data['ID'] === testCaseID));
            // console.log(testCaseListDetail);
      
            let saveSteps = response.data.specificTestCase;
      
            setCurrentTestSteps(saveSteps.map((data) => data.Step));
            setCurrentTestStepsType(saveSteps.map((data) => data.Type));
            setSpecificTestCaseTable(saveSteps);
      
            
      
            // console.table(saveSteps.map((data) => data.Type));
            
      
            //let testIDinfo = response.data.specificTestCase[0].ID;
            //handleTestCasePopupOld(testIDinfo);
      
            //console.log(testIDinfo);
      
            setNewTestCasePopUpState(true);
            setIsLoading(false);
          
          });
      
      
      
      
      
        
        };


        const resetState = () => {

          // Reset Save Items
          setTestCaseID(0);
          // setTestCaseHeader('');
          setCategoryInput('');
          setTitleInput('');
          setDescriptionInput('');
          setCurrentTestSteps([]);
          setFreqencyInputSelection('');
          setPcapItemsSelection('');

          // setTestRunStepFocus(0);
          setCurrentTestStepsType([]);

          setCommentInput('');

    }



        const handleTestCasePopupRun = (payload) => {

          //setNewTestCaseRunPopUpState(true); 
          //setNewTestCasePopUpState(true);
          setIsLoading(true); 
      
        
        };


        const handleRunTestCasePopupClose = () => {

          setNewTestCasePopUpState(false);
          resetState();
      
      
        };



        const viewTestRunStepListTableColumn = [

          {
            title: "Test ID",
            field: "testCaseID",
            width: "20%",
            // align: "center"
            // cellStyle: {
            //   textAlign:'center'
            // },
            // headerStyle: {
            //   textAlign:'center'
            // }
          },
      
          {
            title: "Step",
            field: "index",
            width: "20%"
          },
      
          {
            title: "Description",
            field: "step",
            width: "60%",
          },
        ];
      
        
      
        const viewTestRunStepListDataSet = specificTestCaseTable.map((data, index) => {
      
          return {
            testCaseID: data["ID"],
            index: index + 1,
            step: data["Step"],
            type: data["Type"]
          };
        });
      
      
        
        





    const classes = useStyles();
    const classes2 = useStylesSweetAlert();
    const dashboardClasses = useStylesDashboard();

  return (
    <div>

        <GridContainer>

            <GridItem xs={12}>
                <Button color="github" size="sm" style={{width: "100%"}}> <b>Test Summary</b> </Button>
            </GridItem>

        </GridContainer>


        <GridContainer>

            <GridItem xs={12}>

                <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"
                tabs={[
                {
                    tabName: "Test Result",
                    tabIcon: TableIcon,
                    tabContent: (
                    <div>
                    <div style={{ maxWidth: "100%" }}>
                        <MaterialTable
                        columns={viewTestReportSummaryTableColumn}
                        data={viewTestReportSummaryDataSet}
                        title="Stream Manager"
                        options={{
                            exportButton: true,
                            exportAllData: true,
                            filtering: true,
                            showTitle: false,
                            pageSize: 10,
                            search: true,
                            actionsColumnIndex: -1,
                            // selection: true

                            // rowStyle: rowData => {

                            //   // console.log(rowData);
                            //   // console.log(rowData.tableData.index);
                              
                            //   //console.table(rowData.step);
                            //   //console.table(rowData.step[0]);
                              
                            //   // if(rowData.step === "Go to Settings") {
                            //   // if(rowData.step === currentTestSteps[testRunStepFocus]) {
                            //     // console.log(currentTestStepsType);

                            //   // if(rowData.tableData.index === testRunStepFocus) {
                            //   //   return {backgroundColor: 'rgb(238, 255, 51)', textDecoration: 'underline', fontWeight: 'bold'};
                            //   // } else{
                            //   //   return {backgroundColor: 'rgb(164, 165, 155)'};
                            //   // }
                              
                            //   // return {};
                            // }

                            
                        }}
                        actions={[
                          {
                            icon: ViewReportIcon,
                            tooltip: 'View Report',
                            onClick: (event, rowData) => {
                              // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                              //onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                              // setFullReviewModal(true); // Show Pop-up

                              //console.log(rowData.id, rowData.title, rowData.description, ()=>{});

                              //handleRun(rowData.id, rowData.title, rowData.description);

                              

                              //console.table(rowData);
                              handleViewReport(rowData.testCaseId, rowData.comment, rowData.date, rowData.tester);

                              //console.log(rowData.comment)
                              // setCommentInput(rowData.comment);

                            }

                          }



                        ]}

                        
                        />
                    </div>

                    <br></br>


                    <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center">


                          <Tooltip
                            id="tooltip-bottom"
                            title='Improve Model Selection Performance'
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                            >

                            <Button color="info" onClick={handleRefresh} size="sm">
                              <RefreshIcon className={classes.icons} />
                              Refresh Table
                            </Button>

                          </Tooltip>


                          <CSVLink data={reportSummary} filename={"Stream Manager Test Report.csv"}>
                              <Button color="warning" size="sm" > 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                          </CSVLink>




                        </GridItem>



                    </GridContainer>

    

                    </div>
                    ),
                },
                ]}
            />
                
            </GridItem>



        </GridContainer>


        <div>
          <Dialog
            //fullScreen
            fullWidth={true}
            maxWidth={'lg'}
            open={newTestCasePopUpState}
            onClose={() => setNewTestCasePopUpState(false)}
            // TransitionComponent={'Zoom'}
            aria-labelledby="new-test-case-title"
          >

            <DialogTitle id="run-test-case-title"><strong> {`'Test Case Detail'`}  </strong></DialogTitle>

            <div style={{position: "fixed", top: "50%", left: "50%", marginTop: "-100px", marginLeft: "-100px"}}>
                <GridContainer>
                  <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="center"
                        alignItems="center">

                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="125"
                      visible={isLoading}
                    />

                  </GridItem>
                </GridContainer>
              
              </div>


            <div style={{ display: isLoading === false ? '' : 'none'}}>

              
            <DialogContent>

              <Heading
                textAlign="center"
                title= {titleInput}  //"Test Case Title Goes Here"
                category= {descriptionInput}  //"Created using Roboto Font Family"
              />

              <GridContainer>

                <GridItem xs={12} sm={12} md={12}>

                <CustomTabs
                                  // title="Tasks:"
                                  centered={true}
                                  headerColor="primary"
                                  tabs={[
                                    {
                                      tabName: "Status",
                                      tabIcon: StatusIcon,
                                      tabContent: (
                                        <div>


                                          <GridContainer>

                                            <GridItem 
                                                  xs={12}
                                                  container
                                                  direction="row"
                                                  justify="center"
                                                  alignItems="center">

                                            <Chip 
                                                color= "primary" 
                                                icon= {<IDIcon />} 
                                                label= {`${'ID: '}${testCaseID}`}
                                                size="medium"
                                              />


                                              <Chip 
                                                color= "primary" 
                                                icon= {<TitleIcon />} 
                                                label= {`${'Title: '}${titleInput}`}
                                                size="medium"
                                              />

                                              <Chip 
                                                color= "primary" 
                                                icon= {<DescriptionIcon />} 
                                                label= {`${'Description: '}${descriptionInput}`}
                                                size="medium"
                                              />

                                              <Chip 
                                                color= "primary" 
                                                icon= {<PcapIcon />} 
                                                label= {`${'PCAP: '}${pcapItemsSelection}`}
                                                size="medium"
                                              />

                                              <Chip 
                                                color= "primary" 
                                                icon= {<FrequencyIcon />} 
                                                label= {`${'Frequency: '}${freqencyInputSelection}`}
                                                size="medium"
                                              />


                                            </GridItem>

                                          </GridContainer>

                                          <br></br>



                                        <div style={{ maxWidth: "100%" }}>
                                          <MaterialTable
                                            columns={viewTestRunStepListTableColumn}
                                            data={viewTestRunStepListDataSet}
                                            title=""
                                            options={{
                                              // exportButton: true,
                                              // exportAllData: true,
                                              // filtering: true,
                                              showTitle: true,
                                              pageSize: 10,
                                              search: false,
                                              actionsColumnIndex: -1,
                                              // selection: true

                                              // rowStyle: { backgroundColor: 'black'},
                                              rowStyle: rowData => {

                                                // console.log(testRunStepFocus);
                                                // console.log(rowData.tableData.index);
           
                                                if(rowData.tableData.index == testRunStepFocus - 1) {
                                                  return {backgroundColor: 'rgb(238, 255, 51)', textDecoration: 'underline', fontWeight: 'bold'};
                                                } else{
                                                  return {backgroundColor: 'rgb(164, 165, 155)'};
                                                }
                                                
                                                return {};
                                              }
                                            }}


                                            
                                          />

                                          <br></br>

                                          <Card>
                                        <CardHeader color="info" icon>
    
                                          <h4 className={
                                            classes.cardIconTitle
                                          }>
                                            <b>Comment</b>
                                          </h4>
                                        </CardHeader>
                                        <CardBody>
                                          <form onSubmit={e => { e.preventDefault(); }}> 
                                            <CustomInput
                                              labelText= {commentInput} //"Example: Unable to select CC option on Step# 5"
                                              //value=""
                                              formControlProps={{
                                                fullWidth: true
                                              }}
                                              inputProps={{
                                                // onBlur: event => {setCommentInput(event.target.value)},
                                                type: "text"
                                              }}
                                              
                                          />
                                          
                                          </form>


                                        </CardBody>

                                      </Card>

                                        </div>

                        

                                      </div>
                                      ),
                                    },
                                  ]}
                                />


                </GridItem>

              </GridContainer>


            </DialogContent>

            
            </div>

            <DialogActions>

            <div >
 
              <Button color="github" onClick={handleRunTestCasePopupClose}>
                Close
              </Button>

            </div>

            </DialogActions>
              

          </Dialog>
        </div>
        
      

    </div>
  );
}
