/*eslint-disable*/
import React, { useCallback, useRef } from 'react';
import { useHistory } from "react-router-dom";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import NavPills from "components/NavPills/NavPills.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// import CardText from "components/Card/CardText.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import SweetAlert from "react-bootstrap-sweetalert";
import FormHelperText from '@mui/material/FormHelperText';
import Heading from "components/Heading/Heading.js";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from '@material-ui/core/Chip';
// import FormLabel from "@material-ui/core/FormLabel";
// import CircularProgress from '@material-ui/core/CircularProgress';
// import InputAdornment from "@material-ui/core/InputAdornment";
// import ModelIcon from "@material-ui/icons/Devices";
// import EmailIcon from "@material-ui/icons/Email";
// import WebsiteIcon from "@material-ui/icons/Language";
// import BrandIcon from "@material-ui/icons/LocalOffer";
// import SendIcon from "@material-ui/icons/Send";
// import FileIcon from "@material-ui/icons/List";
// import DescriptionIcon from "@material-ui/icons/Description";

// @material-ui/icons
import AssignmentIcon from "@material-ui/icons/Assignment";
// import AddressIcon from "@material-ui/icons/Language";
// import VersionIcon from "@material-ui/icons/Build";
// import PersonIcon from "@material-ui/icons/Person"; // For User Agent
// import PlayIcon from "@material-ui/icons/PlayArrow";
// import CheckIcon from "@material-ui/icons/CheckCircle";
// import ErrorIcon from "@material-ui/icons/Error";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import TvIcon from "@material-ui/icons/Tv";
// import UploadIcon from "@material-ui/icons/CloudUpload";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import TestCaseIcon from "@material-ui/icons/FormatListBulleted";
import TestSuiteIcon from "@material-ui/icons/ListAlt";
import RefreshIcon from "@material-ui/icons/RefreshSharp";
import CreateNewIcon from "@material-ui/icons/NoteAdd";
import RunIcon from "@material-ui/icons/PlayCircleFilledSharp";
import EditIcon from "@material-ui/icons/Edit";
import TitleIcon from "@material-ui/icons/Title";
import CategoryIcon from "@material-ui/icons/Category";
import DescriptionIcon from "@material-ui/icons/Description";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import NewStepIcon from "@material-ui/icons/PlaylistAdd";
import ListIcon from "@material-ui/icons/ViewList";
import TestListIcon from "@material-ui/icons/PlaylistAddCheck";
import PcapIcon from "@material-ui/icons/VideoLibrarySharp";
import FrequencyIcon from "@material-ui/icons/GraphicEq";
import MoveUpIcon from "@material-ui/icons/KeyboardArrowUpTwoTone";
import MoveDownIcon from "@material-ui/icons/KeyboardArrowDownTwoTone";
import ChannelIcon from "@material-ui/icons/SettingsRemote";
import DetailIcon from "@material-ui/icons/Info";
import ComputerIcon from "@material-ui/icons/ComputerSharp";
import StartIcon from "@material-ui/icons/PlayCircleFilledSharp";
import StatusIcon from "@material-ui/icons/History";
import ReportIcon from "@material-ui/icons/Assessment";
import IDIcon from "@material-ui/icons/Label";
import PassIcon from "@material-ui/icons/Check";
import FailIcon from "@material-ui/icons/Close";
import NextIcon from "@material-ui/icons/NavigateNext";
import TestIcon from "@material-ui/icons/FormatListNumbered";
import TvIcon from "@material-ui/icons/Tv";

// 3rd Party Components
import MaterialTable from "@material-table/core";
import { ThreeDots } from  'react-loader-spinner'
import { CirclesWithBar } from  'react-loader-spinner'
import { RotatingLines } from  'react-loader-spinner'

// REST API
import RestAPI from "../../Service/RestAPI.json"

// Global State
import { useAtom } from 'jotai'
import { user } from "../../store/store.js";


import jsonTableData from "../../JSON/pcapDataSetMapping.json";
import jsonPcapFileMapping from "../../JSON/pcapFileMapping.json";


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import stylesSweetAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { ConsoleLogger } from "@aws-amplify/core";
import { BluetoothAudioSharp } from "@material-ui/icons";

const useStyles = makeStyles(styles);
const useStylesSweetAlert = makeStyles(stylesSweetAlert);
const useStylesDashboard = makeStyles(dashboardStyle);



export default function TestSuite() {

  const history = useHistory();

  // Load Page
  const [isLoading, setIsLoading] = React.useState(false);

  const [alert, setAlert] = React.useState(null);

  const [testCaseList, setTestCaseList] = React.useState(['']);
  const [testSuiteList, setTestSuiteList] = React.useState(['']);


  const [testSuiteTitleInput, setTestSuiteTitleInput] = React.useState(['']);
  const [testSuiteDescriptionInput, setTestSuiteDescriptionInput] = React.useState(['']);

  // Create New Test Case Pop up
  const [newTestCasePopUpState, setNewTestCasePopUpState] = React.useState(false); 

  // Create Edit Test Case Pop up
  const [editTestCasePopUpState, setEditestCasePopUpState] = React.useState(false); 

  // Create Run Test Case Pop up
  const [newTestCaseRunPopUpState, setNewTestCaseRunPopUpState] = React.useState(false); 

  const [testCaseHeader, setTestCaseHeader] = React.useState('');

  const [testCaseID, setTestCaseID] = React.useState(0);
  const [categoryInput, setCategoryInput] = React.useState('');
  const [titleInput, setTitleInput] = React.useState('');
  const [descriptionInput, setDescriptionInput] = React.useState('');

  const [currentTestSteps, setCurrentTestSteps] = React.useState([]);
  const [currentTestStepsType, setCurrentTestStepsType] = React.useState([]);

  const [currentTestCaseSelection, setCurrentTestCaseSelection] = React.useState([]);

  const [testCaseListTable, setTestCaseListTable] = React.useState(['']);

  const [customInput, setCustomInput] = React.useState('');

  const [freqencyInputSelection, setFreqencyInputSelection] = React.useState('473');


  const [pcapItems, setPcapItems] = React.useState(['']);
  const [pcapItemsSelection, setPcapItemsSelection] = React.useState(['']);

  const [selectTypeItems, setSelectTypeItems] = React.useState(['NA', "Pass/Fail"]);
  const [selectTypeSelection, setSelectTypeSelection] = React.useState(['NA']);

  const [channelListItem, setChannelListItem] = React.useState(['']);
  const [channelListSelection, setChannelListSelection] = React.useState('');

  const [testCaseListDetail, setTestCaseListDetail] = React.useState([]);

  const [uniqueTvNameItems, setUniqueTvNameItems] = React.useState([]);
  const [uniqueTvNameSelection, setUniqueTvNameSelection] = React.useState([]);

  const [installPcapList, setInstallPcapList] = React.useState(['']);

  const [testRunStepFocus, setTestRunStepFocus] = React.useState(0);

  const [specificTestCaseTable, setSpecificTestCaseTable] = React.useState([]);

  const [testCaseFocus, setTestCaseFocus] = React.useState(0);


  const [testStatusTotalCount, setTestStatusTotalCount] = React.useState(0);
  const [testStatusCurrentCount, setTestStatusCurrentCount] = React.useState(0);
  const [testStatusPassCount, setTestStatusPassCount] = React.useState(0);
  const [testStatusFailCount, setTestStatusFailCount] = React.useState(0);
  const [failStepLog, setFailStepLog] = React.useState([]);
  const [passStepLog, setPassStepLog] = React.useState([]);

  const [commentInput, setCommentInput] = React.useState('');

  const [testSuiteID, setTestSuiteID] = React.useState('');

  const [userInfo, setUserInfo] = useAtom(user);

  const [testSuiteRunList, setTestSuiteRunList] = React.useState([]);
  const [testSuiteRunCount, setTestSuiteRunCount] = React.useState([]);

  const [uniqueTestRunID, setUniqueTestRunID] = React.useState("");


  const hideAlert = () => {
    setAlert(null);
  };


  //console.log(testRunStepFocus)

  const successAlert = (message) => {

    // console.log(testCaseFocus);
    // console.log(testSuiteRunCount);

    if(testCaseFocus < testSuiteRunCount){

      // setTestCaseFocus(prevCount => prevCount + 1);

      // let TestCaseIncrement = testSuiteRunList.filter((testCase) => testCase['Group'] === testCaseFocus + 1);

      // console.table(TestCaseIncrement);

      // setTestCaseID(TestCaseIncrement[0].ID);
      // setCategoryInput(TestCaseIncrement[0].Category);
      // setTitleInput(TestCaseIncrement[0].Title);
      // setDescriptionInput(TestCaseIncrement[0].Description);
      // setFreqencyInputSelection(TestCaseIncrement[0].Frequency);
      // setPcapItemsSelection(TestCaseIncrement[0].PCAP);

      // setCurrentTestSteps(TestCaseIncrement.map((data) => data.Step));
      // setCurrentTestStepsType(TestCaseIncrement.map((data) => data.Type));
      // setSpecificTestCaseTable(TestCaseIncrement);


      // setTestRunStepFocus(0);


      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={message}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes2.button + " " + classes2.success}
        >
          {/* Test Case has been Saved! */}
        </SweetAlert>
      );
  
      handleStopInterval(); 

      


    }

    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={message}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes2.button + " " + classes2.success}
      >
        {/* Test Case has been Saved! */}
      </SweetAlert>
    );

  };


  const failAlert = (message) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={message}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes2.button + " " + classes2.danger}
      >
        {/* Test Case has been Saved! */}
      </SweetAlert>
    );
  };



  const getTestCaseRunStatus = async (randomIDdata) => {

    const axios = require('axios');

    // uniqueTestRunID
    // a1525485451921

    console.log(randomIDdata)

    axios.post(`${RestAPI.testCase[0].testRunResult}`, {

      UniqueTestRunID: randomIDdata

    })
    .then((response) => {
      // console.log(response);

      //setTestCaseRunResult(response.data.testResult);
      setSpecificTestCaseTable(response.data.testResult);

      console.table(response.data.testResult);

      

      // console.table(response.data.lastCompletedIndexNum);

      setTestRunStepFocus(response.data.lastCompletedIndexNum);

      if(response.data.lastCompletedIndexNum == -1){

        let resultFilter = response.data.testResult;
        resultFilter = resultFilter.filter(x => x.Result == "Fail");
        resultFilter = [...new Set(resultFilter.map(x => x['Result']))];
        // console.log(resultFilter);
        // console.log(resultFilter.length);

        if(resultFilter.length > 0){
          saveTestRunResult("Fail", -1, randomIDdata);

        }else{
          saveTestRunResult("Pass", -1, randomIDdata);
        }


	      successAlert("Test Run Completed!");


      }



    });
    
  
  };





  const getTestSuiteList = async () => {

    const axios = require('axios');
  
  try {
    const resp = await axios.get(`${RestAPI.testSuite[0].testSuiteData}`);

    
    setTestSuiteList(resp.data.testSuiteList);
    


  } catch (err) {
      // Handle Error Here
      console.error(err);

  }


};



  const getTestCaseList = async () => {

    const axios = require('axios');
  
  try {
    const resp = await axios.get(`${RestAPI.testCase[0].testCaseList}`);

    console.table(resp.data.testCaseTable);

    setTestCaseList(resp.data.testCaseTable);
    


  } catch (err) {
      // Handle Error Here
      console.error(err);

  }


};


const getTestCaseListDetail = async () => {

  const axios = require('axios');

try {
  const resp = await axios.get(`${RestAPI.testCase[0].detailTestCaseList}`);

  setTestCaseListDetail(resp.data.testCaseListDetail);


} catch (err) {
    // Handle Error Here
    console.error(err);

}


};


const getTestCaseStepList = async () => {

  const axios = require('axios');

try {
  const resp = await axios.get(`${RestAPI.testCase[0].testCaseStepList}`);

  setTestCaseListTable(resp.data.testCaseStepList);
  //console.log(resp.data.testCaseStepList);
  


} catch (err) {
    // Handle Error Here
    console.error(err);

}


};


const getAnalyzedPcapFiles = async () => {

  const axios = require('axios');

try {
  const resp = await axios.get(`${RestAPI.fileUpload[0].uploadTable}`);

  //console.log(resp.data.analyzedPcapList_mod);
  setPcapItems(resp.data.analyzedPcapList_mod);


} catch (err) {
    // Handle Error Here
    console.error(err);

}



};


const getChannelList = async (filename) => {

  const axios = require('axios');

  axios.post(`${RestAPI.testCase[0].channelList}`, {

    filename: filename
  
  })
  .then((response) => {
    // console.log(response);
  
    setChannelListItem(response.data.channelList);
  
  });


};


const handleChannel = event => {
  setChannelListSelection(event.target.value);

}






  React.useEffect(() => {

    getTestCaseList();
    getTestCaseListDetail();
    getAnalyzedPcapFiles();
    getTestCaseStepList();

    getTestSuiteList();

    getComputerStatusTable();

  }, [])
  




  const viewtestCaseTableColumn = [
    {
      title: "ID",
      field: "id"
    },
    {
      title: "Category",
      field: "category"
    },
    {
      title: "Title",
      field: "title"
    },
    {
      title: "Description",
      field: "description"
    }
  ];


  const viewTestCaseDataSet = testCaseList.map((data) => {

    return {
      id: data.ID,
      category: data["Category"],
      title: data["Title"],
      description: data["Description"]
    };
  });



  const viewtestCaseStepTableColumn = [
    {
      title: "Step",
      field: "step"
    },
    {
      title: "Type",
      field: "type"
    }
  ];


  const viewTestCaseStepDataSet = testCaseListTable.map((data) => {

    return {
      step: data["Step"],
      type: data["Type"]
    };
  });


  const viewCurrentTestCaseTableColumn = [
    {
      title: "ID",
      field: "id"
    },    {
      title: "Category",
      field: "category"
    },    {
      title: "Title",
      field: "title"
    },    {
      title: "Description",
      field: "description"
    }
  ];


  const viewCurrentTestCaseDataSet = currentTestCaseSelection.map((data) => {

    return {
      // step: data["Step"]
      // step: data
      id: data["ID"],
      category: data["Category"],
      title: data["Title"],
      description: data["Description"]
    };
  });




  const filterTest = specificTestCaseTable.map((data, index) => {

    data

    return {
      // step: data["Step"]
      step: data
    };



  });







  const viewTestRunStepListTableColumn = [

    {
      title: "Test ID",
      field: "testCaseID",
      width: "10%",
      // align: "center"
      // cellStyle: {
      //   textAlign:'center'
      // },
      // headerStyle: {
      //   textAlign:'center'
      // }
    },

    {
      title: "Step #",
      field: "index",
      width: "10%"
    },

    {
      title: "PCAP",
      field: "pcap",
      width: "10%"
    },

    {
      title: "Description",
      field: "step",
      width: "50%",
    },

    {
      title: "Status",
      field: "status",
      width: "10%",
    },

    {
      title: "Result",
      field: "result",
      width: "10%",
    }
  ];

  

  var viewTestRunStepListDataSet = specificTestCaseTable.map((data, index) => {

    return {
      testCaseID: data["ID"],
      index: index + 1,
      step: data["Step"],
      pcap: data["PCAP"],
      type: data["Type"],
      status: data["Status"],
      result: data["Result"]
    };
  });




  const viewtestCaseListDetailTableColumn = [
    {
      title: "ID",
      field: "id"
    },
    {
      title: "Category",
      field: "category"
    },
    {
      title: "Title",
      field: "title"
    },
    {
      title: "Description",
      field: "description"
    },
    {
      title: "Freqency",
      field: "frequency"
    },
    {
      title: "PCAP",
      field: "pcap"
    },
    {
      title: "Step",
      field: "step"
    },
    {
      title: "Type",
      field: "type"
    }
  ];


  const viewTestCaseListDetailDataSet = testCaseListDetail.map((data) => {

    return {
      id: data["ID"],
      category: data["Category"],
      title: data["Title"],
      description: data["Description"],
      frequency: data["Frequency"],
      pcap: data["PCAP"],
      step: data["Step"],
      type: data["Type"]
    };
  });




  const viewtestSuiteTableColumn = [
    {
      title: "Date",
      field: "date"
    },
    {
      title: "Test Suite Title",
      field: "testSuiteTitle"
    },
    {
      title: "Test Suite Description",
      field: "testSuiteDescription"
    },
    {
      title: "Test Suite ID",
      field: "testSuiteId"
    },
    {
      title: "User",
      field: "user"
    }
  ];


  const viewTestSuiteDataSet = testSuiteList.map((data) => {

    return {
      date: data["Date"],
      testSuiteTitle: data["Test.Suite.Title"],
      testSuiteDescription: data["Test.Suite.Description"],
      testSuiteId: data["Test.Suite.ID"],
      user: data["User"]

    };
  });



  const handlePcap = event => {

    if(event.target.value !== ''){
      setPcapItemsSelection(event.target.value);
    }
  
  };


  const handleCustomType = event => {

    setSelectTypeSelection(event.target.value);
  
  };



  const handleAdd = async () => {

    const axios = require('axios');
  
    axios.post(`${RestAPI.testCase[0].customText}`, {
  
      customText: customInput,
      customType: selectTypeSelection
    
    })
    .then((response) => {
      // console.log(response);
    
      getTestCaseStepList();
    
    });
  
  
  };


  function generateUID() {
    var firstPart = (Math.random() * 46656) | 0;
    var secondPart = (Math.random() * 46656) | 0;
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
}


  const handleSave = async () => {

    let testSuiteGroupID = generateUID();

    let result = testSuiteList.filter(x => x["Test.Suite.ID"]  === testSuiteGroupID);

    while(result.length !== 0){

      testSuiteGroupID = generateUID();
      console.table(testSuiteGroupID);
      result = testSuiteList.filter(x => x["Test.Suite.ID"]  === testSuiteGroupID);

    }

    if(testSuiteID !== ""){
      testSuiteGroupID = testSuiteID
    }

    // console.log(testSuiteGroupID);
    // console.log(userInfo);
    // console.log(currentTestCaseSelection.map((data) => data.ID));
    // console.log(testSuiteTitleInput);
    // console.log(testSuiteDescriptionInput);

    const axios = require('axios');

    axios.post(`${RestAPI.testSuite[0].saveTestSuite}`, {

      "Test.Suite.ID": testSuiteGroupID,
      User: userInfo,
      "Test.Case.ID": currentTestCaseSelection.map((data) => data.ID),
      "Test.Suite.Title": testSuiteTitleInput,
      "Test.Suite.Description": testSuiteDescriptionInput
    

    })
    .then((response) => {
      // console.log(response);
      setNewTestCasePopUpState(false);
      getTestCaseList();
      getTestCaseListDetail();
      getTestSuiteList()
      setTestSuiteTitleInput('')
      setTestSuiteDescriptionInput('')
    
      // Create a quick pop-up
      successAlert(`${"Save Success! Test Suite ID: "}${testSuiteGroupID}`);
      
      // Reset Save Items
      resetState();

    });

  
  
  };


  const resetState = () => {

          // Reset Save Items
          setTestCaseID(0);
          setTestCaseHeader('');
          setCategoryInput('');
          setTitleInput('');
          setDescriptionInput('');
          setCurrentTestSteps([]);
          setFreqencyInputSelection('473');
          setPcapItemsSelection(['']);

          setTestRunStepFocus(0);
          setCurrentTestCaseSelection([]);
          setTestStatusPassCount(0);
          setTestStatusFailCount(0);

          setFailStepLog([]);
          setPassStepLog([]);


          setTestSuiteTitleInput('');
          setTestSuiteDescriptionInput('');

          setTestSuiteID('');

          setCommentInput('');


          handleStopInterval();


  }



  const handleTestCasePopupNew = () => {

    resetState();

    setTestCaseHeader("Create New Test Suite");
    setNewTestCasePopUpState(true); 
  

  };


  const handleTestCasePopupOld = (payload) => {

    // let customPageTitle = `${"Edit Test Case - ID# "}${payload}`;

    // setTestCaseHeader(customPageTitle);
    setNewTestCasePopUpState(true); 
  
  };


  const handleTestCasePopupClose = () => {

    setNewTestCasePopUpState(false);
    resetState();


  };



  const handleTestCasePopupRun = (payload) => {

    // let customPageTitle = `${"Edit Test Case - ID# "}${payload}`;

    // setTestCaseHeader(customPageTitle);
    // setNewTestCasePopUpState(true); 


    setNewTestCaseRunPopUpState(true); 
    setIsLoading(true); 

  
  };


  const handleRunTestCasePopupClose = () => {

    setNewTestCaseRunPopUpState(false);
    resetState();


    handleStopInterval(); // Stop Refreshing Data


  };


  const handleRunTestCaseHardFail = () => {

    setTestRunStepFocus(-1);
    saveTestRunResult("Fail", testRunStepFocus);
    successAlert(`${"Test Case "}${testCaseFocus}${" of "}${testSuiteRunCount}${" Completed"}`);

  };

  const handleViewReport = () => {

    history.push('/admin/report');

  };




  
  const handleEdit = async (testSuiteId, testSuiteTitle, testSuiteDescription) => {

    // console.log(testSuiteId);

    setTestSuiteTitleInput(testSuiteTitle);
    setTestSuiteDescriptionInput(testSuiteDescription);

    setTestSuiteID(testSuiteId);

    const axios = require('axios');
  
    // axios.post(`${RestAPI.testCase[0].specificTestCase}`, {
    axios.post(`${RestAPI.testSuite[0].specificTestSuite}`, {
  
      testSuiteUniqueId: testSuiteId
    
    })
    .then((response) => {
      //console.table(response.data.saveTestSuiteCaseInfo);

      // setTestCaseID(response.data.specificTestCase[0].ID);
      // setCategoryInput(response.data.specificTestCase[0].Category);
      // setTitleInput(response.data.specificTestCase[0].Title);
      // setDescriptionInput(response.data.specificTestCase[0].Description);
      // setFreqencyInputSelection(response.data.specificTestCase[0].Frequency);
      // setPcapItemsSelection(response.data.specificTestCase[0].PCAP);

      // let saveSteps = response.data.specificTestCase;

      // setCurrentTestSteps(saveSteps.map((data) => data.Step));
      

      // let testIDinfo = response.data.specificTestCase[0].ID;


      setCurrentTestCaseSelection(response.data.saveTestSuiteCaseInfo);



      handleTestCasePopupOld();

  
    
    });




  
  };


  const handleRun = async (testSuiteId, testSuiteTitle, testSuiteDescription) => {

    handleTestCasePopupRun();

    //////////////////////

    setTestSuiteTitleInput(testSuiteTitle);
    setTestSuiteDescriptionInput(testSuiteDescription);
    setTestSuiteID(testSuiteId);

    const axios = require('axios');

    axios.post(`${RestAPI.testSuite[0].specificTestSuiteRun}`, {
  
      testSuiteUniqueId: testSuiteId
    
    })
    .then((response) => {
      console.table(response.data.testCaseList);

      setTestSuiteRunList(response.data.testCaseList);

      let customFilter = response.data.testCaseList;
      customFilter = customFilter.map((testCaseDetail) => testCaseDetail.Group);
      customFilter = [... new Set(customFilter)]
      let testGroupCount = customFilter.length;

      setTestSuiteRunCount(testGroupCount);


      let firstTestCase = response.data.testCaseList
      firstTestCase = firstTestCase.filter((testCase) => testCase['Group'] === 1);

      // console.log(firstTestCase[0].Category)

      setTestCaseID(firstTestCase[0].ID);
      setCategoryInput(firstTestCase[0].Category);
      setTitleInput(firstTestCase[0].Title);
      setDescriptionInput(firstTestCase[0].Description);
      setFreqencyInputSelection(firstTestCase[0].Frequency);
      setPcapItemsSelection(firstTestCase[0].PCAP);

      // setCurrentTestSteps(firstTestCase.map((data) => data.Step));
      // setCurrentTestStepsType(firstTestCase.map((data) => data.Type));
      // setSpecificTestCaseTable(firstTestCase);

      setTestCaseFocus(1);


      setCurrentTestSteps(firstTestCase.map((data) => data.Step));
      setCurrentTestStepsType(firstTestCase.map((data) => data.Type));
      setSpecificTestCaseTable(response.data.testCaseList);
      

      





      // setCurrentTestCaseSelection(response.data.saveTestSuiteCaseInfo);
      // handleTestCasePopupOld();

      setIsLoading(false);
    
    });







    /////////////////////







    // getInstallPcapListTable(); 

    // handleTestCasePopupRun(testCaseID);

    // const axios = require('axios');
  
    // axios.post(`${RestAPI.testCase[0].specificTestCase}`, {
  
    //   testID: testCaseID,
    //   testTitle: testCaseTitle,
    //   testDescription: testCaseDescription
    
    // })
    // .then((response) => {
    //   // console.log(response);

    //   setTestCaseID(response.data.specificTestCase[0].ID);
    //   setCategoryInput(response.data.specificTestCase[0].Category);
    //   setTitleInput(response.data.specificTestCase[0].Title);
    //   setDescriptionInput(response.data.specificTestCase[0].Description);
    //   setFreqencyInputSelection(response.data.specificTestCase[0].Frequency);
    //   setPcapItemsSelection(response.data.specificTestCase[0].PCAP);


    //   let saveSteps = response.data.specificTestCase;

    //   setCurrentTestSteps(saveSteps.map((data) => data.Step));
    //   setCurrentTestStepsType(saveSteps.map((data) => data.Type));
    //   setSpecificTestCaseTable(saveSteps);

      
  
    //   setIsLoading(false);
    
    // });





  
  };



  const handleTvName = event => {
    setUniqueTvNameSelection(event.target.value);
    // setPcapItems(event.target.value);
  
  };



  const getInstallPcapListTable = async () => {

    const axios = require('axios');
  
  try {
    const resp = await axios.get(`${RestAPI.remoteControl[0].fileList}`);
  
    setInstallPcapList(resp.data.fileList);
  
    let uniqueComputerNames = resp.data.fileList;
    uniqueComputerNames = uniqueComputerNames.map((data) => data.Computer_Name);
    setUniqueComputerNameItems([...new Set(uniqueComputerNames)]);
  
  } catch (err) {
      // Handle Error Here
      console.error(err);
  
  }
  
  
  };



  const getTestStatus =  () => {

    let stepCount = testRunStepFocus + 1;

    if(testRunStepFocus + 1 > specificTestCaseTable.length){
      stepCount = testRunStepFocus;
    }

    return (
      `${stepCount}${"/"}${specificTestCaseTable.length}`
    )
  
  };



  const saveTestRunResult = async (result, lastStep) => {

    // console.log(result);
    //console.log(testSuiteID);

    const axios = require('axios');

    // console.log(testCaseID);
    // console.log(testCaseTitle);
    // console.log(testCaseDescription);
    // console.log(pcapItemsSelection);
    // console.log(freqencyInputSelection);
    // console.log(userInfo);
    // console.log(testStatusPassCount);
    // console.log(testStatusFailCount);
    // console.log(failStepLog);
    // console.log(passStepLog);
  
    axios.post(`${RestAPI.testCase[0].testResult}`, {
  
      testID: testCaseID,
      testSuiteId: testSuiteID,
      testResult: result,
      user: userInfo,
      lastStep: lastStep + 1,
      comment: commentInput
      // error
      // testTitle: testCaseTitle,
      // testDescription: testCaseDescription,
      // pcapFilename: pcapItemsSelection,
      // frequency: freqencyInputSelection,
      // user: userInfo,
      // passCount: testStatusPassCount,
      // failCount: testStatusFailCount,
      // failStep: failStepLog,
      // passStep: passStepLog
      
    })
    .then((response) => {
      // console.log(response);
      //setTestRunStepFocus(-1);
      
      

    
    });




  
  };



  const getComputerStatusTable = async () => {

    const axios = require('axios');
  
  try {
    const resp = await axios.get(`${RestAPI.remoteControl[0].computerStatus}`);
  
    //setComputerStatusList(resp.data.computerStatus);
  
  
    let uniqueTvNames = resp.data.computerStatus
    uniqueTvNames = uniqueTvNames.map((data) => data.TvAppName);
  
    setUniqueTvNameItems(uniqueTvNames);
  
  } catch (err) {
      // Handle Error Here
      console.error(err);
  
  }
  
  
  };



  const intervalRef = useRef();

  //console.log(myInterval)

  const handleInterval = (testID) => {
    console.log(myInterval)
    console.log(testID);

    setUniqueTestRunID(testID);

    const myInterval = setInterval( () => {

      getTestCaseRunStatus(testID)

    }, 5000);

    intervalRef.current = myInterval;

  }

  const handleStopInterval =  () => {

    clearInterval(intervalRef.current);

  }



  const handleTestSuiteRun = async () => {

    console.log(testCaseID);

    let randomID = Math.floor(Math.random() * Date.now())
    randomID = randomID.toString();
    randomID = "a" + randomID

    setUniqueTestRunID(randomID);


    const axios = require('axios');
  
    axios.post(`${RestAPI.testSuite[0].testRunAutomation}`, {

      uniqueTestRunID: randomID,
      tvSelection: uniqueTvNameSelection,
      testCaseID: testCaseID,
      testSuiteID: testSuiteID,
      testRunType: "Test Suite"
    
    })
    .then((response) => {
      // console.log(response);


      handleInterval(randomID);
    
      
    
    });


    
  
  };





  const classes = useStyles();
  const classes2 = useStylesSweetAlert();
  const dashboardClasses = useStylesDashboard();

  return (
    <div>
      
      {alert}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
            tabs={[
              {
                tabName: "Test Suite List",
                tabIcon: TestCaseIcon,
                tabContent: (
                  <div>
                    <div style={{ maxWidth: "100%" }}>
                      <MaterialTable
                        columns={viewtestSuiteTableColumn}
                        data={viewTestSuiteDataSet}
                        title="Test Suite"
                        options={{
                          exportButton: true,
                          exportAllData: true,
                          filtering: true,
                          showTitle: false,
                          pageSize: 10,
                          search: true
                        }}

                        actions={[
                          {
                            icon: RunIcon,
                            tooltip: 'Run Test Case',
                            onClick: (event, rowData) => {
                              // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                              //onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                              // setFullReviewModal(true); // Show Pop-up

                              //console.log(rowData.id, rowData.title, rowData.description, ()=>{});

                              //handleRun(rowData.id, rowData.title, rowData.description);


                              console.table(rowData);

                              handleRun(rowData.testSuiteId, rowData.testSuiteTitle, rowData.testSuiteDescription, rowData.testSuiteId);

                            }

                          },

                          {
                            icon: EditIcon,
                            tooltip: 'Edit Test Case',
                            onClick: (event, rowData) => {
                              // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                              //onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                              // setFullReviewModal(true); // Show Pop-up

                              //console.log(rowData.id, rowData.title, rowData.description, ()=>{});

                              // handleEdit(rowData.id, rowData.title, rowData.description);

                              // console.table(rowData);

                              handleEdit(rowData.testSuiteId, rowData.testSuiteTitle, rowData.testSuiteDescription);
                              
                              

                          

                            }

                          },

                          {

                            icon: RemoveIcon,
                            tooltip: 'Remove Step',
                            onClick: (event, rowData) => {
                              // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                              //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});

                              const axios = require('axios');

                              axios.post(`${RestAPI.testSuite[0].removeTestSuite}`, {
                            
                                removeTestSuite: rowData.testSuiteId
                              
                              })
                              .then((response) => {
                                // console.log(response);
                              
                                // getTestCaseList();
                                // getTestCaseListDetail();
                                getTestSuiteList();
                              
                              });


                            }

                          }

                        ]}
                        
                        
                      />
                    </div>


                    <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center">

                          <Button color="warning" size="sm" style={{marginTop: "10%"}} onClick={handleTestCasePopupNew} > 
                            <CreateNewIcon className={classes.icons} />
                              Create New Test Suite
                          </Button>
                        

                      </GridItem>

                        {/* Create New Test Case Pop-Up */}


                        <div>
                          <Dialog
                            //fullScreen
                            fullWidth={true}
                            maxWidth={'lg'}
                            open={newTestCasePopUpState}
                            onClose={() => setNewTestCasePopUpState(false)}
                            // TransitionComponent={'Zoom'}
                            aria-labelledby="new-test-case-title"
                          >
                            <DialogTitle id="new-test-case-title"><strong> {`${testCaseHeader}`}  </strong></DialogTitle>
                            <DialogContent>


                              <GridContainer>

                                <GridItem xs={12}
                                    sm={12}
                                    md={6}>

                                    <Card>
                                      <CardHeader color="rose" icon>
                                        <CardIcon color="rose">
                                          <TitleIcon />
                                          
                                          
                                        </CardIcon>
                                        <h4 className={
                                          classes.cardIconTitle
                                        }>
                                          <b>Test Suite Title</b>
                                        </h4>
                                      </CardHeader>
                                      <CardBody>
                                        <form onSubmit={e => { e.preventDefault(); }}> 
                                          <CustomInput
                                            labelText="Example: ATSC Webapp Testing"
                                            id="Input_TestCase_New_Item"
                                            // value={""}
                                            helperText={`${'Current: '}${testSuiteTitleInput}`}
                                            formControlProps={{
                                              fullWidth: true
                                            }}
                                            inputProps={{
                                              onBlur: event => {setTestSuiteTitleInput(event.target.value)},
                                              type: "text"
                                            }}
                                            
                                        />
                                        
                                        </form>

                                        
                                      </CardBody>

                                    </Card>

                                  </GridItem>


                                  <GridItem xs={12}
                                    sm={12}
                                    md={6}>

                                    <Card>
                                      <CardHeader color="rose" icon>
                                        <CardIcon color="rose">
                                          <DescriptionIcon />
                                          
                                          
                                        </CardIcon>
                                        <h4 className={
                                          classes.cardIconTitle
                                        }>
                                          <b>Test Suite Description</b>
                                        </h4>
                                      </CardHeader>
                                      <CardBody>
                                        <form onSubmit={e => { e.preventDefault(); }}> 
                                          <CustomInput
                                            labelText="Example: Test various functions related to Webapp"
                                            id="Input_TestCase_New_Item"
                                            // value={""}
                                            helperText={`${'Current: '}${testSuiteDescriptionInput}`}
                                            formControlProps={{
                                              fullWidth: true
                                            }}
                                            inputProps={{
                                              onBlur: event => {setTestSuiteDescriptionInput(event.target.value)},
                                              type: "text"
                                            }}
                                            
                                        />
                                        
                                        </form>

                                        
                                      </CardBody>

                                    </Card>

                                  </GridItem>

                                </GridContainer>
                            

                              <GridContainer>
   
                                <GridItem xs={12} sm={12} md={12} >

                                  <CustomTabs
                                      //title="Tasks:"
                                      centered={true}
                                      headerColor="info"
                                      
                                      tabs={[
                                        {
                                          tabName: "Test Case List",
                                          tabIcon: ListIcon,
                                            tabContent: (
                                              <div>    
                                                  <div style={{ maxWidth: '100%' }}>
                                                          <MaterialTable
                                                            columns={viewtestCaseTableColumn}
                                                            data={viewTestCaseDataSet}
                                                            title="Test Step"
                                                            options={{
                                                              exportButton: false,
                                                              exportAllData: false,
                                                              filtering: true,
                                                              showTitle: false,
                                                              searchAutoFocus: true,
                                                              pageSize: 5,
                                                              // rowStyle: {
                                                              //   backgroundColor: '#EEE',
                                                              // }

                                                            }}
                                                            actions={[
                                                              {
                                                                icon: AddIcon,
                                                                tooltip: 'Add Step',
                                                                onClick: (event, rowData) => {
                                                                  // Grab the data from the table row that was clicked 
                                                                  // onClickAddModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});

                                                                  //console.table(rowData);
                                                                  
                                                                  //setCurrentTestSteps(prevState => prevState.concat(rowData.step));

                                                                  //setCurrentTestStepsType(prevState => prevState.concat(rowData.type));

                                                                  setCurrentTestCaseSelection(prevState => prevState.concat([{

                                                                    ID: rowData.id,
                                                                    Category: rowData.category,
                                                                    Title: rowData.title,
                                                                    Description: rowData.description

                                                                  }]));





                                                                }

                                                              },

                                                              // {
                                                              //   icon: RemoveIcon,
                                                              //   tooltip: 'Remove Step',
                                                              //   onClick: (event, rowData) => {
                                                              //     // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                              //     //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});

                                                              //     const axios = require('axios');
  
                                                              //     axios.post(`${RestAPI.testCase[0].removeTestCaseItem}`, {
                                                                
                                                              //       removeItem: rowData.step
                                                                  
                                                              //     })
                                                              //     .then((response) => {
                                                              //       // console.log(response);
                                                                  
                                                              //       getTestCaseStepList();
                                                                  
                                                              //     });


                                                              //   }

                                                              // }
                                                              
                                                            ]}


                                                          />
                                                    </div>


                                                </div>
                                          )

                                          
                                        },

                                        {
                                          tabName: "Test Case List Detail",
                                          tabIcon: DetailIcon,
                                            tabContent: (
                                              <div>    
                                                  <div style={{ maxWidth: '100%' }}>
                                                          <MaterialTable
                                                            columns={viewtestCaseListDetailTableColumn}
                                                            data={viewTestCaseListDetailDataSet}
                                                            title="Test Step"
                                                            options={{
                                                              exportButton: false,
                                                              exportAllData: false,
                                                              filtering: true,
                                                              showTitle: false,
                                                              searchAutoFocus: true,
                                                              pageSize: 10,
                                                              // rowStyle: {
                                                              //   backgroundColor: '#EEE',
                                                              // }
                          
                                                            }}
                          
                                                          />
                                                    </div>
                          
                          
                                                </div>
                                          )
                          
                                          
                                        }

                                      ]}
                                    /> 

                                  </GridItem>

                              {/* Saved Test Steps */}

                                  <GridItem xs={12} sm={12} md={12} >

                                    <CustomTabs
                                        //title="Tasks:"
                                        centered={true}
                                        headerColor="success"
                                        
                                        tabs={[
                                          {
                                            tabName: "Current Test Suite",
                                            tabIcon: TestListIcon,
                                              tabContent: (
                                                <div>    
                                                    <div style={{ maxWidth: '100%' }}>
                                                            <MaterialTable
                                                              columns={viewCurrentTestCaseTableColumn}
                                                              data={viewCurrentTestCaseDataSet}
                                                              title="Test Steps"
                                                              options={{
                                                                exportButton: false,
                                                                exportAllData: false,
                                                                filtering: true,
                                                                showTitle: false,
                                                                searchAutoFocus: true,
                                                                pageSize: 5
                                                              }}
                                                              actions={[
                                                                {
                                                                  icon: RemoveIcon,
                                                                  tooltip: 'Remove Step',
                                                                  onClick: (event, rowData) => {
                                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                                    //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                                                    
                                                                    setCurrentTestCaseSelection(
                                                                      currentTestCaseSelection.filter(data => data.ID !== rowData.id)
                                                                      )

                                                                  }

                                                                },

                                                                // {
                                                                //   icon: MoveUpIcon,
                                                                //   tooltip: 'Move Up',
                                                                //   onClick: (event, rowData) => {
                                                                //     // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                                //     //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                                                    

                                                                //   }

                                                                // },

                                                                // {
                                                                //   icon: MoveDownIcon,
                                                                //   tooltip: 'Move Down',
                                                                //   onClick: (event, rowData) => {
                                                                //     // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                                //     //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                                                    

                                                                //   }

                                                                // }

                                                              ]}

                                                            />
                                                      </div>


                                                  </div>
                                            )

                                            
                                          }
                                        ]}
                                      /> 

                                    </GridItem>
                                  
                                </GridContainer>


                            
                            </DialogContent>
                            <DialogActions>

                              <Button onClick={handleTestCasePopupClose} color="github">
                                Close
                              </Button>

                              <Button onClick={handleSave} color="success">
                                Save
                              </Button>

                            </DialogActions>
                          </Dialog>
                        </div>


                         {/* Create Run Test Case Pop-Up */}


                          <Dialog

                            //fullScreen
                            fullWidth={true}
                            maxWidth={'lg'}
                            fullScreen={true}
                            open={newTestCaseRunPopUpState}
                            onClose={() => setNewTestCaseRunPopUpState(false)}
                            // TransitionComponent={'Zoom'}
                            aria-labelledby="run-test-case-title"
                          >
                            <DialogTitle id="run-test-case-title"><strong> {`${'Run Test Suite'}`}  </strong></DialogTitle>

                             <div style={{position: "fixed", top: "50%", left: "50%", marginTop: "-100px", marginLeft: "-100px"}}>
                              <GridContainer>
                                <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="center"
                                      alignItems="center">

                                  <RotatingLines
                                    strokeColor="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="125"
                                    visible={isLoading}
                                  />




                                </GridItem>
                              </GridContainer>
                            
                            </div>
                            <div style={{ display: isLoading === false ? '' : 'none'}}>

                           
                            <DialogContent>


                            <Heading
                              textAlign="center"
                              title= {titleInput}  //"Test Case Title Goes Here"
                              category= {descriptionInput}  //"Created using Roboto Font Family"
                            />

                          

                            <div>
                            <GridContainer>

                              <GridItem xs={12}
                                        sm={12}
                                        md={12}>


                                      <Card>
                                        <CardHeader color="rose" icon>
                                          <CardIcon color="rose">
                                            <TvIcon />
                                            
                                            
                                          </CardIcon>
                                          <h4 className={
                                            classes.cardIconTitle
                                          }>
                                            <b>TV</b>
                                          </h4>
                                        </CardHeader>
                                        <CardBody >
                                          <FormControl fullWidth
                                            className={
                                              classes.selectFormControl
                                          }>


                                            <InputLabel htmlFor="computer-select"
                                              className={
                                                classes.selectLabel
                                            }>
                                              Select TV
                                            </InputLabel>

                                            <Select style={
                                                {minWidth: "100%"}
                                              }
                                              MenuProps={
                                                {className: classes.selectMenu}
                                              }
                                              classes={
                                                {select: classes.select}
                                              }
                                              //multiple
                                              autoWidth={false}
                                              value={uniqueTvNameSelection}
                                              
                                              onChange={handleTvName}
                                              inputProps={
                                                {
                                                  name: "computerSelect",
                                                  id: "computer-select"
                                                }
                                            }>
                                              <MenuItem disabled
                                                classes={
                                                  {root: classes.selectMenuItem}
                                              }>
                                                <b>Single Selection</b>
                                              </MenuItem>

                                              {
                                              uniqueTvNameItems.map((UniqueTvNameItems, index) => (
                                                <MenuItem key={index}
                                                  classes={
                                                    {
                                                      root: classes.selectMenuItem,
                                                      selected: classes.selectMenuItemSelected
                                                    }
                                                  }
                                                  value={UniqueTvNameItems}>
                                                  {UniqueTvNameItems} </MenuItem>
                                              ))
                                            } 
                                            </Select>
                                            <FormHelperText style={{opacity: "0"}}>.</FormHelperText>

                                          </FormControl>
                                        </CardBody>
                                      </Card>

                                    </GridItem>

                            </GridContainer>

                            <GridContainer>
                              <GridItem 
                                    xs={12}
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center">

                                  <Button color="success" size="lg" onClick={handleTestSuiteRun}> 
                                    <StartIcon className={classes.icons} />
                                      Run
                                  </Button>

                                

                              </GridItem>

                            </GridContainer>


                            <br></br>

                            <GridContainer>

                              <GridItem xs={12}>
                                <Button color="github" size="sm" style={{width: "100%"}}> <b>Test Run</b> </Button>
                              </GridItem>

                            </GridContainer>

                            <br></br>


                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                  <CardHeader color="info" stats icon>
                                    <CardIcon color="info">
                                      <TestIcon />
                                    </CardIcon>
                                    <p className={dashboardClasses.cardCategory}>Test Suite Status</p>
                                    <h3 className={dashboardClasses.cardTitle}>
                                      {`${testCaseFocus}${" / "}${testSuiteRunCount}${" Completed"}`} 
                                    </h3>
                                  </CardHeader>
                                  <CardFooter stats>
                                    <div className={dashboardClasses.stats}>

                                    </div>
                                  </CardFooter>
                                </Card>
                              </GridItem>

                              {/* <GridItem xs={12} sm={6} md={4}>
                                <Card>
                                  <CardHeader color="success" stats icon>
                                    <CardIcon color="success">
                                      <PassIcon />
                                    </CardIcon>
                                    <p className={dashboardClasses.cardCategory}>Pass</p>
                                    <h3 className={dashboardClasses.cardTitle}>
                                      {testStatusPassCount} 
                                    </h3>
                                  </CardHeader>
                                  <CardFooter stats>
                                    <div className={dashboardClasses.stats}>

                                    </div>
                                  </CardFooter>
                                </Card>
                              </GridItem> */}

                              {/* <GridItem xs={12} sm={6} md={4}>
                                <Card>
                                  <CardHeader color="danger" stats icon>
                                    <CardIcon color="danger">
                                      <FailIcon />
                                    </CardIcon>
                                    <p className={dashboardClasses.cardCategory}>Fail</p>
                                    <h3 className={dashboardClasses.cardTitle}>
                                      {testStatusFailCount} 
                                    </h3>
                                  </CardHeader>
                                  <CardFooter stats>
                                    <div className={dashboardClasses.stats}>

                                    </div>
                                  </CardFooter>
                                </Card>
                              </GridItem> */}

                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                
                                <CustomTabs
                                  // title="Tasks:"
                                  centered={true}
                                  headerColor="primary"
                                  tabs={[
                                    {
                                      tabName: "Status",
                                      tabIcon: StatusIcon,
                                      tabContent: (
                                        <div>


                                          <GridContainer>

                                            <GridItem 
                                                  xs={12}
                                                  container
                                                  direction="row"
                                                  justify="center"
                                                  alignItems="center">

                                            <Chip 
                                                color= "primary" 
                                                icon= {<IDIcon />} 
                                                label= {`${'Test Run ID: '}${uniqueTestRunID}`}
                                                size="medium"
                                              />

                                            <Chip 
                                                color= "primary" 
                                                icon= {<IDIcon />} 
                                                label= {`${'Test Suite ID: '}${testSuiteID}`}
                                                size="medium"
                                              />
                                            
                                            <Chip 
                                                color= "primary" 
                                                icon= {<IDIcon />} 
                                                label= {`${'Test Case ID: '}${testCaseID}`}
                                                size="medium"
                                              />


                                              <Chip 
                                                color= "primary" 
                                                icon= {<TitleIcon />} 
                                                label= {`${'Title: '}${titleInput}`}
                                                size="medium"
                                              />

                                              <Chip 
                                                color= "primary" 
                                                icon= {<DescriptionIcon />} 
                                                label= {`${'Description: '}${descriptionInput}`}
                                                size="medium"
                                              />

                                              <Chip 
                                                color= "primary" 
                                                icon= {<PcapIcon />} 
                                                label= {`${'PCAP: '}${pcapItemsSelection}`}
                                                size="medium"
                                              />

                                              <Chip 
                                                color= "primary" 
                                                icon= {<FrequencyIcon />} 
                                                label= {`${'Frequency: '}${freqencyInputSelection}`}
                                                size="medium"
                                              />


                                            </GridItem>

                                          </GridContainer>

                                          <br></br>



                                        <div style={{ maxWidth: "100%" }}>
                                          <MaterialTable
                                            columns={viewTestRunStepListTableColumn}
                                            data={viewTestRunStepListDataSet}
                                            title=""
                                            options={{
                                              // exportButton: true,
                                              // exportAllData: true,
                                              // filtering: true,
                                              showTitle: true,
                                              pageSize: 10,
                                              search: false,
                                              actionsColumnIndex: -1,
                                              
                                              // selection: true

                                              // rowStyle: { backgroundColor: 'black'},
                                              rowStyle: rowData => {

                                                // console.log(rowData);
                                                // console.log(rowData.tableData.index);
                                                
                                                //console.table(rowData.step);
                                                //console.table(rowData.step[0]);
                                                
                                                // if(rowData.step === "Go to Settings") {
                                                // if(rowData.step === currentTestSteps[testRunStepFocus]) {
                                                  // console.log(currentTestStepsType);

                                                if(rowData.tableData.index === testRunStepFocus) {
                                                  return {backgroundColor: 'rgb(238, 255, 51)', textDecoration: 'underline', fontWeight: 'bold'};
                                                } else{
                                                  return {backgroundColor: 'rgb(164, 165, 155)'};
                                                }
                                                
                                                return {};
                                              }
                                            }}

                                            actions={[

                                              (rowData) => {
                                                // console.table(rowData);
                                                // console.table(viewTestRunStepListDataSet);

                                                if(rowData.type === "NA" | rowData.tableData.index !== testRunStepFocus | rowData.step === "Video Stream Test"){
                                                  return {
                                                    icon: PassIcon,
                                                    tooltip: "Pass",
                                                    onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    disabled: true
                                                    }
                                                }else{
                                                  return {
                                                    icon: PassIcon,
                                                    tooltip: "Pass",
                                                    // onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    onClick: (event, rowData) => {
                                                      setTestRunStepFocus(prevCount => prevCount + 1)
                                                      setTestStatusPassCount(prevCount => prevCount + 1)

                                                      setPassStepLog(passStepLog.concat(rowData.step));

                                                      if(testRunStepFocus + 1 === specificTestCaseTable.length){
                                                        
                                                        saveTestRunResult("Pass", testRunStepFocus);

                                                      
                                                        successAlert(`${"Test Case "}${testCaseFocus}${" of "}${testSuiteRunCount}${" Completed"}`);

                                                        
                                                        
                                                      }

                                                    },
                                                    disabled: false
                                                    }

                                                }

                                              },


                                              (rowData) => {
                                                if(rowData.type === "NA" | rowData.tableData.index !== testRunStepFocus | rowData.step === "Video Stream Test"){
                                                  return {
                                                    icon: FailIcon,
                                                    tooltip: "Fail",
                                                    onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    disabled: true
                                                    }
                                                }else{
                                                  return {
                                                    icon: FailIcon,
                                                    tooltip: "Fail",
                                                    // onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    onClick: (event, rowData) => {
                                                      setTestRunStepFocus(prevCount => prevCount + 1)
                                                      setTestStatusFailCount(prevCount => prevCount + 1)

                                                      // console.log(rowData.step);
                                                      setFailStepLog(failStepLog.concat(rowData.step));

                                                      // console.log(testRunStepFocus + 1);
                                                      // console.log(specificTestCaseTable.length);

                                                      if(testRunStepFocus + 1 === specificTestCaseTable.length){
                                                        saveTestRunResult("Fail", testRunStepFocus);
                                                        successAlert(`${"Test Case "}${testCaseFocus}${" of "}${testSuiteRunCount}${" Completed"}`);
                                                        
                                                      }

                                                    },
                                                    disabled: false
                                                    }

                                                }

                                              },


                                              (rowData) => {
                                                if(rowData.type !== "NA" | rowData.tableData.index !== testRunStepFocus | rowData.step === "Video Stream Test"){
                                                  return {
                                                    icon: NextIcon,
                                                    tooltip: "Next",
                                                    onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    disabled: true
                                                    }
                                                }else{
                                                  return {
                                                    icon: NextIcon,
                                                    tooltip: "Next",
                                                    // onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    onClick: (event, rowData) => {
                                                      setTestRunStepFocus(prevCount => prevCount + 1)

                                                      if(testRunStepFocus + 1 === specificTestCaseTable.length){
                                                        
                                                        saveTestRunResult("NA", testRunStepFocus);

                                                        successAlert(`${"Test Case "}${testCaseFocus}${" of "}${testSuiteRunCount}${" Completed"}`);
                                                        
                                                      }

                                                    
                                                      
                                                    },

                                                    disabled: false
                                                    }

                                                }

                                              },

                                              // Temporary - TODO
                                              (rowData) => {
                                                if(rowData.step !== "Video Stream Test" | rowData.tableData.index !== testRunStepFocus){
                                                  return {
                                                    icon: RunIcon,
                                                    tooltip: "Run",
                                                    onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    disabled: true
                                                    }
                                                }else{
                                                  return {
                                                    icon: RunIcon,
                                                    tooltip: "Run",
                                                    // onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    onClick: (event, rowData) => {
                                                      setTestRunStepFocus(prevCount => prevCount + 1)

                                                      // Input Function to Run TestApp AutoRun
                                                      getAutoRun();

                                                      if(testRunStepFocus + 1 === specificTestCaseTable.length){
                                                        
                                                        saveTestRunResult("NA", testRunStepFocus);

                                                        successAlert(`${"Test Case "}${testCaseFocus}${" of "}${testSuiteRunCount}${" Completed"}`);
                                                        
                                                      }

                                                    
                                                      
                                                    },

                                                    disabled: false
                                                    }

                                                }

                                              }
                    
                                              // {
                    
                                              //   icon: NextIcon,
                                              //   tooltip: 'Next',
                                              //   onClick: (event, rowData) => {
                                              //     // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                              //     //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                    
                                              //     // const axios = require('axios');
                    
                                              //     // axios.post(`${RestAPI.testCase[0].removeTestCaseMain}`, {
                                                
                                              //     //   removeTestCaseMain: rowData.id
                                                  
                                              //     // })
                                              //     // .then((response) => {
                                              //     //   // console.log(response);
                                                  
                                              //     //   getTestCaseList();
                                              //     //   getTestCaseListDetail();
                                                  
                                              //     // });


                                              //     setTestRunStepFocus(prevCount => prevCount + 1)
                    
                    
                                              //   }
                    
                                              // }
                    
                                            ]}
                                            
                                          />

                                              <br></br>

                                              <Card>
                                              <CardHeader color="info" icon>

                                              <h4 className={
                                                classes.cardIconTitle
                                              }>
                                                <b>Comment</b>
                                              </h4>
                                              </CardHeader>
                                              <CardBody>
                                              <form onSubmit={e => { e.preventDefault(); }}> 
                                                <CustomInput
                                                  labelText="Example: Unable to select CC option on Step# 5"
                                                  // value={""}
                                                  formControlProps={{
                                                    fullWidth: true
                                                  }}
                                                  inputProps={{
                                                    onBlur: event => {setCommentInput(event.target.value)},
                                                    type: "text"
                                                  }}
                                                  
                                              />

                                              </form>

                                              {/* <GridContainer>
                                                <GridItem 
                                                      xs={12}
                                                      container
                                                      direction="row"
                                                      justify="flex-end"
                                                      alignItems="center">

                                                    <Button color="behance" size="sm" onClick={handleAdd} > 
                                                      <AddIcon className={classes.icons} />
                                                        Add
                                                    </Button>

                                                </GridItem>
                                              </GridContainer> */}

                                              </CardBody>

                                              </Card>


                                        </div>


                                        {/* <GridContainer>
                                          <GridItem 
                                            xs={12}
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center">

                                              <Button color="danger" size="sm" style={{marginTop: "10%"}} onClick={onClickStopPC}> 
                                                <StopIcon className={classes.icons} />
                                                  Stop
                                              </Button>


                                              <Button color="warning" size="sm" style={{marginTop: "10%"}} onClick={onClickRefreshComputerStatusTable}> 
                                                <RefreshIcon className={classes.icons} />
                                                  Refresh Table
                                              </Button>
                                            

                                          </GridItem>

                                        </GridContainer> */}

                        

                                      </div>
                                      ),
                                    },
                                  ]}
                                />


                              </GridItem>

                            </GridContainer>

                            </div>
                            
                            </DialogContent>

                            </div>

                            <DialogActions>

                            <div >

                              <Button color="danger" onClick={handleRunTestCaseHardFail}>
                                <FailIcon className={classes.icons} />
                                Fail Test Case
                              </Button>

                              <Button color="dribbble" onClick={handleViewReport}>
                                <ReportIcon className={classes.icons} />
                                View Report
                              </Button>

                              
                              <Button color="github" onClick={handleRunTestCasePopupClose}>
                                Close
                              </Button>

                            </div>

                            </DialogActions>
                          </Dialog>
                        

                    </GridContainer>


                  </div>
                ),
              }

              // {
              //   tabName: "Test Case List Detail",
              //   tabIcon: DetailIcon,
              //     tabContent: (
              //       <div>    
              //           <div style={{ maxWidth: '100%' }}>
              //                   <MaterialTable
              //                     columns={viewtestCaseListDetailTableColumn}
              //                     data={viewTestCaseListDetailDataSet}
              //                     title="Test Step"
              //                     options={{
              //                       exportButton: false,
              //                       exportAllData: false,
              //                       filtering: true,
              //                       showTitle: false,
              //                       searchAutoFocus: true,
              //                       pageSize: 10,
              //                       // rowStyle: {
              //                       //   backgroundColor: '#EEE',
              //                       // }

              //                     }}

              //                   />
              //             </div>


              //         </div>
              //   )

                
              // }
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
