/*eslint-disable*/
import React, { useMemo } from "react";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import NavPills from "components/NavPills/NavPills.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardText from "components/Card/CardText.js";

// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import Chip from '@material-ui/core/Chip';
// import FormLabel from "@material-ui/core/FormLabel";
// import CircularProgress from '@material-ui/core/CircularProgress';
// import InputAdornment from "@material-ui/core/InputAdornment";
// import ModelIcon from "@material-ui/icons/Devices";
// import EmailIcon from "@material-ui/icons/Email";
// import WebsiteIcon from "@material-ui/icons/Language";
// import BrandIcon from "@material-ui/icons/LocalOffer";
// import SendIcon from "@material-ui/icons/Send";
// import FileIcon from "@material-ui/icons/List";
// import DescriptionIcon from "@material-ui/icons/Description";

// @material-ui/icons
import AssignmentIcon from "@material-ui/icons/Assignment";
// import AddressIcon from "@material-ui/icons/Language";
// import VersionIcon from "@material-ui/icons/Build";
// import PersonIcon from "@material-ui/icons/Person"; // For User Agent
// import PlayIcon from "@material-ui/icons/PlayArrow";
// import CheckIcon from "@material-ui/icons/CheckCircle";
// import ErrorIcon from "@material-ui/icons/Error";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import TvIcon from "@material-ui/icons/Tv";
// import UploadIcon from "@material-ui/icons/CloudUpload";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import TopicIcon from "@material-ui/icons/ListAlt";
import RefreshIcon from "@material-ui/icons/Refresh";

// 3rd Party
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import MaterialTable from "@material-table/core";
//import MaterialTable from "material-table/core";


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

import jsonTableData from "../../JSON/PCAP_Database.json";
import jsonPcapFileMapping from "../../JSON/pcapFileMapping.json";

// REST API
import RestAPI from "../../Service/RestAPI.json"







export default function PCAPdatabase() {
  const [urlDownload, setUrlDownload] = React.useState(jsonPcapFileMapping);

  const [tableData, setTableData] = React.useState(['']);
  const [defaultTableData, setDefaultTableData] = React.useState(['']);

  const [orList, setOrList] = React.useState("");
  const [andList, setAndList] = React.useState("");
  const [excludeList, setExcludeList] = React.useState("");



  const getDefaultTableData = async () => {


    const axios = require('axios');
  
    try {
      const resp = await axios.get(`${RestAPI.pcapDatabase[0].defaultTable}`);


      //console.table(resp);
      setTableData(resp.data.searchResult);
      setDefaultTableData(resp.data.searchResult);

      //console.table(resp.data.searchResult)


    } catch (err) {
        // Handle Error Here
        console.error(err);

    }


  }



  React.useEffect(() => { 

    getDefaultTableData();

  }, []);





  const onClickRefreshTable = () => {

    // console.log(orList);
    // console.log(andList);
    // console.log(excludeList);


    if(orList != ""){

      getFilterTable();

    }


  }

  const onClickDefaultTable = () => {

    setTableData(defaultTableData);


  }



  const getFilterTable = async () => {

    const axios = require('axios');

    let restGETurl1 = `${"userOrList="}${orList}${"&userAndList="}${andList}${"&userExcludeList="}${excludeList}`;
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
  
  try {
    const resp = await axios.get(`${RestAPI.pcapDatabase[0].searchTable}${restGETurl1}`);

    // console.table(resp.data);
    // console.table(resp.data.searchResult);

    //setFilterTableData(resp.data.searchResult);
    //setFilterTableData(jsonTableData);
    setTableData(resp.data.searchResult);

  } catch (err) {
      // Handle Error Here
      console.error(err);

  }


};




  


  const viewIndividualSentenceTableColumn = [
    {
      title: "Filename",
      field: "filename",
      cellStyle: {
        width: 600,
        minWidth: 400
      },
    },
    {
      title: "File.Size",
      field: "file.size",
    },
    {
      title: "BSID",
      field: "bsid",
    },
    {
      title: "Segment.Duration",
      field: "segment.duration",
    },
    {
      title: "Service.ID",
      field: "service.id",
    },
    {
      title: "Virtual.Channel",
      field: "virtual.channel",
    },
    {
      title: "Service.Name",
      field: "service.name",
    },
    {
      title: "Service.Protocol",
      field: "service.protocol",
    },
    {
      title: "Service.Category",
      field: "service.category",
    },
    {
      title: "Video.ES",
      field: "video.es",
    },
    {
      title: "Audio.ES",
      field: "audio.es",
    },
    {
      title: "Caption",
      field: "caption",
    },
    {
      title: "App",
      field: "app",
    },
    {
      title: "DRM",
      field: "drm",
    },
    {
      title: "Rating",
      field: "rating",
    },
    {
      title: "ESG",
      field: "esg",
    },
    {
      title: "Video.IP.Port",
      field: "video.ip.port",
    },
    {
      title: "tsi.Video",
      field: "tsi.video",
    },
    {
      title: "Video.Codec",
      field: "video.codec",
    },
    {
      title: "Video.Resolution",
      field: "video.resolution",
    },
    {
      title: "P.or.I",
      field: "p.or.i",
    },
    {
      title: "Picture.format",
      field: "picture.format",
    },
    {
      title: "Video.Frame.Rate",
      field: "video.frame.rate",
    },
    {
      title: "Video.Aspect.Ratio",
      field: "video.aspect.ratio",
    },
    {
      title: "Video.Bitrate",
      field: "video.bitrate",
    },
    {
      title: "Video.ES.status",
      field: "video.es.status",
    },
    {
      title: "Audio.IP.Port",
      field: "audio.ip.port",
    },
    {
      title: "tsi.Audio",
      field: "tsi.audio",
    },
    {
      title: "Audio.Codec.Layer",
      field: "audio.codec.layer",
    },
    {
      title: "Audio.Bitrate",
      field: "audio.bitrate",
    },
    {
      title: "Audio.Sampling.Freq",
      field: "audio.sampling.freq",
    },
    {
      title: "Audio.Mode",
      field: "audio.mode",
    },
    {
      title: "Audio.Lang",
      field: "audio.lang",
    },
    {
      title: "Audio.ES.Status",
      field: "audio.es.status",
    },
    {
      title: "Caption.IP.Port",
      field: "caption.ip.port",
    },
    {
      title: "Tsi.Caption", //
      field: "tsi.caption",
    },
    {
      title: "Caption.Type",
      field: "caption.type",
    },
    {
      title: "Caption.Lang",
      field: "caption.lang",
    },
    {
      title: "Caption.Status", //
      field: "caption.status",
    },
    {
      title: "App.IP.port",
      field: "app.ip.port",
    },
    {
      title: "Tsi.App", //
      field: "tsi.app",
    },
    {
      title: "HELD",
      field: "held",
    },
    {
      title: "Domain",
      field: "domain",
    },
    {
      title: "Type",
      field: "type",
    },
    {
      title: "License",
      field: "license",
    },
    {
      title: "Video.KID",
      field: "video.kid",
    },
    {
      title: "Audio.KID",
      field: "audio.kid",
    },
    {
      title: "DRM.Status",
      field: "drmstatus",
    },
    {
      title: "Summary",
      field: "summary",
    },
    {
      title: "Notes",
      field: "notes",
    }
  ];


  const viewIndividualSentenceDataTableDataSet = tableData.map((data) => {

    // let convertData = data["Virtual.Channel"];
    // convertData = toString(convertData);

    return {
      // id: data.key,
      filename: data["Filename"],
      "file.size": data["File.Size"],
      bsid: data["BSID"],
      "segment.duration": data["Segment.Duration"],
      "service.id": data["Service.ID"],
      "virtual.channel": data["Virtual.Channel"],
      // "virtual.channel": convertData,
      "service.name": data["Service.Name"],
      "service.protocol": data["Service.Protocol"],
      "service.category": data["Service.Category"],
      "video.es": data["Video.ES"],
      "audio.es": data["Audio.ES"],
      "caption": data["Caption"],
      "app": data["App"],
      "drm": data["DRM"],
      "rating": data["Rating"],
      "esg": data["ESG"],
      "video.ip.port": data["Video.IP.Port"],
      "tsi.video": data["tsi.Video"],
      "video.codec": data["Video.Codec"],
      "video.resolution": data["Video.Resolution"],
      "p.or.i": data["P.or.I"],
      "picture.format": data["Picture.format"],
      "video.frame.rate": data["Video.Frame.Rate"],
      "video.aspect.ratio": data["Video.Aspect.Ratio"],
      "video.bitrate": data["Video.Bitrate"],
      "video.es.status": data["Video.ES.status"],
      "audio.ip.port": data["Audio.IP.Port"],
      "tsi.audio": data["tsi.Audio"],
      "audio.codec.layer": data["Audio.Codec.Layer"],
      "audio.bitrate": data["Audio.Bitrate"],
      "audio.sampling.freq": data["Audio.Sampling.Freq"],
      "audio.mode": data["Audio.Mode"],
      "audio.lang": data["Audio.Lang"],
      "audio.es.status": data["Audio.ES.Status"],
      "caption.ip.port": data["Caption.IP.Port"],
      "tsi.caption": data["Tsi.Caption"],
      "caption.type": data["Caption.Type"],
      "caption.lang": data["Caption.Lang"],
      "caption.status": data["Caption.Status"],
      "app.ip.port": data["App.IP.port"],
      "tsi.app" : data["Tsi.App"],
      "held": data["HELD"],
      "domain": data["Domain"],
      "type": data["Type"],
      "license": data["License"],
      "video.kid": data["Video.KID"],
      "audio.kid": data["Audio.KID"],
      "drmstatus": data["DRMstatus"],
      "summary": data["Summary"],
      "notes": data["Notes"]
    };
  });



  const handleDownloadFile = async (filename) => {
  // const handleDownloadFile = (filename) => {

    const axios = require('axios');

    let filterBySelection = urlDownload.filter(
      (data) => data.Raw_Filename == filename
    );



  
  try {
    const resp = await axios.get(`${RestAPI.pcapDatabase[0].downloadFile}${filterBySelection[0].Filename}`);

   //console.log((resp.data.url));
   //console.log(encodeURIComponent(resp.data.url));


   // Create invisible Download link
   const downloadURI = (uri, name) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    }
    downloadURI(resp.data.url, filterBySelection[0].Filename);


  } catch (err) {
      // Handle Error Here
      console.error(err);

  }


};
  
  






  const classes = useStyles();

  return (
    <div>

      {/* Topic Search Filter */}
      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>PCAP Search</b> </Button>
        </GridItem>

      </GridContainer>


      <GridContainer>
          <GridItem xs={12}
            sm={12}
            md={12}>

            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <TopicIcon />
                  
                  
                </CardIcon>
                <h4 className={
                  classes.cardIconTitle
                }>
                  <b>Topic Search</b>
                </h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={e => { e.preventDefault(); }}> 
                  <CustomInput
                    labelText="OR: Any of these words"
                    id="text_pcapSearch1"
                    // value={topicSearchItems}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onBlur: event => {setOrList(event.target.value)},
                      type: "text"
                    }}
                    
                />
                
                </form>


                <form onSubmit={e => { e.preventDefault(); }}> 
                  <CustomInput
                    labelText="AND: Must include these words"
                    id="text_pcapSearch2"
                    // value={topicSearchItems}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onBlur: event => {setAndList(event.target.value)},
                      type: "text"
                    }}
                    
                />
                
                </form>



                <form onSubmit={e => { e.preventDefault(); }}> 
                  <CustomInput
                    labelText="EXCLUDE: None of these words"
                    id="text_pcapSearch3"
                    // value={topicSearchItems}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onBlur: event => {setExcludeList(event.target.value)},
                      type: "text"
                    }}
                    
                />
                
                </form>
                
              </CardBody>

            </Card>


            

          </GridItem>
          

          </GridContainer>

          <GridContainer >

            <GridItem 
                xs={12}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center">

              <Button color="warning" onClick={onClickDefaultTable}>
                <RefreshIcon className={classes.icons} />
                  Display Default Table
              </Button>

              <Button color="pinterest" onClick={onClickRefreshTable}>
                <RefreshIcon className={classes.icons} />
                  Display Filter Table
              </Button>
              

            </GridItem>

            </GridContainer>

            <br/>


      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
            tabs={[
              {
                tabName: "Stream Files",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                    <div style={{ maxWidth: "100%" }}>
                      <MaterialTable
                        columns={viewIndividualSentenceTableColumn}
                        data={viewIndividualSentenceDataTableDataSet}
                        title="Stream Manager"
                        options={{
                          exportButton: true,
                          exportAllData: true,
                          filtering: true,
                          showTitle: false,
                          pageSize: 5,
                          search: true
                        }}
                        actions={[
                          {
                            icon: DownloadIcon,
                            tooltip: "Download File",
                            onClick: (event, rowData) => {
                              //handleDownloadFile();
                              //console.log(rowData.filename);
                              handleDownloadFile(rowData.filename, () => {});
                            },
                          },
                        ]}
                      />
                    </div>

                    <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center">

                        <CSVLink data={viewIndividualSentenceDataTableDataSet} filename={"PCAP Database.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>
                        

                      </GridItem>

                  </GridContainer>

                  </div>
                ),
                
              },



            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
