import Home from "views/Home/Home.js";
import PcapDatabase from "views/PCAPdatabase/PCAPdatabase.js";
import FileUpload from "views/FileUpload/FileUpload.js";
import RemoteControl from "views/RemoteControl/RemoteControl.js";
import TestCase from "views/TestCase/TestCase.js";
import TestSuite from "views/TestSuite/TestSuite.js";
import Report from "views/Report/Report.js";

// import RestApiDownload from "views/RESTAPIdownload/RESTAPIdownload.js";
//import LoginPage from "views/Pages/LoginPage.js";

// @material-ui/icons
import HomeIcon from "@material-ui/icons/Home";
import PcapDatabaseIcon from "@material-ui/icons/Folder";
import FileUploadIcon from "@material-ui/icons/CloudUploadSharp";
import RemoteControlIcon from "@material-ui/icons/SettingsRemote";
import TestCaseIcon from "@material-ui/icons/FormatListBulleted";
import TestSuiteIcon from "@material-ui/icons/ListAlt";
import ReportIcon from "@material-ui/icons/Assessment";
// import RestApiDownloadIcon from "@material-ui/icons/CloudDownload";
//import LoginIcon from "@material-ui/icons/AccountCircle";

var dashRoutes = [
  // {
  //   path: "/login-page",
  //   name: "Login Page",
  //   rtlName: "Login Page",
  //   icon: LoginIcon,
  //   rtlMini: "Login Page",
  //   component: LoginPage,
  //   layout: "/auth",
  // },

  {
    path: "/home",
    name: "Home",
    rtlName: "Home",
    icon: HomeIcon,
    component: Home,
    layout: "/admin",
  },

  {
    path: "/test-case",
    name: "Test Case",
    rtlName: "Test Case",
    icon: TestCaseIcon,
    component: TestCase,
    layout: "/admin",
  },

  {
    path: "/test-suite",
    name: "Test Suite",
    rtlName: "est Suite",
    icon: TestSuiteIcon,
    component: TestSuite,
    layout: "/admin",
  },

  {
    path: "/remote-control",
    name: "Remote Control",
    rtlName: "Remote Control",
    icon: RemoteControlIcon,
    component: RemoteControl,
    layout: "/admin",
  },


  {
    path: "/pcap-database",
    name: "PCAP Database",
    rtlName: "PCAP Database",
    icon: PcapDatabaseIcon,
    component: PcapDatabase,
    layout: "/admin",
  },
  {
    path: "/upload",
    name: "File Upload",
    rtlName: "File Upload",
    icon: FileUploadIcon,
    component: FileUpload,
    layout: "/admin",
  },

  {
    path: "/report",
    name: "Report",
    rtlName: "Report",
    icon: ReportIcon,
    component: Report,
    layout: "/admin",
  },



  // {
  //   path: "/restapi-Download",
  //   name: "RestAPI Download",
  //   rtlName: "RestAPI Download",
  //   icon: RestApiDownloadIcon,
  //   component: RestApiDownload,
  //   layout: "/admin",
  // },
];

export default dashRoutes;
