/*eslint-disable*/
import React from "react";

// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Tooltip from '@material-ui/core/Tooltip';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import NavPills from "components/NavPills/NavPills.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
// import CardText from "components/Card/CardText.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Checkbox from "@material-ui/core/Checkbox";
// import FormLabel from "@material-ui/core/FormLabel";
// import CircularProgress from '@material-ui/core/CircularProgress';
// import InputAdornment from "@material-ui/core/InputAdornment";
// import ModelIcon from "@material-ui/icons/Devices";
// import EmailIcon from "@material-ui/icons/Email";
// import WebsiteIcon from "@material-ui/icons/Language";
// import BrandIcon from "@material-ui/icons/LocalOffer";
// import SendIcon from "@material-ui/icons/Send";
// import FileIcon from "@material-ui/icons/List";
// import DescriptionIcon from "@material-ui/icons/Description";

// @material-ui/icons
import AssignmentIcon from "@material-ui/icons/Assignment";
import FilenameIcon from "@material-ui/icons/Description";
import UploadIcon from "@material-ui/icons/CloudUpload";
// import AddressIcon from "@material-ui/icons/Language";
// import VersionIcon from "@material-ui/icons/Build";
// import PersonIcon from "@material-ui/icons/Person"; // For User Agent
// import PlayIcon from "@material-ui/icons/PlayArrow";
import CheckIcon from "@material-ui/icons/CheckCircle";
// import ErrorIcon from "@material-ui/icons/Error";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import TvIcon from "@material-ui/icons/Tv";
// import UploadIcon from "@material-ui/icons/CloudUpload";

// import MaterialTable from "material-table";
import CheckedIcon from "@material-ui/icons/CheckBox";
import QuestionIcon from "@material-ui/icons/NotListedLocation";
import ComputerIcon from "@material-ui/icons/ComputerSharp";
import PcapIcon from "@material-ui/icons/VideoLibrarySharp";
import TvIcon from "@material-ui/icons/Tv";
import ChannelIcon from "@material-ui/icons/FormatListNumbered";

import StartIcon from "@material-ui/icons/PlayCircleFilledSharp";
import StopIcon from "@material-ui/icons/StopSharp";
import RefreshIcon from "@material-ui/icons/RefreshSharp";
import FrequencyIcon from "@material-ui/icons/GraphicEq";

import DownloadIcon from "@material-ui/icons/GetApp";
import TopicIcon from "@material-ui/icons/ListAlt";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import LockIcon from "@material-ui/icons/Lock";
import FileIcon from "@material-ui/icons/Description";

// import jsonTableData from "../../JSON/pcapDataSetMapping.json";
// import jsonPcapFileMapping from "../../JSON/pcapFileMapping.json";

// 3rd Party
import { FileUploader } from "react-drag-drop-files";
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import MaterialTable from "@material-table/core";
//import S3 from 'react-aws-s3';
import AWS from 'aws-sdk';

// REST API
import RestAPI from "../../Service/RestAPI.json"


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

export default function RemoteControl() {


  const fileTypes = ["PCAP"];
  const [file, setFile] = React.useState(null);

  const [topLock, setTopLock] = React.useState(null);
  const [bottomLock, setBottomLock] = React.useState(null);

  const [analyzedPcapList, setAnalyzedPcapList] = React.useState(['']);


  const [computerStatusList, setComputerStatusList] = React.useState(['']);

  const [computerSelection1, setComputerSelection1] = React.useState(['']);
  const [computerSelection2, setComputerSelection2] = React.useState(['']);

  const [installPcapList, setInstallPcapList] = React.useState(['']);

  const [freqencyInputSelection, setFreqencyInputSelection] = React.useState('473');

  const [uniqueComputerNameItems, setUniqueComputerNameItems] = React.useState(['']);
  const [uniqueComputerNameSelection, setUniqueComputerNameSelection] = React.useState(['']);

  const [uniqueTvNameItems, setUniqueTvNameItems] = React.useState(['']);
  const [uniqueTvNameSelection, setUniqueTvNameSelection] = React.useState(['']);

  const [pcapItems, setPcapItems] = React.useState(['']);
  const [pcapItemsSelection, setPcapItemsSelection] = React.useState(['']);

  const [channelItems, setChannelItems] = React.useState(['']);
  const [channelItemsSelection, setChannelItemsSelection] = React.useState(['']);

  const [channelListMain, setChannelListMain] = React.useState(['']);


  // Modal Pop-up

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



    // for search result to appear constant after selection
    const [inputValue, setInputValue] = React.useState("");


  const getUploadTable = async () => {

    const axios = require('axios');
  
  try {
    const resp = await axios.get(`${RestAPI.fileUpload[0].uploadTable}`);

    //let pcapFileName = resp.data.analyzedPcapList_mod

    //setPcapItems(pcapFileName.map(data => data["Filename"]));

    setPcapItems(resp.data.analyzedPcapList_mod);


  } catch (err) {
      // Handle Error Here
      console.error(err);

  }


};


const getComputerStatusTable = async () => {

  const axios = require('axios');

try {
  const resp = await axios.get(`${RestAPI.remoteControl[0].computerStatus}`);

  setComputerStatusList(resp.data.computerStatus);


  let uniqueTvNames = resp.data.computerStatus
  uniqueTvNames = uniqueTvNames.map((data) => data.TvAppName);

  setUniqueTvNameItems(uniqueTvNames);

} catch (err) {
    // Handle Error Here
    console.error(err);

}


};



const getChannelListTable = async () => {

  const axios = require('axios');

  try {
    const resp = await axios.get(`${RestAPI.remoteControl[0].channelList}`);

    //console.table(resp.data.channelList);
    setChannelListMain(resp.data.channelList);

  } catch (err) {
      // Handle Error Here
      console.error(err);

  }


};


const handleTvName = event => {
  setUniqueTvNameSelection(event.target.value);
  // setPcapItems(event.target.value);

};




const handlePcap = () => {

  let filterChannel = channelListMain.filter(x => x.Filename == pcapItemsSelection);

  filterChannel = filterChannel.filter(x => typeof x['Virtual.Channel'] != 'undefined');
  filterChannel = [...new Set(filterChannel.map(x => x['Virtual.Channel']))];
  filterChannel = filterChannel.map(x => x.replace("-", "."));
  setChannelItems(filterChannel);

};


const handleChannel = event => {
  setChannelItemsSelection(event.target.value);

};




React.useEffect(() => {
  getUploadTable();
  getComputerStatusTable();
  getChannelListTable();
}, [])





const viewComputerInfoTableColumn = [
  {
    title: "TV App Name",
    field: "tvAppName"
  },
  {
    title: "TV IP Address",
    field: "tvAddress"
  },
  {
    title: "Android OS Version",
    field: "osVersion"
  },
  {
    title: "PC Name",
    field: "pcName",
  },
  {
    title: "PC IP Address",
    field: "pcIPaddress"
  },
  {
    title: "Last PC Ping Date",
    field: "pcPingDate"
  }
];


const viewComputerInfoDataTableDataSet = computerStatusList.map((data) => {

  return {
    // id: data.key,
    tvAppName: data["TvAppName"],
    tvAddress: data["TvIpAddress"],
    osVersion: data["OS"],
    pcName: data["PcName"],
    pcIPaddress: data["PcIpAddress"],
    pcPingDate: data["LastPcPing"]
  };
});



const viewComputerStatusTableColumn = [
  {
    title: "TV App Name",
    field: "tvAppName"
  },
  {
    title: "PC Name",
    field: "pcName",
  },
  {
    title: "Running PCAP",
    field: "runningPCAP"
  },
  {
    title: "Frequency",
    field: "frequency",
  },
  {
    title: "Channel Selection",
    field: "channelSelection",
  },
  {
    title: "Computer Status",
    field: "computerStatus"
  },
  {
    title: "Action Request",
    field: "actionRequest"
  }
];


const viewComputerStatusDataTableDataSet = computerStatusList.map((data) => {

  return {
    // id: data.key,
    tvAppName: data["TvAppName"],
    pcName: data["PcName"],
    runningPCAP: data["RunningPCAP"],
    frequency: data["Frequency"],
    frequency: data["Frequency"],
    channelSelection: data["ChannelSelection"],
    computerStatus: data["ComputerStatus"],
    actionRequest: data["ActionRequest"]
  };
});






const pcapTableColumn = [
  {
    title: "PCAP File",
    field: "pcapFile"
  }
];


//console.log(pcapItems);

const pcapDataTableDataSet = pcapItems.map((data) => {

  return {
    // id: data.key,
    pcapFile: data
  };
});



const pcapTableColumnSelection = [
  {
    title: "PCAP File",
    field: "pcapFile"
  }
];


//console.log(pcapItems);

const pcapDataTableDataSetSelection = pcapItemsSelection.map((data) => {

  return {
    // id: data.key,
    pcapFile: data
  };
});




const onClickStopPC = () => {


  // TODO : Update the below Stop function

  // const axios = require('axios');

  // axios.post(`${RestAPI.remoteControl[0].computerStop}`, {
  
  //   Computer_Name: computerSelection1

  // })
  // .then((response) => {
  //   // console.log(response);

  //   getComputerStatusTable()

  // });


}



const onClickRefreshComputerStatusTable = () => {

  getComputerStatusTable()

}



const onClickStartPC = () => {


  const axios = require('axios');

  axios.post(`${RestAPI.remoteControl[0].tvStart}`, {
  
    tvName: uniqueTvNameSelection,
    pcap: pcapItemsSelection,
    frequency: freqencyInputSelection,
    channelSelection: channelItemsSelection

  })
  .then((response) => {
    // console.log(response);

    getComputerStatusTable()


  });


  // console.log(uniqueComputerNameSelection);
  // console.log(pcapItemsSelection);

}



React.useEffect(() => {

  handlePcap();

}, [pcapItemsSelection])



  const classes = useStyles();


  return (
    <div>

    <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>REMOTE ATSC3XPRESS CONTROL</b> </Button>
      </GridItem>

    </GridContainer>



      <GridContainer>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <TvIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>TV</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="computer-select"
                  className={
                    classes.selectLabel
                }>
                  Select TV
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={uniqueTvNameSelection}
                  onChange={handleTvName}
                  inputProps={
                    {
                      name: "computerSelect",
                      id: "computer-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  uniqueTvNameItems.map((UniqueTvNameItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={UniqueTvNameItems}>
                      {UniqueTvNameItems} </MenuItem>
                  ))
                } 
                </Select>
                <br></br>
                <br></br>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PcapIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>PCAP</b>
              </h4>
            </CardHeader>
            <CardBody>

            <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center">


              {/* <CustomInput
                labelText="Select PCAP File"
                id="pcap_file"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text"
                }}
              /> */}


              <Chip 
                color= "default" 
                icon= {<FileIcon />} 
                label= {pcapItemsSelection}
                size="medium"
                variant="outlined"
              />

             {/* { console.log(pcapItemsSelection)} */}

             <br></br>
             <br></br>
             <br></br>
             <br></br>

             <GridItem
              xs={12}
              container
              direction="row"
              justify="center"
              alignItems="center">

             <Button onClick={() => setOpen(true)} color="tumblr" size="sm">
                Select PCAP
              </Button>

              
             </GridItem>

              </GridItem>

            </GridContainer>

              {/* <button onClick={() => setOpen(true)}>Open select dialog</button> */}


              <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={open}
                onClose={() => setOpen(false)}
                //TransitionComponent={Transition}
                aria-labelledby="pcap-selection-dialog"
              >
                <DialogTitle id="pcap-selection-dialog"><strong> PCAP Selection </strong></DialogTitle>
                <DialogContent>
                  {/* <DialogContentText> */}
                    {/* {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )} */}
                  {/* </DialogContentText> */}

                  {/* Pop-up Content */}
                  <GridContainer>
   
                    <GridItem xs={12} sm={12} md={6} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="warning"
                          
                          tabs={[
                            {
                              tabName: "PCAP List",
                              tabIcon: PcapIcon,
                                tabContent: (
                                  <div>    
                                      <div style={{ maxWidth: '100%' }}>
                                              <MaterialTable
                                                columns={pcapTableColumn}
                                                data={pcapDataTableDataSet}
                                                title="Model List"
                                                options={{
                                                  exportButton: false,
                                                  exportAllData: false,
                                                  filtering: true,
                                                  showTitle: false,
                                                  searchAutoFocus: true,
                                                  pageSize: 5
                                                }}
                                                actions={[
                                                  {
                                                    icon: AddIcon,
                                                    tooltip: 'Add PCAP',
                                                    onClick: (event, rowData) => {
                                                      // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                      //onClickAddModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});

                                                      // console.table(rowData);
                                                      // console.log(rowData);
                                                      
                                                      setPcapItemsSelection([rowData.pcapFile]);
                                                      //console.log(rowData.pcapFile);
                                                      

                                                    }

                                                  }
                                                ]}
                                                components={{
                                                  Action: props => (

                                                    <Tooltip
                                                      id="tooltip-bottom"
                                                      title="Add Model"
                                                      placement="top"
                                                      classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <Button color="success" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                          <AddIcon />
                                                        </Button>
                                                    </Tooltip>
                                                    
                                                  )
                                                }}
                
                                              />
                                        </div>


                                    </div>
                              )

                              
                            }
                          ]}
                        /> 

                      </GridItem>

            {/* Saved Model List */}

                      <GridItem xs={12} sm={12} md={6} >

                        <CustomTabs
                            //title="Tasks:"
                            centered={true}
                            headerColor="info"
                            
                            tabs={[
                              {
                                tabName: "PCAP Selection",
                                tabIcon: PcapIcon,
                                  tabContent: (
                                    <div>    
                                        <div style={{ maxWidth: '100%' }}>
                                                <MaterialTable
                                                  columns={pcapTableColumnSelection}
                                                  data={pcapDataTableDataSetSelection}
                                                  title="Model List"
                                                  options={{
                                                    exportButton: false,
                                                    exportAllData: false,
                                                    filtering: true,
                                                    showTitle: false,
                                                    searchAutoFocus: true,
                                                    pageSize: 5
                                                  }}
                                                  // actions={[
                                                  //   {
                                                  //     icon: RemoveIcon,
                                                  //     tooltip: 'Remove Model',
                                                  //     onClick: (event, rowData) => {
                                                  //       // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                  //       //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                                        

                                                  //     }

                                                  //   }
                                                  // ]}
                                                  components={{
                                                    Action: props => (

                                                      <Tooltip
                                                        id="tooltip-bottom"
                                                        title="Remove Model"
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                      >
                                                          <Button color="danger" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                            <RemoveIcon />
                                                          </Button>
                                                      </Tooltip>

                                                      
                                                    )
                                                  }}

                                                />
                                          </div>


                                      </div>
                                )

                                
                              }
                            ]}
                          /> 

                      </GridItem>
                      
                    </GridContainer>
                
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)} color="primary">
                  {/* <Button onClick={handlePopUpClose()} color="primary"> */}
                    Close
                  </Button>
                </DialogActions>
              </Dialog>




              {/* <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>


                <InputLabel htmlFor="file-select"
                  className={
                    classes.selectLabel
                }>
                  Select PCAP
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={pcapItemsSelection}
                  onChange={handlePcap}
                  inputProps={
                    {
                      name: "fileSelect",
                      id: "file-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  pcapItems.map((PcapItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={PcapItems}>
                      {PcapItems} </MenuItem>
                  ))
                } 
                </Select>

              </FormControl> */}



            </CardBody>
          </Card>

        </GridItem>



        <GridItem xs={12}
            sm={12}
            md={3}>

            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <FrequencyIcon />
                  
                  
                </CardIcon>
                <h4 className={
                  classes.cardIconTitle
                }>
                  <b>Freqency</b>
                </h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={e => { e.preventDefault(); }}> 
                  <CustomInput
                    labelText="Default: 473"
                    id="input_frequency"
                    value={freqencyInputSelection}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onBlur: event => {setFreqencyInputSelection(event.target.value)},
                      type: "text"
                    }}
                    
                />
                
                </form>

                <br></br>
                <br></br>
                
              </CardBody>

            </Card>


            

          </GridItem>


          <GridItem xs={12}
            sm={12}
            md={3}>

            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <ChannelIcon />
                  
                  
                </CardIcon>
                <h4 className={
                  classes.cardIconTitle
                }>
                  <b>Channel</b>
                </h4>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth
                  className={
                    classes.selectFormControl
                }>


                  <InputLabel htmlFor="channel-select"
                    className={
                      classes.selectLabel
                  }>
                    Select Channel
                  </InputLabel>

                  <Select style={
                      {minWidth: "100%"}
                    }
                    MenuProps={
                      {className: classes.selectMenu}
                    }
                    classes={
                      {select: classes.select}
                    }
                    //multiple
                    autoWidth={false}
                    value={channelItemsSelection}
                    onChange={handleChannel}
                    inputProps={
                      {
                        name: "channelSelect",
                        id: "channel-select"
                      }
                  }>
                    <MenuItem disabled
                      classes={
                        {root: classes.selectMenuItem}
                    }>
                      <b>Single Selection</b>
                    </MenuItem>

                    {
                    channelItems.map((ChannelItems, index) => (
                      <MenuItem key={index}
                        classes={
                          {
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }
                        }
                        value={ChannelItems}>
                        {ChannelItems} </MenuItem>
                    ))
                  } 
                  </Select>

                  <br></br>
                 <br></br>

                </FormControl>
              </CardBody>
            </Card>


            

          </GridItem>


      </GridContainer>


      <GridContainer>
        <GridItem 
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="center">

            <Button color="success" size="sm" onClick={onClickStartPC} > 
              <StartIcon className={classes.icons} />
                Start PCAP Playback
            </Button>
          

        </GridItem>

      </GridContainer>



      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Remote Device Info</b> </Button>
        </GridItem>

      </GridContainer>

   
      <br/>

      <GridContainer>

        <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="info"
              tabs={[
                {
                  tabName: "Device Info",
                  tabIcon: TvIcon,
                  tabContent: (
                    <div>
                    <div style={{ maxWidth: "100%" }}>
                      <MaterialTable
                        columns={viewComputerInfoTableColumn}
                        data={viewComputerInfoDataTableDataSet}
                        title="Stream Manager"
                        options={{
                          exportButton: true,
                          exportAllData: true,
                          filtering: true,
                          showTitle: false,
                          pageSize: 5,
                          search: true,
                          selection: false
                        }}

                      />
                    </div>


                    <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center">


                          <Button color="warning" size="sm" style={{marginTop: "10%"}} onClick={onClickRefreshComputerStatusTable}> 
                            <RefreshIcon className={classes.icons} />
                              Refresh Table
                          </Button>
                        

                      </GridItem>

                    </GridContainer>



                  </div>
                  ),
                },
              ]}
            />
          </GridItem>

        </GridContainer>


        <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Remote Device State</b> </Button>
          </GridItem>

        </GridContainer>


      <GridContainer>

      <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
            tabs={[
              {
                tabName: "Device State",
                tabIcon: TvIcon,
                tabContent: (
                  <div>
                  <div style={{ maxWidth: "100%" }}>
                    <MaterialTable
                      columns={viewComputerStatusTableColumn}
                      data={viewComputerStatusDataTableDataSet}
                      title="Stream Manager"
                      options={{
                        exportButton: true,
                        exportAllData: true,
                        filtering: true,
                        showTitle: false,
                        pageSize: 5,
                        search: true,
                        selection: true
                      }}
                      onSelectionChange={(rows) => 
                        {
                          //console.log(rows.map(data => data["computerName"]));
                          setComputerSelection1(rows.map(data => data["computerName"]))
                        }
                      }

                      
                    />
                  </div>


                  <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center">

                        <Button color="danger" size="sm" style={{marginTop: "10%"}} onClick={onClickStopPC}> 
                          <StopIcon className={classes.icons} />
                            Stop
                        </Button>


                        <Button color="warning" size="sm" style={{marginTop: "10%"}} onClick={onClickRefreshComputerStatusTable}> 
                          <RefreshIcon className={classes.icons} />
                            Refresh Table
                        </Button>
                      

                    </GridItem>

                  </GridContainer>

  

                </div>
                ),
              },
            ]}
          />
        </GridItem>

      </GridContainer>





    </div>
  );
}
