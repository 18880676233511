/*eslint-disable*/
import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import Amplify, { Auth, Hub } from "aws-amplify"; // Custom signout button
import {Authenticator, View } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import { createBrowserHistory } from "history";
import { Router , BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import Background from "./assets/img/login.jpeg";
// @material-ui/icons
import SignOutIcon from "@material-ui/icons/ExitToApp";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import "assets/css/amplify-custom.css"
import { urlSafeDecode } from "@aws-amplify/core";

// Global State
import { useAtom } from 'jotai'
import { user } from "../src/store/store.js";


// import {Amplify} from 'aws-amplify';
import awsExports from "./aws-exports";
// import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
// import { Auth } from "aws-amplify";


const useStyles = makeStyles(styles);

const hist = createBrowserHistory();


Auth.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
  }
})



const App = () => {

  const [userInfo, setUserInfo] = useAtom(user);

  const classes = useStyles();

  return (
    <div
        // style={{
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     height: "100vh",
        //     backgroundImage: `url(${Background})`,
        //     backgroundColor: "transparent",
        // }}
    >

      <Authenticator style={{backgroundColor: "black"}}>

        {({ signOut, user }) => (
            <div style={{}}>
                {/* {console.log(user)} */}
                {/* {console.log(user.attributes.email)} */}
                {setUserInfo(user.attributes.email)}
                {/* {console.log(onAuthUIStateChange)} */}
                
                <GridContainer>
                  <GridItem
                  xs={12}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  >
                      <Button size="sm" color="github" onClick={signOut}>
                          <SignOutIcon className={classes.icons} />
                          Sign Out
                      </Button>
                  </GridItem>
               </GridContainer>

                <Router  history={hist}>
                    <Switch>
                        <Route path="/admin" component={AdminLayout} />
                        <Route path="/auth" component={AuthLayout} />
                        <Route path="/rtl" component={RtlLayout} />
                        <Redirect from="/" to="/admin/Home" />
                    </Switch>
                </Router >


          </div>
        )}
      </Authenticator>



    </div>
  );
}

export default App;





