/*eslint-disable*/
import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
// import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import NavPills from "components/NavPills/NavPills.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import CardText from "components/Card/CardText.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import Chip from '@material-ui/core/Chip';
// import FormLabel from "@material-ui/core/FormLabel";
// import CircularProgress from '@material-ui/core/CircularProgress';
// import InputAdornment from "@material-ui/core/InputAdornment";
// import ModelIcon from "@material-ui/icons/Devices";
// import EmailIcon from "@material-ui/icons/Email";
// import WebsiteIcon from "@material-ui/icons/Language";
// import BrandIcon from "@material-ui/icons/LocalOffer";
// import SendIcon from "@material-ui/icons/Send";
// import FileIcon from "@material-ui/icons/List";
// import DescriptionIcon from "@material-ui/icons/Description";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import AssignmentIcon from "@material-ui/icons/Assignment";
import FilenameIcon from "@material-ui/icons/Description";
import UploadIcon from "@material-ui/icons/CloudUpload";
// import AddressIcon from "@material-ui/icons/Language";
// import VersionIcon from "@material-ui/icons/Build";
// import PersonIcon from "@material-ui/icons/Person"; // For User Agent
// import PlayIcon from "@material-ui/icons/PlayArrow";
// import CheckIcon from "@material-ui/icons/CheckCircle";
// import ErrorIcon from "@material-ui/icons/Error";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import TvIcon from "@material-ui/icons/Tv";
// import UploadIcon from "@material-ui/icons/CloudUpload";
import DownloadIcon from "@material-ui/icons/CloudDownload";
// import MaterialTable from "material-table";
import CheckedIcon from "@material-ui/icons/CheckBox";
import QuestionIcon from "@material-ui/icons/NotListedLocation";
import PendingIcon from "@material-ui/icons/List";
import RefreshIcon from "@material-ui/icons/RefreshSharp";

// import jsonTableData from "../../JSON/pcapDataSetMapping.json";
// import jsonPcapFileMapping from "../../JSON/pcapFileMapping.json";

// 3rd Party
import { FileUploader } from "react-drag-drop-files";
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import MaterialTable from "@material-table/core";
//import S3 from 'react-aws-s3';
import AWS from 'aws-sdk';

// REST API
import RestAPI from "../../Service/RestAPI.json"


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

export default function FileUpload() {


  const fileTypes = ["PCAP"];
  const [file, setFile] = React.useState(null);

  const [topLock, setTopLock] = React.useState(null);
  const [bottomLock, setBottomLock] = React.useState(null);

  const [downloadProgress, setDownloadProgress] = React.useState(0);

  const [uploadStatus, setUploadStatus] = React.useState(false);

  const [analyzedPcapList, setAnalyzedPcapList] = React.useState(['']);
  const [uploadRoutePcapList, setUploadRoutePcapList] = React.useState(['']);
  const [uploadMmtPcapList, setUploadMmtPcapList] = React.useState(['']);
  const [uploadUndefinedPcapList, setUploadUndefinedPcapList] = React.useState(['']);

  const [pcapPendingList, setPcapPendingList] = React.useState(['']);


  const getUploadTable = async () => {

    const axios = require('axios');
  
  try {
    const resp = await axios.get(`${RestAPI.fileUpload[0].uploadTable}`);

    setAnalyzedPcapList(resp.data.AnalyzedPcapList);
    setUploadRoutePcapList(resp.data.UploadRoutePcapList);
    setUploadMmtPcapList(resp.data.UploadMmtPcapList);
    setUploadUndefinedPcapList(resp.data.UploadUndefinedPcapList);

  } catch (err) {
      // Handle Error Here
      console.error(err);

  }


};



const getPcapPendingTable = async () => {

  const axios = require('axios');

try {
  const resp = await axios.get(`${RestAPI.fileUpload[0].pendingPcapList}`);

  setPcapPendingList(resp.data.pendingList);



} catch (err) {
    // Handle Error Here
    console.error(err);

} finally {
  // console.log("")
  getPcapPendingTable();
}


};


const handleRefresh = () => {

  getPcapPendingTable();

}



const updatePcapPendingTable = async () => {

  const axios = require('axios');

try {
  const resp = await axios.get(`${RestAPI.fileUpload[0].updatePendingPcapList}`);

  getPcapPendingTable();


} catch (err) {
    // Handle Error Here
    console.error(err);

}


};



React.useEffect(() => {
  getUploadTable();
  getPcapPendingTable();

}, [uploadStatus])



const viewAnalyzedPcapListTableColumn = [
  {
    title: "Filename",
    field: "filename",
    cellStyle: {
      width: 600,
      minWidth: 400
    },
  },
  {
    title: "Size",
    field: "size",
  }
];


const viewAnalyzedPcapListDataTableDataSet = analyzedPcapList.map((data) => {

  return {
    // id: data.key,
    filename: data["Filename"],
    size: data["Size"],
  };
});



const viewUploadRoutePcapListTableColumn = [
  {
    title: "Filename",
    field: "filename",
    cellStyle: {
      width: 600,
      minWidth: 400
    },
  },
  {
    title: "Size",
    field: "size",
  }
];


const viewUploadRoutePcapListDataTableDataSet = uploadRoutePcapList.map((data) => {

  return {
    // id: data.key,
    filename: data["Filename"],
    size: data["Size"],
  };
});


const viewUploadMmtPcapListTableColumn = [
  {
    title: "Filename",
    field: "filename",
    cellStyle: {
      width: 600,
      minWidth: 400
    },
  },
  {
    title: "Size",
    field: "size",
  }
];


const viewUploadMmtPcapListDataTableDataSet = uploadMmtPcapList.map((data) => {

  return {
    // id: data.key,
    filename: data["Filename"],
    size: data["Size"],
  };
});


const viewUploadUndefinedPcapListTableColumn = [
  {
    title: "Filename",
    field: "filename",
    cellStyle: {
      width: 600,
      minWidth: 400
    },
  },
  {
    title: "Size",
    field: "size",
  }
];


const viewUploadUndefinedPcapListDataTableDataSet = uploadUndefinedPcapList.map((data) => {

  return {
    // id: data.key,
    filename: data["Filename"],
    size: data["Size"],
  };
});




  React.useEffect(() => {


    const axios = require('axios');

    axios.post(`${RestAPI.fileUpload[0].s3Upload}`, {
    
      gate: "b3BlbiBzZXNhbWU="

    })
    .then((response) => {
      // console.log(response);

      let encodedStringAtoB1 = response.data.TopLock[0];
      let decodedStringAtoB1 = atob(encodedStringAtoB1);

      let encodedStringAtoB2 = response.data.BottomLock[0];
      let decodedStringAtoB2 = atob(encodedStringAtoB2);

      setTopLock(decodedStringAtoB1);
      setBottomLock(decodedStringAtoB2);

    });


  }, [])




  const pendingPcapListTableColumn = [
    {
      title: "Filename",
      field: "filename"
    },
    {
      title: "Size",
      field: "size"
    },
    {
      title: "Location",
      field: "location"
    },
    {
      title: "Status",
      field: "status"
    }
  ];
  
  
  const pendingPcapListDataTableDataSet = pcapPendingList.map((data) => {
  
    return {
      // id: data.key,
      filename: data["Filename"],
      size: data["Size"],
      location: data["Location"],
      status: data["Status"],
    };

  });





const uploadFileRoute = async (file) => {

  setUploadStatus(true);

  const S3_BUCKET = "atsc-stream-manager-pcap/Upload_Route";
  const REGION = "us-west-1";

  AWS.config.update({
    accessKeyId: topLock,
    secretAccessKey: bottomLock,
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const params = {
    Bucket: S3_BUCKET,
    Key: file.name,
    Body: file,
  };

  var upload = s3
    .putObject(params)
    .on("httpUploadProgress", (evt) => {
      // console.log(
      //   "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
      // );

      setDownloadProgress(parseInt((evt.loaded * 100) / evt.total) + "%")




    })
    .promise();

  await upload.then((err, data) => {
    // console.log(err);
    // alert("File uploaded successfully.");
    setUploadStatus(false);
  });
};


  // Function to handle file and store it to file state
  const handleFileChangeRoute= (e) => {

    //console.log(e.target.files[0])
    // console.log(e)

    // Uploaded file
    // const file = e.target.files[0];
    const file = e;
    // Changing file state
    setFile(file);
    uploadFileRoute(file);
  };




  const uploadFileMMT = async (file) => {

    setUploadStatus(true);
  
    const S3_BUCKET = "atsc-stream-manager-pcap/Upload_MMT";
    const REGION = "us-west-1";
  
    AWS.config.update({
      accessKeyId: topLock,
      secretAccessKey: bottomLock,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
  
    const params = {
      Bucket: S3_BUCKET,
      Key: file.name,
      Body: file,
    };
  
    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // console.log(
        //   "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        // );
  
        setDownloadProgress(parseInt((evt.loaded * 100) / evt.total) + "%")
  
  
  
  
      })
      .promise();
  
    await upload.then((err, data) => {
      // console.log(err);
      // alert("File uploaded successfully.");
      updatePcapPendingTable();
      setUploadStatus(false);
    });
  };
  
  
    // Function to handle file and store it to file state
    const handleFileChangeMMT = (e) => {
  
      //console.log(e.target.files[0])
      // console.log(e)
  
      // Uploaded file
      // const file = e.target.files[0];
      const file = e;
      // Changing file state
      setFile(file);
      uploadFileMMT(file);
    };



    const uploadFileUndefined = async (file) => {

      setUploadStatus(true);
    
      const S3_BUCKET = "atsc-stream-manager-pcap/Upload_Undefined";
      const REGION = "us-west-1";
    
      AWS.config.update({
        accessKeyId: topLock,
        secretAccessKey: bottomLock,
      });
      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });
    
      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
        Body: file,
      };
    
      var upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          // console.log(
          //   "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
          // );
    
          setDownloadProgress(parseInt((evt.loaded * 100) / evt.total) + "%")
    
    
    
    
        })
        .promise();
    
      await upload.then((err, data) => {
        // console.log(err);
        // alert("File uploaded successfully.");
        setUploadStatus(false);
      });
    };
    
    
      // Function to handle file and store it to file state
      const handleFileChangeUndefined = (e) => {
    
        //console.log(e.target.files[0])
        // console.log(e)
    
        // Uploaded file
        // const file = e.target.files[0];
        const file = e;
        // Changing file state
        setFile(file);
        uploadFileUndefined(file);
      };
    
  



  const classes = useStyles();

  //  add 3 info box:  Analyzed PCAP Count, Non-Analyzed PCAP Count, Total PCAP Count

  return (
    <div>
      <GridContainer>
        <GridItem              
              xs={12} 
              sm={12} 
              md={12}>

          <Chip 
            color= "primary" 
            icon= {<FilenameIcon />} 
            label= { file ? "Filename: " + file.name : "Filename: xxxxxx" }
            size="medium"
          />

          <Chip 
            color= "primary" 
            icon= {<UploadIcon />} 
            label= {"Uploading: " + downloadProgress}
            size="medium"
          />

        </GridItem>

      </GridContainer>

      <br></br>
      <br></br>


      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>

          { !uploadStatus ?  <FileUploader 
                              handleChange={handleFileChangeRoute} 
                              name="file" 
                              types={fileTypes}
                              label = "Upload Route PCAP File"

          /> : ""}

        </GridItem>

        <GridItem xs={12} sm={12} md={4}>

          { !uploadStatus ?  <FileUploader 
                              handleChange={handleFileChangeMMT} 
                              name="file" 
                              types={fileTypes}
                              label = "Upload MMT PCAP File"

          /> : ""}

        </GridItem>


        <GridItem xs={12} sm={12} md={4}>

          { !uploadStatus ?  <FileUploader 
                              handleChange={handleFileChangeUndefined} 
                              name="file" 
                              types={fileTypes}
                              label = "Upload Undefined PCAP File"

          /> : ""}

        </GridItem>
        
      </GridContainer>


      <br/>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="success"
            tabs={[
              {
                tabName: "Analyzed Stream Files",
                tabIcon: CheckedIcon,
                tabContent: (
                  <div>
                  <div style={{ maxWidth: "100%" }}>
                    <MaterialTable
                      columns={viewAnalyzedPcapListTableColumn}
                      data={viewAnalyzedPcapListDataTableDataSet}
                      title="Stream Manager"
                      options={{
                        exportButton: true,
                        exportAllData: true,
                        filtering: true,
                        showTitle: false,
                        pageSize: 10,
                        search: true
                      }}
                    />
                  </div>

                  <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center">

                      <CSVLink data={viewAnalyzedPcapListDataTableDataSet} filename={"Analyzed_PCAP_File.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>
                      

                    </GridItem>

                </GridContainer>

                </div>
                ),
              },
            ]}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="warning"
            tabs={[
              {
                tabName: "Non-Analyzed Route Files",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                  <div style={{ maxWidth: "100%" }}>
                    <MaterialTable
                      columns={viewUploadRoutePcapListTableColumn}
                      data={viewUploadRoutePcapListDataTableDataSet}
                      title="Stream Manager"
                      options={{
                        exportButton: true,
                        exportAllData: true,
                        filtering: true,
                        showTitle: false,
                        pageSize: 10,
                        search: true
                      }}
                    />
                  </div>

                  <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center">

                      <CSVLink data={viewUploadRoutePcapListDataTableDataSet} filename={"Upload_Route_PCAP_File.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>
                      

                    </GridItem>

                </GridContainer>

                </div>
                ),
              },

              {
                tabName: "Non-Analyzed MMT Files",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                  <div style={{ maxWidth: "100%" }}>
                    <MaterialTable
                      columns={viewUploadMmtPcapListTableColumn}
                      data={viewUploadMmtPcapListDataTableDataSet}
                      title="Stream Manager"
                      options={{
                        exportButton: true,
                        exportAllData: true,
                        filtering: true,
                        showTitle: false,
                        pageSize: 10,
                        search: true
                      }}
                    />
                  </div>

                  <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center">

                      <CSVLink data={viewUploadMmtPcapListDataTableDataSet} filename={"Upload_MMT_PCAP_File.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>
                      

                    </GridItem>

                </GridContainer>

                </div>
                ),
              },

              {
                tabName: "Non-Analyzed Undedfined Files",
                tabIcon: QuestionIcon,
                tabContent: (
                  <div>
                  <div style={{ maxWidth: "100%" }}>
                    <MaterialTable
                      columns={viewUploadUndefinedPcapListTableColumn}
                      data={viewUploadUndefinedPcapListDataTableDataSet}
                      title="Stream Manager"
                      options={{
                        exportButton: true,
                        exportAllData: true,
                        filtering: true,
                        showTitle: false,
                        pageSize: 10,
                        search: true
                      }}
                    />
                  </div>

                  <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center">

                      <CSVLink data={viewUploadUndefinedPcapListDataTableDataSet} filename={"Upload_Undefined_PCAP_File.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>
                      

                    </GridItem>

                </GridContainer>

                </div>
                ),
              },

            ]}
          />
        </GridItem>
      </GridContainer>


      <GridContainer>
        <GridItem  xs={12} sm={12} md={12}>


        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
            tabs={[
              {
                tabName: "Analyzed PCAP File Status",
                tabIcon: PendingIcon,
                tabContent: (
                  <div>
                  <div style={{ maxWidth: "100%" }}>
                    <MaterialTable
                      columns={pendingPcapListTableColumn}
                      data={pendingPcapListDataTableDataSet}
                      title="Stream Manager"
                      options={{
                        exportButton: true,
                        exportAllData: true,
                        filtering: true,
                        showTitle: false,
                        pageSize: 10,
                        search: true
                      }}
                    />
                  </div>

                  <br></br>

                  <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center">

                      <CSVLink data={pendingPcapListDataTableDataSet} filename={"Pending_Analyze_PCAP_File.csv"}>
                        <Button color="warning" size="sm" > 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>



                      <Tooltip
                          id="tooltip-bottom"
                          title='Refresh File Status Table'
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                          >

                          <Button color="info"  size="sm" onClick={handleRefresh}>
                            <RefreshIcon className={classes.icons} />
                            Refresh Table
                          </Button>

                        </Tooltip>
                    

                    </GridItem>

                </GridContainer>

                </div>
                ),
              },
            ]}
          />




        </GridItem>

      </GridContainer>



    </div>
  );
}
