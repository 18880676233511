/*eslint-disable*/
import React, { useCallback, useRef } from 'react';
import { useHistory } from "react-router-dom";
import useWebSocket, { ReadyState } from 'react-use-websocket';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import NavPills from "components/NavPills/NavPills.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// import CardText from "components/Card/CardText.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import SweetAlert from "react-bootstrap-sweetalert";
import FormHelperText from '@mui/material/FormHelperText';
import Heading from "components/Heading/Heading.js";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from '@material-ui/core/Chip';
// import FormLabel from "@material-ui/core/FormLabel";
// import CircularProgress from '@material-ui/core/CircularProgress';
// import InputAdornment from "@material-ui/core/InputAdornment";
// import ModelIcon from "@material-ui/icons/Devices";
// import EmailIcon from "@material-ui/icons/Email";
// import WebsiteIcon from "@material-ui/icons/Language";
// import BrandIcon from "@material-ui/icons/LocalOffer";
// import SendIcon from "@material-ui/icons/Send";
// import FileIcon from "@material-ui/icons/List";
// import DescriptionIcon from "@material-ui/icons/Description";

// @material-ui/icons
import AssignmentIcon from "@material-ui/icons/Assignment";
// import AddressIcon from "@material-ui/icons/Language";
// import VersionIcon from "@material-ui/icons/Build";
// import PersonIcon from "@material-ui/icons/Person"; // For User Agent
// import PlayIcon from "@material-ui/icons/PlayArrow";
// import CheckIcon from "@material-ui/icons/CheckCircle";
// import ErrorIcon from "@material-ui/icons/Error";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import TvIcon from "@material-ui/icons/Tv";
// import UploadIcon from "@material-ui/icons/CloudUpload";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import TestCaseIcon from "@material-ui/icons/FormatListBulleted";
import TestSuiteIcon from "@material-ui/icons/ListAlt";
import RefreshIcon from "@material-ui/icons/RefreshSharp";
import CreateNewIcon from "@material-ui/icons/NoteAdd";
import RunIcon from "@material-ui/icons/PlayCircleFilledSharp";
import EditIcon from "@material-ui/icons/Edit";
import TitleIcon from "@material-ui/icons/Title";
import CategoryIcon from "@material-ui/icons/Category";
import DescriptionIcon from "@material-ui/icons/Description";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import NewStepIcon from "@material-ui/icons/PlaylistAdd";
import ListIcon from "@material-ui/icons/ViewList";
import TestListIcon from "@material-ui/icons/PlaylistAddCheck";
import PcapIcon from "@material-ui/icons/VideoLibrarySharp";
import FrequencyIcon from "@material-ui/icons/GraphicEq";
import MoveUpIcon from "@material-ui/icons/KeyboardArrowUpTwoTone";
import MoveDownIcon from "@material-ui/icons/KeyboardArrowDownTwoTone";
import ChannelIcon from "@material-ui/icons/SettingsRemote";
import DetailIcon from "@material-ui/icons/Info";
import ComputerIcon from "@material-ui/icons/ComputerSharp";
import StartIcon from "@material-ui/icons/PlayCircleFilledSharp";
import StatusIcon from "@material-ui/icons/History";
import ReportIcon from "@material-ui/icons/Assessment";
import IDIcon from "@material-ui/icons/Label";
import PassIcon from "@material-ui/icons/Check";
import FailIcon from "@material-ui/icons/Close";
import NextIcon from "@material-ui/icons/NavigateNext";
import TestIcon from "@material-ui/icons/FormatListNumbered";
import FileIcon from "@material-ui/icons/Description";
import TvIcon from "@material-ui/icons/Tv";



// 3rd Party Components
import MaterialTable from "@material-table/core";
import { ThreeDots } from  'react-loader-spinner'
import { CirclesWithBar } from  'react-loader-spinner'
import { RotatingLines } from  'react-loader-spinner'

// REST API
import RestAPI from "../../Service/RestAPI.json"

// Global State
import { useAtom } from 'jotai'
import { user } from "../../store/store.js";


import jsonTableData from "../../JSON/pcapDataSetMapping.json";
import jsonPcapFileMapping from "../../JSON/pcapFileMapping.json";


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import stylesSweetAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { ConsoleLogger } from "@aws-amplify/core";
import { BluetoothAudioSharp, ContactsOutlined } from "@material-ui/icons";

const useStyles = makeStyles(styles);
const useStylesSweetAlert = makeStyles(stylesSweetAlert);
const useStylesDashboard = makeStyles(dashboardStyle);



export default function TestCase() {

  const history = useHistory();

  // Load Page
  const [isLoading, setIsLoading] = React.useState(false);

  const [alert, setAlert] = React.useState(null);

  const [testCaseList, setTestCaseList] = React.useState(['']);

  // Create New Test Case Pop up
  const [newTestCasePopUpState, setNewTestCasePopUpState] = React.useState(false); 

  // Create Edit Test Case Pop up
  const [editTestCasePopUpState, setEditestCasePopUpState] = React.useState(false); 

  // Create Run Test Case Pop up
  const [newTestCaseRunPopUpState, setNewTestCaseRunPopUpState] = React.useState(false); 

  const [testCaseHeader, setTestCaseHeader] = React.useState('');

  const [testCaseID, setTestCaseID] = React.useState(0);
  const [categoryInput, setCategoryInput] = React.useState('');
  const [titleInput, setTitleInput] = React.useState('');
  const [descriptionInput, setDescriptionInput] = React.useState('');

  const [currentTestSteps, setCurrentTestSteps] = React.useState([]);
  // const [currentTestSteps2, setCurrentTestSteps2] = React.useState([]);

  const [currentTestStepsType, setCurrentTestStepsType] = React.useState([]);

  const [currentTestStepsChannel, setCurrentTestStepsChannel] = React.useState([]);

  const [testCaseListTable, setTestCaseListTable] = React.useState(['']);
  const [testCaseAutomationListTable, setTestCaseAutomationListTable] = React.useState(['']);

  const [customInput, setCustomInput] = React.useState('');

  const [freqencyInputSelection, setFreqencyInputSelection] = React.useState('473');


  const [pcapItems, setPcapItems] = React.useState(['']);
  const [pcapItemsSelection, setPcapItemsSelection] = React.useState(['']);

  const [selectTypeItems, setSelectTypeItems] = React.useState(['NA', "Pass/Fail"]);
  const [selectTypeSelection, setSelectTypeSelection] = React.useState(['NA']);

  const [channelListItem, setChannelListItem] = React.useState(['']);
  const [channelListSelection, setChannelListSelection] = React.useState([]);

  // Updated Channel Variable
  const [open, setOpen] = React.useState(false);
  const [channelItems, setChannelItems] = React.useState([]);
  const [channelItemsSelection, setChannelItemsSelection] = React.useState([]);
  const [channelListMain, setChannelListMain] = React.useState(['']);

  const [testCaseListDetail, setTestCaseListDetail] = React.useState([]);


  const [uniqueComputerNameItems, setUniqueComputerNameItems] = React.useState([]);
  const [uniqueComputerNameSelection, setUniqueComputerNameSelection] = React.useState([]);

  const [installPcapList, setInstallPcapList] = React.useState(['']);

  const [testRunStepFocus, setTestRunStepFocus] = React.useState(0);

  const [specificTestCaseTable, setSpecificTestCaseTable] = React.useState([]);


  const [testStatusTotalCount, setTestStatusTotalCount] = React.useState(0);
  const [testStatusCurrentCount, setTestStatusCurrentCount] = React.useState(0);
  const [testStatusPassCount, setTestStatusPassCount] = React.useState(0);
  const [testStatusFailCount, setTestStatusFailCount] = React.useState(0);
  const [failStepLog, setFailStepLog] = React.useState([]);
  const [passStepLog, setPassStepLog] = React.useState([]);


  const [commentInput, setCommentInput] = React.useState('');

  const [userInfo, setUserInfo] = useAtom(user);

  const [uniqueTvNameItems, setUniqueTvNameItems] = React.useState([]);
  const [uniqueTvNameSelection, setUniqueTvNameSelection] = React.useState([]);

  const [uniqueTestRunID, setUniqueTestRunID] = React.useState("");

  const [testCaseRunResult, setTestCaseRunResult] = React.useState([]);

  /// WebSocket

  const [socketUrl, setSocketUrl] = React.useState('ws://3.238.30.166:9000');
  // console.log(socketUrl);

  const [messageHistory, setMessageHistory] = React.useState([]);
 

  const {
    lastJsonMessage,
    sendMessage,
    lastMessage,
    readyState,
  } = useWebSocket(socketUrl);


  React.useEffect(() => {
    // setTableResponse(lastJsonMessage);

    console.log(lastMessage);


  }, [lastJsonMessage])



  React.useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory(prev => prev.concat(lastMessage));
    }

    console.log(messageHistory);


  }, [lastMessage, setMessageHistory])


  const handleClickSendMessage = useCallback(() =>
  sendMessage('Hello')
  , []);


  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  //




  const handleWebSocketTest = () => {

    let data = {
      id: 12,
      jsonrpc: "2.0",
      method: "org.atsc.query.ratingLevel"
    };
    
    sendMessage(JSON.stringify(data));

  };




  const intervalRef = useRef();

  //console.log(myInterval)

  const handleInterval = (testID) => {
    console.log(myInterval)
    console.log(testID);

    setUniqueTestRunID(testID);

    const myInterval = setInterval( () => {

      getTestCaseRunStatus(testID)

    }, 5000);

    intervalRef.current = myInterval;

  }

  const handleStopInterval =  () => {

    clearInterval(intervalRef.current);

  }


  const handleTestCaseRun = async () => {

    let randomID = Math.floor(Math.random() * Date.now())
    randomID = randomID.toString();
    randomID = "a" + randomID

    setUniqueTestRunID(randomID);


    const axios = require('axios');
  
    axios.post(`${RestAPI.testCase[0].testRunAutomation}`, {

      uniqueTestRunID: randomID,
      tvSelection: uniqueTvNameSelection,
      testCaseID: testCaseID,
      testRunType: "Test Case"
    
    })
    .then((response) => {
      // console.log(response);


      handleInterval(randomID);
    
      
    
    });


    
  
  };


  const hideAlert = () => {
    setAlert(null);
  };

  const successAlert = (message) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={message}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes2.button + " " + classes2.success}
      >
        {/* Test Case has been Saved! */}
      </SweetAlert>
    );

    handleStopInterval(); 

  };


  const failAlert = (message) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={message}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes2.button + " " + classes2.danger}
      >
        {/* Test Case has been Saved! */}
      </SweetAlert>
    );
  };


  const getTestCaseRunStatus = async (randomIDdata) => {

    const axios = require('axios');

    // uniqueTestRunID
    // a1525485451921

    console.log(randomIDdata)

    axios.post(`${RestAPI.testCase[0].testRunResult}`, {

      UniqueTestRunID: randomIDdata

    })
    .then((response) => {
      // console.log(response);

      //setTestCaseRunResult(response.data.testResult);
      setSpecificTestCaseTable(response.data.testResult);

      console.table(response.data.testResult);

      

      // console.table(response.data.lastCompletedIndexNum);

      setTestRunStepFocus(response.data.lastCompletedIndexNum);

      if(response.data.lastCompletedIndexNum == -1){

        let resultFilter = response.data.testResult;
        resultFilter = resultFilter.filter(x => x.Result == "Fail");
        resultFilter = [...new Set(resultFilter.map(x => x['Result']))];
        // console.log(resultFilter);
        // console.log(resultFilter.length);

        if(resultFilter.length > 0){
          saveTestRunResult("Fail", -1, randomIDdata);

        }else{
          saveTestRunResult("Pass", -1, randomIDdata);
        }


	      successAlert("Test Run Completed!");


      }



    });
    
  
  };







  const getTestCaseList = async () => {

    const axios = require('axios');
  
  try {
    const resp = await axios.get(`${RestAPI.testCase[0].testCaseList}`);

    setTestCaseList(resp.data.testCaseTable);
    


  } catch (err) {
      // Handle Error Here
      console.error(err);

  }


};


const getTestCaseListDetail = async () => {

  const axios = require('axios');

try {
  const resp = await axios.get(`${RestAPI.testCase[0].detailTestCaseList}`);

  setTestCaseListDetail(resp.data.testCaseListDetail);


} catch (err) {
    // Handle Error Here
    console.error(err);

}


};


const getTestCaseStepList = async () => {

  const axios = require('axios');

try {
  const resp = await axios.get(`${RestAPI.testCase[0].testCaseStepList}`);

  setTestCaseListTable(resp.data.testCaseStepList);
  //console.log(resp.data.testCaseStepList);
  


} catch (err) {
    // Handle Error Here
    console.error(err); 

}


};


// const getTestCaseAutomationList = async () => {

//   const axios = require('axios');

// try {
//   const resp = await axios.get(`${RestAPI.testCase[0].testCaseAutomationList}`);

//   setTestCaseAutomationListTable(resp.data.testCaseAutomationList);
//   //console.log(resp.data.testCaseAutomationList);
  


// } catch (err) {
//     // Handle Error Here
//     console.error(err); 

// }


// };


const getTestCaseAutomationList = async () => {

  const axios = require('axios');

  axios.post(`${RestAPI.testCase[0].testCaseAutomationList}`, {

    filename: pcapItemsSelection

  })
  .then((response) => {
    // console.log(response);

    setTestCaseAutomationListTable(response.data.testCaseAutomationList)

    //console.table(response.data.testCaseAutomationList)

  });


};

React.useEffect(() => {

  getTestCaseAutomationList();


}, [pcapItemsSelection])





const getAutoRun = async () => {

  const axios = require('axios');

try {
  const resp = await axios.get(`${RestAPI.testCase[0].autoRun}`);


} catch (err) {
    // Handle Error Here
    console.error(err); 

}


};


const getAnalyzedPcapFiles = async () => {

  const axios = require('axios');

try {
  const resp = await axios.get(`${RestAPI.fileUpload[0].uploadTable}`);

  //console.log(resp.data.analyzedPcapList_mod);
  setPcapItems(resp.data.analyzedPcapList_mod);


} catch (err) {
    // Handle Error Here
    console.error(err);

}



};


const getChannelList = async (filename) => {

  const axios = require('axios');

  axios.post(`${RestAPI.testCase[0].channelList}`, {

    filename: filename
  
  })
  .then((response) => {
    // console.log(response);
  
    setChannelListItem(response.data.channelList);
  
  });


};


const handleChannel = event => {
  //setChannelListSelection(event.target.value);
  setChannelItemsSelection(event.target.value);

  
}

// console.log(channelItemsSelection);


  React.useEffect(() => {

    getTestCaseList();
    getTestCaseListDetail();
    getAnalyzedPcapFiles();
    getTestCaseStepList();
    getTestCaseAutomationList();
    getChannelListTable();
    getComputerStatusTable()

  }, [])
  




  const viewtestCaseTableColumn = [
    {
      title: "ID",
      field: "id"
    },
    {
      title: "Category",
      field: "category"
    },
    {
      title: "Title",
      field: "title"
    },
    {
      title: "Description",
      field: "description"
    }
  ];


  const viewTestCaseDataSet = testCaseList.map((data) => {

    return {
      id: data.ID,
      category: data["Category"],
      title: data["Title"],
      description: data["Description"]
    };
  });




  const viewtestCaseStepTableColumn = [
    {
      title: "Step",
      field: "step"
    },
    {
      title: "Type",
      field: "type"
    }
  ];

//currentTestStepsType
  const viewTestCaseStepDataSet = testCaseListTable.map((data) => {

    return {
      step: data["Step"],
      type: data["Type"]
    };
  });


  const viewtestCaseAutomationTableColumn = [
    {
      title: "Step",
      field: "step"
    },
    {
      title: "Type",
      field: "type"
    }
  ];


  const viewTestCaseAutomationDataSet = testCaseAutomationListTable.map((data) => {

    return {
      step: data["Step"],
      type: data["Type"]
    };
  });


  const viewCurrentTestCaseStepTableColumn = [
    {
      title: "Step",
      field: "step"
    },
    {
      title: "Type",
      field: "type"
    }
  ];


  const viewCurrentTestCaseStepDataSet = currentTestSteps.map((data) => {

    return {
      step: data["Step"],
      type: data["Type"]
    };
  });




  const filterTest = specificTestCaseTable.map((data, index) => {

    data

    return {
      // step: data["Step"]
      step: data
    };



  });







  const viewTestRunStepListTableColumn = [

    {
      title: "Test ID",
      field: "testCaseID",
      width: "10%",
      // align: "center"
      // cellStyle: {
      //   textAlign:'center'
      // },
      // headerStyle: {
      //   textAlign:'center'
      // }
    },

    {
      title: "Step",
      field: "index",
      width: "10%"
    },

    {
      title: "Description",
      field: "step",
      width: "60%",
    },

    {
      title: "Status",
      field: "status",
      width: "10%",
    },

    {
      title: "Result",
      field: "result",
      width: "10%",
    }
  ];

  

  var viewTestRunStepListDataSet = specificTestCaseTable.map((data, index) => {

    return {
      testCaseID: data["ID"],
      index: index + 1,
      step: data["Step"],
      type: data["Type"],
      status: data["Status"],
      result: data["Result"]
    };
  });





  const viewtestCaseListDetailTableColumn = [
    {
      title: "ID",
      field: "id"
    },
    {
      title: "Category",
      field: "category"
    },
    {
      title: "Title",
      field: "title"
    },
    {
      title: "Description",
      field: "description"
    },
    {
      title: "Freqency",
      field: "frequency"
    },
    {
      title: "PCAP",
      field: "pcap"
    },
    {
      title: "Step",
      field: "step"
    },
    {
      title: "Type",
      field: "type"
    }
  ];


  const viewTestCaseListDetailDataSet = testCaseListDetail.map((data) => {

    return {
      id: data["ID"],
      category: data["Category"],
      title: data["Title"],
      description: data["Description"],
      frequency: data["Frequency"],
      pcap: data["PCAP"],
      step: data["Step"],
      type: data["Type"]
    };
  });



  const handlePcap = () => {

    let filterChannel = channelListMain.filter(x => x.Filename == pcapItemsSelection);
  
    filterChannel = filterChannel.filter(x => typeof x['Virtual.Channel'] != 'undefined');
    filterChannel = [...new Set(filterChannel.map(x => x['Virtual.Channel']))];
    filterChannel = filterChannel.map(x => x.replace("-", "."));
    setChannelItems(filterChannel);
  
  };


  React.useEffect(() => {

    handlePcap();
  
  }, [pcapItemsSelection])




  const handleCustomType = event => {

    setSelectTypeSelection(event.target.value);
  
  };



  const handleAdd = async () => {

    const axios = require('axios');
  
    axios.post(`${RestAPI.testCase[0].customText}`, {
  
      customText: customInput,
      customType: selectTypeSelection
    
    })
    .then((response) => {
      // console.log(response);
    
      getTestCaseStepList();
    
    });
  
  
  };



  const handleSave = async () => {


    // console.log(testCaseID);
    // console.log(titleInput);
    // console.log(descriptionInput);
    // console.log(categoryInput);
    // console.log(freqencyInputSelection);
    // console.log(pcapItemsSelection);
    // console.log(currentTestSteps);


    const axios = require('axios');

    axios.post(`${RestAPI.testCase[0].saveTestCase}`, {
    
      ID: testCaseID,
      Title: titleInput,
      Description: descriptionInput,
      Category: categoryInput,
      Frequency: freqencyInputSelection,
      PCAP: pcapItemsSelection,
      Step: currentTestSteps
    
    })
    .then((response) => {
      // console.log(response);
      setNewTestCasePopUpState(false);
      getTestCaseList();
      getTestCaseListDetail();
    
      // Create a quick pop-up
      successAlert("Save Success!");

      // Reset Save Items
      //resetState();

  

    });
  
  
  };


  const resetState = () => {

          // Reset Save Items
          setTestCaseID(0);
          setTestCaseHeader('');
          setCategoryInput('');
          setTitleInput('');
          setDescriptionInput('');
          setCurrentTestSteps([]);
          setFreqencyInputSelection('473');
          setPcapItemsSelection(['']);

          setTestRunStepFocus(0);
          setCurrentTestStepsType([]);
          setTestStatusPassCount(0);
          setTestStatusFailCount(0);

          setFailStepLog([]);
          setPassStepLog([]);

          setCommentInput('');


          handleStopInterval();

  }



  const handleTestCasePopupNew = () => {

    resetState();

    setTestCaseHeader("Create New Test Case");
    setNewTestCasePopUpState(true); 
  

  };


  const handleTestCasePopupOld = (payload) => {

    let customPageTitle = `${"Edit Test Case - ID# "}${payload}`;

    setTestCaseHeader(customPageTitle);
    setNewTestCasePopUpState(true); 
  
  };


  const handleTestCasePopupClose = () => {

    setNewTestCasePopUpState(false);
    resetState();


  };



  const handleTestCasePopupRun = (payload) => {

    // let customPageTitle = `${"Edit Test Case - ID# "}${payload}`;

    // setTestCaseHeader(customPageTitle);
    // setNewTestCasePopUpState(true); 


    setNewTestCaseRunPopUpState(true); 
    setIsLoading(true); 

  
  };


  const handleRunTestCasePopupClose = () => {

    setNewTestCaseRunPopUpState(false);
    resetState();
    handleStopInterval(); // Stop Refreshing Data

  };


  const handleRunTestCaseHardFail = () => {

    if(commentInput == ''){

      failAlert("Comment Is Missing!")

    }else{

      saveTestRunResult("Fail", testRunStepFocus);
      successAlert("Test Result Submitted!");

    }



  };

  const handleViewReport = () => {
    
    handleStopInterval(); // Stop Refreshing Data

    history.push('/admin/report');

  };




  
  const handleEdit = async (testCaseID, testCaseTitle, testCaseDescription) => {

    //console.log(testCaseID, testCaseTitle, testCaseDescription);

    const axios = require('axios');
  
    axios.post(`${RestAPI.testCase[0].specificTestCase}`, {
  
      testID: testCaseID,
      testTitle: testCaseTitle,
      testDescription: testCaseDescription
    
    })
    .then((response) => {
      // console.log(response);

      setTestCaseID(response.data.specificTestCase[0].ID);
      setCategoryInput(response.data.specificTestCase[0].Category);
      setTitleInput(response.data.specificTestCase[0].Title);
      setDescriptionInput(response.data.specificTestCase[0].Description);
      setFreqencyInputSelection(response.data.specificTestCase[0].Frequency);
      setPcapItemsSelection([response.data.specificTestCase[0].PCAP]);

      setCurrentTestSteps(response.data.specificTestCase);

      

      let testIDinfo = response.data.specificTestCase[0].ID;
      handleTestCasePopupOld(testIDinfo);

  
    
    });


  };



  const handleRun = async (testCaseID, testCaseTitle, testCaseDescription) => {

    getInstallPcapListTable(); 

    //console.log(testCaseID, testCaseTitle, testCaseDescription);


    handleTestCasePopupRun(testCaseID);

    const axios = require('axios');
  
    axios.post(`${RestAPI.testCase[0].specificTestCase}`, {
  
      testID: testCaseID,
      testTitle: testCaseTitle,
      testDescription: testCaseDescription
    
    })
    .then((response) => {
      // console.log(response);

      setTestCaseID(response.data.specificTestCase[0].ID);
      setCategoryInput(response.data.specificTestCase[0].Category);
      setTitleInput(response.data.specificTestCase[0].Title);
      setDescriptionInput(response.data.specificTestCase[0].Description);
      setFreqencyInputSelection(response.data.specificTestCase[0].Frequency);
      setPcapItemsSelection([response.data.specificTestCase[0].PCAP]);



      
      //console.log(testCaseListDetail.filter(data => data['ID'] === testCaseID));
      // console.log(testCaseListDetail);

      let saveSteps = response.data.specificTestCase;

      setCurrentTestSteps(saveSteps);
      // setCurrentTestStepsType(saveSteps.map((data) => data.Type));
      setSpecificTestCaseTable(saveSteps);

      //console.table(saveSteps);
      

      // console.table(saveSteps.map((data) => data.Type));
      

      //let testIDinfo = response.data.specificTestCase[0].ID;
      //handleTestCasePopupOld(testIDinfo);

      //console.log(testIDinfo);

  
      setIsLoading(false);
    
    });





  
  };



  const handleComputerName = event => {
    setUniqueComputerNameSelection(event.target.value);
    // setPcapItems(event.target.value);
  
  };



  const getInstallPcapListTable = async () => {

    const axios = require('axios');
  
  try {
    const resp = await axios.get(`${RestAPI.remoteControl[0].fileList}`);
  
    setInstallPcapList(resp.data.fileList);
  
    let uniqueComputerNames = resp.data.fileList;
    uniqueComputerNames = uniqueComputerNames.map((data) => data.Computer_Name);
    setUniqueComputerNameItems([...new Set(uniqueComputerNames)]);
  
  } catch (err) {
      // Handle Error Here
      console.error(err);
  
  }
  
  
  };



  const getTestStatus =  () => {

    let stepCount = testRunStepFocus + 1;

    if(testRunStepFocus + 1 > specificTestCaseTable.length){
      stepCount = testRunStepFocus;
    }

    return (
      `${stepCount}${"/"}${specificTestCaseTable.length}`
    )
  
  };



  const saveTestRunResult = async (result, lastStep, testRunID) => {

    //console.log(lastStep);

    const axios = require('axios');

    // console.log(testCaseID);
    // console.log(testCaseTitle);
    // console.log(testCaseDescription);
    // console.log(pcapItemsSelection);
    // console.log(freqencyInputSelection);
    // console.log(userInfo);
    // console.log(testStatusPassCount);
    // console.log(testStatusFailCount);
    // console.log(failStepLog);
    // console.log(passStepLog);

    console.log(testRunID);

  
    axios.post(`${RestAPI.testCase[0].testResult}`, {
  
      user: userInfo,
      lastStep: lastStep + 1,
      comment: commentInput,
      UniqueTestRunID: testRunID


    })
    .then((response) => {
      // console.log(response);
      setTestRunStepFocus(-1);
      

    
    });




  
  };





  const getChannelListTable = async () => {

    const axios = require('axios');
  
    try {
      const resp = await axios.get(`${RestAPI.remoteControl[0].channelList}`);
  
      //console.table(resp.data.channelList);
      setChannelListMain(resp.data.channelList);
  
    } catch (err) {
        // Handle Error Here
        console.error(err);
  
    }
  
  
  };



  const pcapTableColumn = [
    {
      title: "PCAP File",
      field: "pcapFile"
    }
  ];
  
  
  //console.log(pcapItems);
  
  const pcapDataTableDataSet = pcapItems.map((data) => {
  
    return {
      // id: data.key,
      pcapFile: data
    };
  });
  
  
  
  const pcapTableColumnSelection = [
    {
      title: "PCAP File",
      field: "pcapFile"
    }
  ];
  
  
  //console.log(pcapItems);
  
  const pcapDataTableDataSetSelection = pcapItemsSelection.map((data) => {
  
    return {
      // id: data.key,
      pcapFile: data
    };
  });



  const handleCurrentTestStepsChannel =  () => {

    // setCurrentTestStepsChannel(prevState => prevState.concat(channelItemsSelection));

    // console.log(channelItemsSelection);
    // console.log(pcapItemsSelection);
    // console.log(currentTestSteps);
  
  };


// console.log(currentTestStepsChannel)



  const handleTvName = event => {
    setUniqueTvNameSelection(event.target.value);

  };



  const getComputerStatusTable = async () => {

    const axios = require('axios');
  
  try {
    const resp = await axios.get(`${RestAPI.remoteControl[0].computerStatus}`);
  
    //setComputerStatusList(resp.data.computerStatus);
  
  
    let uniqueTvNames = resp.data.computerStatus
    uniqueTvNames = uniqueTvNames.map((data) => data.TvAppName);
  
    setUniqueTvNameItems(uniqueTvNames);
  
  } catch (err) {
      // Handle Error Here
      console.error(err);
  
  }
  
  
  };




  const classes = useStyles();
  const classes2 = useStylesSweetAlert();
  const dashboardClasses = useStylesDashboard();

  return (
    <div>
      
      {alert}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
            tabs={[
              {
                tabName: "Test Case List",
                tabIcon: TestCaseIcon,
                tabContent: (
                  <div>
                    <div style={{ maxWidth: "100%" }}>
                      <MaterialTable
                        columns={viewtestCaseTableColumn}
                        data={viewTestCaseDataSet}
                        title="Test Case"
                        options={{
                          exportButton: true,
                          exportAllData: true,
                          filtering: true,
                          showTitle: false,
                          pageSize: 5,
                          search: true
                        }}

                        actions={[
                          {
                            icon: RunIcon,
                            tooltip: 'Run Test Case',
                            onClick: (event, rowData) => {
                              // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                              //onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                              // setFullReviewModal(true); // Show Pop-up

                              //console.log(rowData.id, rowData.title, rowData.description, ()=>{});

                              handleRun(rowData.id, rowData.title, rowData.description);

                            }

                          },

                          {
                            icon: EditIcon,
                            tooltip: 'Edit Test Case',
                            onClick: (event, rowData) => {
                              // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                              //onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                              // setFullReviewModal(true); // Show Pop-up

                              //console.log(rowData.id, rowData.title, rowData.description, ()=>{});

                              handleEdit(rowData.id, rowData.title, rowData.description);
                              
                              //setNewTestCasePopUpState(true)}

                            }

                          },

                          {

                            icon: RemoveIcon,
                            tooltip: 'Remove Step',
                            onClick: (event, rowData) => {
                              // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                              //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});

                              const axios = require('axios');

                              axios.post(`${RestAPI.testCase[0].removeTestCaseMain}`, {
                            
                                removeTestCaseMain: rowData.id
                              
                              })
                              .then((response) => {
                                // console.log(response);
                              
                                getTestCaseList();
                                getTestCaseListDetail();
                              
                              });


                            }

                          }

                        ]}
                        
                        
                      />
                    </div>


                    <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center">

                          <Button color="warning" size="sm" style={{marginTop: "10%"}} onClick={handleTestCasePopupNew} > 
                            <CreateNewIcon className={classes.icons} />
                              Create New Test Case
                          </Button>
                        

                      </GridItem>

                        {/* Create New Test Case Pop-Up */}


                        <div>
                          <Dialog
                            //fullScreen
                            fullWidth={true}
                            maxWidth={'lg'}
                            open={newTestCasePopUpState}
                            onClose={() => setNewTestCasePopUpState(false)}
                            // TransitionComponent={'Zoom'}
                            aria-labelledby="new-test-case-title"
                          >
                            <DialogTitle id="new-test-case-title"><strong> {`${testCaseHeader}`}  </strong></DialogTitle>
                            <DialogContent>

                              <GridContainer>

                                <GridItem xs={12}
                                  sm={12}
                                  md={4}>

                                  <Card>
                                    <CardHeader color="rose" icon>
                                      <CardIcon color="rose">
                                        <TitleIcon />
                                        
                                        
                                      </CardIcon>
                                      <h4 className={
                                        classes.cardIconTitle
                                      }>
                                        <b>Title</b>
                                      </h4>
                                    </CardHeader>
                                    <CardBody>
                                      <form onSubmit={e => { e.preventDefault(); }}> 
                                        <CustomInput
                                          labelText="Example: 'CC display'"
                                          id="Input_TestCase_Title"
                                          helperText={`${'Current: '}${titleInput}`}
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                          inputProps={{
                                            onBlur: event => {setTitleInput(event.target.value)},
                                            type: "text"
                                          }}
                                          
                                      />
                                      
                                      </form>
                                      
                                    </CardBody>

                                  </Card>

                                </GridItem>


                                <GridItem xs={12}
                                  sm={12}
                                  md={4}>

                                  <Card>
                                    <CardHeader color="rose" icon>
                                      <CardIcon color="rose">
                                        <DescriptionIcon />
                                        
                                        
                                      </CardIcon>
                                      <h4 className={
                                        classes.cardIconTitle
                                      }>
                                        <b>Description</b>
                                      </h4>
                                    </CardHeader>
                                    <CardBody>
                                      <form onSubmit={e => { e.preventDefault(); }}> 
                                        <CustomInput
                                          labelText="Example: 'On when muting'"
                                          id="Input_TestCase_Description"
                                          helperText={`${'Current: '}${descriptionInput}`}
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                          inputProps={{
                                            onBlur: event => {setDescriptionInput(event.target.value)},
                                            type: "text"
                                          }}
                                          
                                      />
                                      
                                      </form>
                                      
                                    </CardBody>

                                  </Card>

                                </GridItem>


                                <GridItem xs={12}
                                  sm={12}
                                  md={4}>

                                  <Card>
                                    <CardHeader color="rose" icon>
                                      <CardIcon color="rose">
                                        <CategoryIcon />
                                        
                                        
                                      </CardIcon>
                                      <h4 className={
                                        classes.cardIconTitle
                                      }>
                                        <b>Category</b>
                                      </h4>
                                    </CardHeader>
                                    <CardBody>
                                      <form onSubmit={e => { e.preventDefault(); }}> 
                                        <CustomInput
                                          labelText="Example: 'Video'"
                                          id="Input_TestCase_Category"
                                          helperText={`${'Current: '}${categoryInput}`}
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                          inputProps={{
                                            onBlur: event => {setCategoryInput(event.target.value)},
                                            type: "text"
                                          }}
                                          
                                      />
                                      
                                      </form>
                                      
                                    </CardBody>

                                  </Card>

                                </GridItem>

                              </GridContainer>
                              
                              <GridContainer>




                              <GridItem xs={12}
                                      sm={12}
                                      md={4}>

                                      <Card>
                                        <CardHeader color="rose" icon>
                                          <CardIcon color="rose">
                                            <PcapIcon />
                                            
                                            
                                          </CardIcon>
                                          <h4 className={
                                            classes.cardIconTitle
                                          }>
                                            <b>PCAP</b>
                                          </h4>
                                        </CardHeader>
                                        <CardBody >

                                          <GridContainer>

                                            <GridItem
                                              xs={12}
                                              container
                                              direction="row"
                                              justify="center"
                                              alignItems="center">


                                              <Chip 
                                                color= "default" 
                                                icon= {<FileIcon />} 
                                                label= {pcapItemsSelection}
                                                size="medium"
                                                variant="outlined"
                                              />

                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <br></br>

                                            <GridItem
                                              xs={12}
                                              container
                                              direction="row"
                                              justify="center"
                                              alignItems="center">

                                            < Button onClick={() => setOpen(true)} color="tumblr" size="sm">
                                                Select PCAP
                                              </Button>

              
                                                </GridItem>

   


                                            </GridItem>

                                          </GridContainer>


                                          <Dialog
                                            fullWidth={true}
                                            maxWidth={'lg'}
                                            open={open}
                                            onClose={() => setOpen(false)}
                                            //TransitionComponent={Transition}
                                            aria-labelledby="pcap-selection-dialog"
                                          >
                                            <DialogTitle id="pcap-selection-dialog"><strong> PCAP Selection </strong></DialogTitle>
                                            <DialogContent>
                                              {/* <DialogContentText> */}
                                                {/* {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )} */}
                                              {/* </DialogContentText> */}

                                              {/* Pop-up Content */}
                                              <GridContainer>
                              
                                                <GridItem xs={12} sm={12} md={6} >

                                                  <CustomTabs
                                                      //title="Tasks:"
                                                      centered={true}
                                                      headerColor="warning"
                                                      
                                                      tabs={[
                                                        {
                                                          tabName: "PCAP List",
                                                          tabIcon: PcapIcon,
                                                            tabContent: (
                                                              <div>    
                                                                  <div style={{ maxWidth: '100%' }}>
                                                                          <MaterialTable
                                                                            columns={pcapTableColumn}
                                                                            data={pcapDataTableDataSet}
                                                                            title="Model List"
                                                                            options={{
                                                                              exportButton: false,
                                                                              exportAllData: false,
                                                                              filtering: true,
                                                                              showTitle: false,
                                                                              searchAutoFocus: true,
                                                                              pageSize: 5
                                                                            }}
                                                                            actions={[
                                                                              {
                                                                                icon: AddIcon,
                                                                                tooltip: 'Add PCAP',
                                                                                onClick: (event, rowData) => {
                                                                                  // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                                                  //onClickAddModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});

                                                                                  // console.table(rowData);
                                                                                  // console.log(rowData);
                                                                                  
                                                                                  setPcapItemsSelection([rowData.pcapFile]);
                                                                                  //console.log(rowData.pcapFile);
                                                                                  

                                                                                }

                                                                              }
                                                                            ]}
                                                                            components={{
                                                                              Action: props => (

                                                                                <Tooltip
                                                                                  id="tooltip-bottom"
                                                                                  title="Add PCAP"
                                                                                  placement="top"
                                                                                  classes={{ tooltip: classes.tooltip }}
                                                                                >
                                                                                    <Button color="success" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                                                      <AddIcon />
                                                                                    </Button>
                                                                                </Tooltip>
                                                                                
                                                                              )
                                                                            }}
                                            
                                                                          />
                                                                    </div>


                                                                </div>
                                                          )

                                                          
                                                        }
                                                      ]}
                                                    /> 

                                                  </GridItem>

                                        {/* Saved Model List */}

                                                  <GridItem xs={12} sm={12} md={6} >

                                                    <CustomTabs
                                                        //title="Tasks:"
                                                        centered={true}
                                                        headerColor="info"
                                                        
                                                        tabs={[
                                                          {
                                                            tabName: "PCAP Selection",
                                                            tabIcon: PcapIcon,
                                                              tabContent: (
                                                                <div>    
                                                                    <div style={{ maxWidth: '100%' }}>
                                                                            <MaterialTable
                                                                              columns={pcapTableColumnSelection}
                                                                              data={pcapDataTableDataSetSelection}
                                                                              title="Model List"
                                                                              options={{
                                                                                exportButton: false,
                                                                                exportAllData: false,
                                                                                filtering: true,
                                                                                showTitle: false,
                                                                                searchAutoFocus: true,
                                                                                pageSize: 5
                                                                              }}
                                                                              // actions={[
                                                                              //   {
                                                                              //     icon: RemoveIcon,
                                                                              //     tooltip: 'Remove Model',
                                                                              //     onClick: (event, rowData) => {
                                                                              //       // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                                              //       //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                                                                    

                                                                              //     }

                                                                              //   }
                                                                              // ]}
                                                                              components={{
                                                                                Action: props => (

                                                                                  <Tooltip
                                                                                    id="tooltip-bottom"
                                                                                    title="Remove Model"
                                                                                    placement="top"
                                                                                    classes={{ tooltip: classes.tooltip }}
                                                                                  >
                                                                                      <Button color="danger" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                                                        <RemoveIcon />
                                                                                      </Button>
                                                                                  </Tooltip>

                                                                                  
                                                                                )
                                                                              }}

                                                                            />
                                                                      </div>


                                                                  </div>
                                                            )

                                                            
                                                          }
                                                        ]}
                                                      /> 

                                                  </GridItem>
                                                  
                                                </GridContainer>
                                            
                                            </DialogContent>
                                            <DialogActions>
                                              <Button onClick={() => setOpen(false)} color="primary">
                                              {/* <Button onClick={handlePopUpClose()} color="primary"> */}
                                                Close
                                              </Button>
                                            </DialogActions>
                                          </Dialog>



                                          {/* <FormControl fullWidth
                                            className={
                                              classes.selectFormControl
                                          }>


                                            <InputLabel htmlFor="file-select"
                                              className={
                                                classes.selectLabel
                                            }>
                                              Select PCAP
                                            </InputLabel>

                                            <Select style={
                                                {minWidth: "100%"}
                                              }
                                              MenuProps={
                                                {className: classes.selectMenu}
                                              }
                                              classes={
                                                {select: classes.select}
                                              }
                                              //multiple
                                              autoWidth={false}
                                              value={pcapItemsSelection}
                                              
                                              onChange={handlePcap}
                                              inputProps={
                                                {
                                                  name: "fileSelect",
                                                  id: "file-select"
                                                }
                                            }>
                                              <MenuItem disabled
                                                classes={
                                                  {root: classes.selectMenuItem}
                                              }>
                                                <b>Single Selection</b>
                                              </MenuItem>

                                              {
                                              pcapItems.map((PcapItems, index) => (
                                                <MenuItem key={index}
                                                  classes={
                                                    {
                                                      root: classes.selectMenuItem,
                                                      selected: classes.selectMenuItemSelected
                                                    }
                                                  }
                                                  value={PcapItems}>
                                                  {PcapItems} </MenuItem>
                                              ))
                                            } 
                                            </Select>
                                            <FormHelperText style={{opacity: "0"}}>.</FormHelperText>

                                          </FormControl> */}



                                        </CardBody>
                                      </Card>

                                    </GridItem>





                                    <GridItem xs={12}
                                      sm={12}
                                      md={4}>

                                      <Card>
                                        <CardHeader color="rose" icon>
                                          <CardIcon color="rose">
                                            <FrequencyIcon />
                                            
                                            
                                          </CardIcon>
                                          <h4 className={
                                            classes.cardIconTitle
                                          }>
                                            <b>Frequency</b>
                                          </h4>
                                        </CardHeader>
                                        <CardBody >
                                          <form onSubmit={e => { e.preventDefault(); }}> 
                                            <CustomInput
                                              labelText="Default: 473"
                                              id="Input_Frequency_Test_Case"
                                              helperText={`${'Current: '}${freqencyInputSelection}`}
                                              formControlProps={{
                                                fullWidth: true
                                              }}
                                              inputProps={{
                                                onBlur: event => {setFreqencyInputSelection(event.target.value)},
                                                type: "text"
                                              }}
                                              
                                          />
                                          
                                          </form>

                                        </CardBody>

                                        <br></br>


                                      </Card>

                                      

                                    </GridItem>


                                    <GridItem xs={12}
                                      sm={12}
                                      md={4}>

                                      <Card>
                                        <CardHeader color="rose" icon>
                                          <CardIcon color="rose">
                                            <ChannelIcon />
                                            
                                            
                                          </CardIcon>
                                          <h4 className={
                                            classes.cardIconTitle
                                          }>
                                            <b>Channel</b>
                                          </h4>
                                        </CardHeader>
                                        <CardBody>
                                          <FormControl fullWidth
                                            className={
                                              classes.selectFormControl
                                          }>


                                            <InputLabel htmlFor="channel-select"
                                              className={
                                                classes.selectLabel
                                            }>
                                              Select Channel
                                            </InputLabel>

                                            <Select style={
                                                {minWidth: "100%"}
                                              }
                                              MenuProps={
                                                {className: classes.selectMenu}
                                              }
                                              classes={
                                                {select: classes.select}
                                              }
                                              //multiple
                                              autoWidth={false}
                                              value={channelItemsSelection}
                                              onChange={handleChannel}
                                              inputProps={
                                                {
                                                  name: "channelSelect",
                                                  id: "channel-select"
                                                }
                                            }>
                                              <MenuItem disabled
                                                classes={
                                                  {root: classes.selectMenuItem}
                                              }>
                                                <b>Single Selection</b>
                                              </MenuItem>

                                              {
                                              channelItems.map((ChannelItems, index) => (
                                                <MenuItem key={index}
                                                  classes={
                                                    {
                                                      root: classes.selectMenuItem,
                                                      selected: classes.selectMenuItemSelected
                                                    }
                                                  }
                                                  value={ChannelItems}>
                                                  {ChannelItems} </MenuItem>
                                              ))
                                            } 
                                            </Select>

                                            <br></br>
                                          <br></br>

                                          </FormControl>
                                        </CardBody>
                                        
                                      </Card>


                                      

                                    </GridItem>

                              </GridContainer>



                              <GridContainer>
   
                                <GridItem xs={12} sm={12} md={6} >

                                  <CustomTabs
                                      //title="Tasks:"
                                      centered={true}
                                      headerColor="info"
                                      
                                      tabs={[
                                        {
                                          tabName: "Test Step List",
                                          tabIcon: ListIcon,
                                            tabContent: (
                                              <div>    
                                                  <div style={{ maxWidth: '100%' }}>
                                                          <MaterialTable
                                                            columns={viewtestCaseStepTableColumn}
                                                            data={viewTestCaseStepDataSet}
                                                            title="Test Step"
                                                            options={{
                                                              exportButton: false,
                                                              exportAllData: false,
                                                              filtering: true,
                                                              showTitle: false,
                                                              searchAutoFocus: true,
                                                              pageSize: 5,
                                                              // rowStyle: {
                                                              //   backgroundColor: '#EEE',
                                                              // }

                                                            }}
                                                            actions={[
                                                              {
                                                                icon: AddIcon,
                                                                tooltip: 'Add Step',
                                                                onClick: (event, rowData) => {
                                                                  // Grab the data from the table row that was clicked 
                                                                  // onClickAddModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});

                                                                  //console.table(rowData);
                                                                  //setCurrentTestSteps(prevState => prevState.concat(rowData.step));
                                                                  //setCurrentTestStepsType(prevState => prevState.concat(rowData.type));

                                                                  setCurrentTestSteps((prevState) => [...prevState, {
                                                                    Step: rowData.step, 
                                                                    Type: rowData.type
                                                                  }])


                                                                }

                                                              },

                                                              {
                                                                icon: RemoveIcon,
                                                                tooltip: 'Remove Step',
                                                                onClick: (event, rowData) => {
                                                                  // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                                  //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});

                                                                  const axios = require('axios');
  
                                                                  axios.post(`${RestAPI.testCase[0].removeTestCaseItem}`, {
                                                                
                                                                    removeItem: rowData.step
                                                                  
                                                                  })
                                                                  .then((response) => {
                                                                    // console.log(response);
                                                                  
                                                                    getTestCaseStepList();
                                                                  
                                                                  });


                                                                }

                                                              }
                                                              
                                                            ]}


                                                          />
                                                    </div>


                                                </div>
                                          )

                                          
                                        },

                                        {
                                          tabName: "Automation List",
                                          tabIcon: ListIcon,
                                            tabContent: (
                                              <div>    
                                                  <div style={{ maxWidth: '100%' }}>
                                                          <MaterialTable
                                                            columns={viewtestCaseAutomationTableColumn}
                                                            data={viewTestCaseAutomationDataSet}
                                                            title="Automation Step"
                                                            options={{
                                                              exportButton: false,
                                                              exportAllData: false,
                                                              filtering: true,
                                                              showTitle: false,
                                                              searchAutoFocus: true,
                                                              pageSize: 5,
                                                              // rowStyle: {
                                                              //   backgroundColor: '#EEE',
                                                              // }

                                                            }}
                                                            actions={[
                                                              {
                                                                icon: AddIcon,
                                                                tooltip: 'Add Step',
                                                                onClick: (event, rowData) => {
                                                                  // Grab the data from the table row that was clicked 
                                                                  // onClickAddModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});

                                                                  //console.table(rowData);
                                                                  //setCurrentTestSteps(prevState => prevState.concat(rowData.step));


                                                                  // setCurrentTestStepsType(prevState => prevState.concat(rowData.type));


                                                                  //setCurrentTestStepsType(prevState => prevState.concat(rowData.type));


                                                                  setCurrentTestSteps((prevState) => [...prevState, {
                                                                    Step: rowData.step, 
                                                                    Type: rowData.type
                                                                  }])



                                                                }

                                                              },

                                                              // {
                                                              //   icon: RemoveIcon,
                                                              //   tooltip: 'Remove Step',
                                                              //   onClick: (event, rowData) => {
                                                              //     // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                              //     //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});

                                                              //     const axios = require('axios');
  
                                                              //     axios.post(`${RestAPI.testCase[0].removeTestCaseItem}`, {
                                                                
                                                              //       removeItem: rowData.step
                                                                  
                                                              //     })
                                                              //     .then((response) => {
                                                              //       // console.log(response);
                                                                  
                                                              //       getTestCaseStepList();
                                                                  
                                                              //     });


                                                              //   }

                                                              // }
                                                              
                                                            ]}


                                                          />
                                                    </div>


                                                </div>
                                          )

                                          
                                        }

                                      ]}
                                    /> 

                                  </GridItem>

                              {/* Saved Test Steps */}

                                  <GridItem xs={12} sm={12} md={6} >

                                    <CustomTabs
                                        //title="Tasks:"
                                        centered={true}
                                        headerColor="success"
                                        
                                        tabs={[
                                          {
                                            tabName: "Current Test Steps",
                                            tabIcon: TestListIcon,
                                              tabContent: (
                                                <div>    
                                                    <div style={{ maxWidth: '100%' }}>
                                                            <MaterialTable
                                                              columns={viewCurrentTestCaseStepTableColumn}
                                                              data={viewCurrentTestCaseStepDataSet}
                                                              title="Test Steps"
                                                              options={{
                                                                exportButton: false,
                                                                exportAllData: false,
                                                                filtering: true,
                                                                showTitle: false,
                                                                searchAutoFocus: true,
                                                                pageSize: 5
                                                              }}
                                                              actions={[
                                                                {
                                                                  icon: RemoveIcon,
                                                                  tooltip: 'Remove Step',
                                                                  onClick: (event, rowData) => {
                                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                                    //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                                                    
                                                                    
                                                                    setCurrentTestSteps(
                                                                      currentTestSteps.filter(data => data.Step !== rowData.step)
                                                                      )

                                                                  }

                                                                },

                                                                // {
                                                                //   icon: MoveUpIcon,
                                                                //   tooltip: 'Move Up',
                                                                //   onClick: (event, rowData) => {
                                                                //     // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                                //     //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                                                    

                                                                //   }

                                                                // },

                                                                // {
                                                                //   icon: MoveDownIcon,
                                                                //   tooltip: 'Move Down',
                                                                //   onClick: (event, rowData) => {
                                                                //     // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                                //     //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                                                    

                                                                //   }

                                                                // }

                                                              ]}

                                                            />
                                                      </div>


                                                  </div>
                                            )

                                            
                                          }
                                        ]}
                                      /> 

                                    </GridItem>
                                  
                                </GridContainer>

                                <GridContainer>

                                  <GridItem xs={12}
                                      sm={12}
                                      md={6}>

                                      <Card>
                                        <CardHeader color="info" icon>
                                          <CardIcon color="info">
                                            <NewStepIcon />
                                            
                                            
                                          </CardIcon>
                                          <h4 className={
                                            classes.cardIconTitle
                                          }>
                                            <b>Custom Step</b>
                                          </h4>
                                        </CardHeader>
                                        <CardBody>
                                          <form onSubmit={e => { e.preventDefault(); }}> 
                                            <CustomInput
                                              labelText="Example: Change to the channel 39 and wait 3 seconds"
                                              id="Input_TestCase_New_Item"
                                              // value={""}
                                              formControlProps={{
                                                fullWidth: true
                                              }}
                                              inputProps={{
                                                onBlur: event => {setCustomInput(event.target.value)},
                                                type: "text"
                                              }}
                                              
                                          />
                                          
                                          </form>

                                          <FormControl fullWidth
                                            className={
                                              classes.selectFormControl
                                          }>


                                            <InputLabel htmlFor="type-select"
                                              className={
                                                classes.selectLabel
                                            }>
                                              Select Type
                                            </InputLabel>

                                            <Select style={
                                                {minWidth: "100%"}
                                              }
                                              MenuProps={
                                                {className: classes.selectMenu}
                                              }
                                              classes={
                                                {select: classes.select}
                                              }
                                              //multiple
                                              autoWidth={false}
                                              value={selectTypeSelection}
                                              onChange={handleCustomType}
                                              inputProps={
                                                {
                                                  name: "typeSelect",
                                                  id: "type-select"
                                                }
                                            }>
                                              <MenuItem disabled
                                                classes={
                                                  {root: classes.selectMenuItem}
                                              }>
                                                <b>Single Selection</b>
                                              </MenuItem>

                                              {
                                              selectTypeItems.map((SelectTypeItems, index) => (
                                                <MenuItem key={index}
                                                  classes={
                                                    {
                                                      root: classes.selectMenuItem,
                                                      selected: classes.selectMenuItemSelected
                                                    }
                                                  }
                                                  value={SelectTypeItems}>
                                                  {SelectTypeItems} </MenuItem>
                                              ))
                                            } 
                                            </Select>

                                          </FormControl>

                                          <GridContainer>
                                            <GridItem 
                                                  xs={12}
                                                  container
                                                  direction="row"
                                                  justify="flex-end"
                                                  alignItems="center">

                                                <Button color="behance" size="sm" onClick={handleAdd} > 
                                                  <AddIcon className={classes.icons} />
                                                    Add
                                                </Button>

                                            </GridItem>
                                          </GridContainer>
                                          
                                        </CardBody>

                                      </Card>

                                    </GridItem>

                                  </GridContainer>

                            
                            </DialogContent>
                            <DialogActions>

                              <Button onClick={handleTestCasePopupClose} color="github">
                                Close
                              </Button>

                              <Button onClick={handleSave} color="success">
                                Create/Save Test Case
                              </Button>

                            </DialogActions>
                          </Dialog>
                        </div>


                         {/* Create Run Test Case Pop-Up */}


                          <Dialog

                            //fullScreen
                            fullWidth={true}
                            maxWidth={'lg'}
                            fullScreen={true}
                            open={newTestCaseRunPopUpState}
                            onClose={() => setNewTestCaseRunPopUpState(false)}
                            // TransitionComponent={'Zoom'}
                            aria-labelledby="run-test-case-title"
                          >
                            <DialogTitle id="run-test-case-title"><strong> {`${'Run Test Case'}`}  </strong></DialogTitle>

                             <div style={{position: "fixed", top: "50%", left: "50%", marginTop: "-100px", marginLeft: "-100px"}}>
                              <GridContainer>
                                <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="center"
                                      alignItems="center">

                                  <RotatingLines
                                    strokeColor="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="125"
                                    visible={isLoading}
                                  />




                                </GridItem>
                              </GridContainer>
                            
                            </div>
                            <div style={{ display: isLoading === false ? '' : 'none'}}>

                           
                            <DialogContent>


                            <Heading
                              textAlign="center"
                              title= {titleInput}  //"Test Case Title Goes Here"
                              category= {descriptionInput}  //"Created using Roboto Font Family"
                            />

                          

                            <div>
                            <GridContainer>

                              <GridItem xs={12}
                                sm={12}
                                md={12}>

                                <Card>
                                  <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                      <TvIcon />
                                      
                                      
                                    </CardIcon>
                                    <h4 className={
                                      classes.cardIconTitle
                                    }>
                                      <b>TV</b>
                                    </h4>
                                  </CardHeader>
                                  <CardBody>
                                    <FormControl fullWidth
                                      className={
                                        classes.selectFormControl
                                    }>
                                      <InputLabel htmlFor="computer-tv"
                                        className={
                                          classes.selectLabel
                                      }>
                                        Select TV
                                      </InputLabel>

                                      <Select style={
                                          {minWidth: "100%"}
                                        }
                                        MenuProps={
                                          {className: classes.selectMenu}
                                        }
                                        classes={
                                          {select: classes.select}
                                        }
                                        //multiple
                                        autoWidth={false}
                                        value={uniqueTvNameSelection}
                                        onChange={handleTvName}
                                        inputProps={
                                          {
                                            name: "computerTV",
                                            id: "computer-tv"
                                          }
                                      }>
                                        <MenuItem disabled
                                          classes={
                                            {root: classes.selectMenuItem}
                                        }>
                                          <b>Single Selection</b>
                                        </MenuItem>

                                        {
                                        uniqueTvNameItems.map((UniqueTvNameItems, index) => (
                                          <MenuItem key={index}
                                            classes={
                                              {
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                              }
                                            }
                                            value={UniqueTvNameItems}>
                                            {UniqueTvNameItems} </MenuItem>
                                        ))
                                      } 
                                      </Select>
                                      <br></br>
                                      <br></br>
                                    </FormControl>
                                  </CardBody>
                                </Card>

                              </GridItem>

                            </GridContainer>

                            <GridContainer>
                              <GridItem 
                                    xs={12}
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center">

                                  <Button color="success" size="lg" onClick={handleTestCaseRun}> 
                                    <StartIcon className={classes.icons} />
                                      Run
                                  </Button>
                                

                              </GridItem>

                            </GridContainer>


                            <br></br>

                            <GridContainer>

                              <GridItem xs={12}>
                                <Button color="github" size="sm" style={{width: "100%"}}> <b>Test Run</b> </Button>
                              </GridItem>

                            </GridContainer>

                            <br></br>


                            <GridContainer>
                              <GridItem xs={12} sm={6} md={4}>
                                <Card>
                                  <CardHeader color="info" stats icon>
                                    <CardIcon color="info">
                                      <TestIcon />
                                    </CardIcon>
                                    <p className={dashboardClasses.cardCategory}>Test Status</p>
                                    <h3 className={dashboardClasses.cardTitle}>
                                      {getTestStatus()} 
                                    </h3>
                                  </CardHeader>
                                  <CardFooter stats>
                                    <div className={dashboardClasses.stats}>

                                    </div>
                                  </CardFooter>
                                </Card>
                              </GridItem>

                              <GridItem xs={12} sm={6} md={4}>
                                <Card>
                                  <CardHeader color="success" stats icon>
                                    <CardIcon color="success">
                                      <PassIcon />
                                    </CardIcon>
                                    <p className={dashboardClasses.cardCategory}>Pass</p>
                                    <h3 className={dashboardClasses.cardTitle}>
                                      {testStatusPassCount} 
                                    </h3>
                                  </CardHeader>
                                  <CardFooter stats>
                                    <div className={dashboardClasses.stats}>

                                    </div>
                                  </CardFooter>
                                </Card>
                              </GridItem>

                              <GridItem xs={12} sm={6} md={4}>
                                <Card>
                                  <CardHeader color="danger" stats icon>
                                    <CardIcon color="danger">
                                      <FailIcon />
                                    </CardIcon>
                                    <p className={dashboardClasses.cardCategory}>Fail</p>
                                    <h3 className={dashboardClasses.cardTitle}>
                                      {testStatusFailCount} 
                                    </h3>
                                  </CardHeader>
                                  <CardFooter stats>
                                    <div className={dashboardClasses.stats}>

                                    </div>
                                  </CardFooter>
                                </Card>
                              </GridItem>

                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                
                                <CustomTabs
                                  // title="Tasks:"
                                  centered={true}
                                  headerColor="primary"
                                  tabs={[
                                    {
                                      tabName: "Status",
                                      tabIcon: StatusIcon,
                                      tabContent: (
                                        <div>


                                          <GridContainer>

                                            <GridItem 
                                                  xs={12}
                                                  container
                                                  direction="row"
                                                  justify="center"
                                                  alignItems="center">

                                            <Chip 
                                                color= "primary" 
                                                icon= {<IDIcon />} 
                                                label= {`${'Test Run ID: '}${uniqueTestRunID}`}
                                                size="medium"
                                              />

                                            <Chip 
                                                color= "primary" 
                                                icon= {<IDIcon />} 
                                                label= {`${'Test Case ID: '}${testCaseID}`}
                                                size="medium"
                                              />


                                              <Chip 
                                                color= "primary" 
                                                icon= {<TitleIcon />} 
                                                label= {`${'Title: '}${titleInput}`}
                                                size="medium"
                                              />

                                              <Chip 
                                                color= "primary" 
                                                icon= {<DescriptionIcon />} 
                                                label= {`${'Description: '}${descriptionInput}`}
                                                size="medium"
                                              />

                                              <Chip 
                                                color= "primary" 
                                                icon= {<PcapIcon />} 
                                                label= {`${'PCAP: '}${pcapItemsSelection}`}
                                                size="medium"
                                              />

                                              <Chip 
                                                color= "primary" 
                                                icon= {<FrequencyIcon />} 
                                                label= {`${'Frequency: '}${freqencyInputSelection}`}
                                                size="medium"
                                              />


                                            </GridItem>

                                          </GridContainer>

                                          <br></br>



                                        <div style={{ maxWidth: "100%" }}>
                                          <MaterialTable
                                            columns={viewTestRunStepListTableColumn}
                                            data={viewTestRunStepListDataSet}
                                            title=""
                                            options={{
                                              // exportButton: true,
                                              // exportAllData: true,
                                              // filtering: true,
                                              showTitle: true,
                                              pageSize: 20,
                                              search: false,
                                              actionsColumnIndex: -1,
                                              // selection: true

                                              // rowStyle: { backgroundColor: 'black'},
                                              rowStyle: rowData => {

                                                // console.log(rowData);
                                                // console.log(rowData.tableData.index);
                                                
                                                //console.table(rowData.step);
                                                //console.table(rowData.step[0]);
                                                
                                                // if(rowData.step === "Go to Settings") {
                                                // if(rowData.step === currentTestSteps[testRunStepFocus]) {
                                                  // console.log(currentTestStepsType);

                                                if(rowData.tableData.index === testRunStepFocus) {
                                                  return {backgroundColor: 'rgb(238, 255, 51)', textDecoration: 'underline', fontWeight: 'bold'};
                                                } else{
                                                  return {backgroundColor: 'rgb(164, 165, 155)'};
                                                }
                                                
                                                return {};
                                              }
                                            }}

                                            actions={[

                                              (rowData) => {
                                                // console.table(rowData);
                                                // console.table(viewTestRunStepListDataSet);

                                                if(rowData.type === "NA" | rowData.tableData.index !== testRunStepFocus | rowData.step === "Video Stream Test"){
                                                  return {
                                                    icon: PassIcon,
                                                    tooltip: "Pass",
                                                    onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    disabled: true
                                                    }
                                                }else{
                                                  return {
                                                    icon: PassIcon,
                                                    tooltip: "Pass",
                                                    // onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    onClick: (event, rowData) => {
                                                      setTestRunStepFocus(prevCount => prevCount + 1)
                                                      setTestStatusPassCount(prevCount => prevCount + 1)

                                                      setPassStepLog(passStepLog.concat(rowData.step));

                                                      if(testRunStepFocus + 1 === specificTestCaseTable.length){
                                                        
                                                        saveTestRunResult("Pass", testRunStepFocus);

                                                        successAlert("Test Run Completed!");
                                                        
                                                      }

                                                    },
                                                    disabled: false
                                                    }

                                                }

                                              },


                                              (rowData) => {
                                                if(rowData.type === "NA" | rowData.tableData.index !== testRunStepFocus | rowData.step === "Video Stream Test"){
                                                  return {
                                                    icon: FailIcon,
                                                    tooltip: "Fail",
                                                    onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    disabled: true
                                                    }
                                                }else{
                                                  return {
                                                    icon: FailIcon,
                                                    tooltip: "Fail",
                                                    // onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    onClick: (event, rowData) => {
                                                      setTestRunStepFocus(prevCount => prevCount + 1)
                                                      setTestStatusFailCount(prevCount => prevCount + 1)

                                                      // console.log(rowData.step);
                                                      setFailStepLog(failStepLog.concat(rowData.step));



                                                      if(testRunStepFocus + 1 === specificTestCaseTable.length){
                                                        saveTestRunResult("Fail", testRunStepFocus);
                                                        successAlert("Test Run Completed!");
                                                        
                                                      }

                                                    },
                                                    disabled: false
                                                    }

                                                }

                                              },


                                              (rowData) => {
                                                if(rowData.type !== "NA" | rowData.tableData.index !== testRunStepFocus | rowData.step === "Video Stream Test"){
                                                  return {
                                                    icon: NextIcon,
                                                    tooltip: "Next",
                                                    onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    disabled: true
                                                    }
                                                }else{
                                                  return {
                                                    icon: NextIcon,
                                                    tooltip: "Next",
                                                    // onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    onClick: (event, rowData) => {
                                                      setTestRunStepFocus(prevCount => prevCount + 1)

                                                      if(testRunStepFocus + 1 === specificTestCaseTable.length){
                                                        
                                                        saveTestRunResult();

                                                        successAlert("Test Run Completed!");
                                                        
                                                      }

                                                    
                                                      
                                                    },

                                                    disabled: false
                                                    }

                                                }

                                              },

                                              // Temporary - TODO
                                              (rowData) => {
                                                if(rowData.step !== "Video Stream Test" | rowData.tableData.index !== testRunStepFocus){
                                                  return {
                                                    icon: RunIcon,
                                                    tooltip: "Run",
                                                    onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    disabled: true
                                                    }
                                                }else{
                                                  return {
                                                    icon: RunIcon,
                                                    tooltip: "Run",
                                                    // onClick: (event, rowData) => setTestRunStepFocus(prevCount => prevCount + 1),
                                                    onClick: (event, rowData) => {
                                                      setTestRunStepFocus(prevCount => prevCount + 1)

                                                      // Input Function to Run TestApp AutoRun
                                                      getAutoRun();

                                                      if(testRunStepFocus + 1 === specificTestCaseTable.length){
                                                        
                                                        saveTestRunResult();

                                                        successAlert("Test Run Completed!");
                                                        
                                                      }

                                                    
                                                      
                                                    },

                                                    disabled: false
                                                    }

                                                }

                                              }
                    
                                              // {
                    
                                              //   icon: NextIcon,
                                              //   tooltip: 'Next',
                                              //   onClick: (event, rowData) => {
                                              //     // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                              //     //onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                    
                                              //     // const axios = require('axios');
                    
                                              //     // axios.post(`${RestAPI.testCase[0].removeTestCaseMain}`, {
                                                
                                              //     //   removeTestCaseMain: rowData.id
                                                  
                                              //     // })
                                              //     // .then((response) => {
                                              //     //   // console.log(response);
                                                  
                                              //     //   getTestCaseList();
                                              //     //   getTestCaseListDetail();
                                                  
                                              //     // });


                                              //     setTestRunStepFocus(prevCount => prevCount + 1)
                    
                    
                                              //   }
                    
                                              // }
                    
                                            ]}
                                            
                                          />

                                          <br></br>

                                          <Card>
                                        <CardHeader color="info" icon>
    
                                          <h4 className={
                                            classes.cardIconTitle
                                          }>
                                            <b>Comment</b>
                                          </h4>
                                        </CardHeader>
                                        <CardBody>
                                          <form onSubmit={e => { e.preventDefault(); }}> 
                                            <CustomInput
                                              labelText="Example: Unable to select CC option on Step# 5"
                                              // value={""}
                                              formControlProps={{
                                                fullWidth: true
                                              }}
                                              inputProps={{
                                                onBlur: event => {setCommentInput(event.target.value)},
                                                type: "text"
                                              }}
                                              
                                          />
                                          
                                          </form>

                                          {/* <GridContainer>
                                            <GridItem 
                                                  xs={12}
                                                  container
                                                  direction="row"
                                                  justify="flex-end"
                                                  alignItems="center">

                                                <Button color="behance" size="sm" onClick={handleAdd} > 
                                                  <AddIcon className={classes.icons} />
                                                    Add
                                                </Button>

                                            </GridItem>
                                          </GridContainer> */}
                                          
                                        </CardBody>

                                      </Card>

                                        </div>


                                        {/* <GridContainer>
                                          <GridItem 
                                            xs={12}
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center">

                                              <Button color="danger" size="sm" style={{marginTop: "10%"}} onClick={onClickStopPC}> 
                                                <StopIcon className={classes.icons} />
                                                  Stop
                                              </Button>


                                              <Button color="warning" size="sm" style={{marginTop: "10%"}} onClick={onClickRefreshComputerStatusTable}> 
                                                <RefreshIcon className={classes.icons} />
                                                  Refresh Table
                                              </Button>
                                            

                                          </GridItem>

                                        </GridContainer> */}

                        

                                      </div>
                                      ),
                                    },
                                  ]}
                                />


                              </GridItem>

                            </GridContainer>

                            </div>
                            
                            </DialogContent>

                            </div>

                            <DialogActions>

                            <div >

                              <Button color="danger" onClick={handleRunTestCaseHardFail}>
                                <FailIcon className={classes.icons} />
                                Fail Test Case
                              </Button>

                              <Button color="dribbble" onClick={handleViewReport}>
                                <ReportIcon className={classes.icons} />
                                View Report
                              </Button>

                              
                              <Button color="github" onClick={handleRunTestCasePopupClose}>
                                Close
                              </Button>

                            </div>

                            </DialogActions>
                          </Dialog>
                        

                    </GridContainer>


                  </div>
                ),
              },

              {
                tabName: "Test Case List Detail",
                tabIcon: DetailIcon,
                  tabContent: (
                    <div>    
                        <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                  columns={viewtestCaseListDetailTableColumn}
                                  data={viewTestCaseListDetailDataSet}
                                  title="Test Step"
                                  options={{
                                    exportButton: false,
                                    exportAllData: false,
                                    filtering: true,
                                    showTitle: false,
                                    searchAutoFocus: true,
                                    pageSize: 10,
                                    // rowStyle: {
                                    //   backgroundColor: '#EEE',
                                    // }

                                  }}

                                />
                          </div>


                      </div>
                )

                
              }
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
